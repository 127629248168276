import { useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Container, Divider } from "@mui/material"

import { Calendar,dateFnsLocalizer } from "react-big-calendar"

import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import enUS from "date-fns/locale/en-US"

import "react-big-calendar/lib/css/react-big-calendar.css"

import EventInfo from "./EventInfo"
import AddEventModal from "./AddEventModal"
import EventInfoModal from "./EventInfoModal"
import AddTodoModal from "./AddTodoModal"
import AddDatePickerEventModal from "./AddDatePickerEventModal"
import { useSelector } from "react-redux"
import { selectDataForCalendar } from "../../../../Api/OrgRequests/OrgRequestHandler"

export default function USCompanyCalendar({calendarTitle="Производственный календарь", calendarSubheader="Отображает период производства"}) {

    const initialEventFormState = {
        description: "",
        todoId: undefined
    }

    const initialDatePickerEventFormData = {
        description: "",
        todoId: undefined,
        allDay: false,
        start: new Date(),
        end: undefined
    }

    //const lacale = useSelector((state) => state.basic.location);
    const locales = {
        "en-US": enUS
    }

    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });

    //States
    const [openSlot, setOpenSlot] = useState(false)
    const [openDatepickerModal, setOpenDatepickerModal] = useState(false)
    const [openTodoModal, setOpenTodoModal] = useState(false)
    const [currentEvent, setCurrentEvent] = useState(null)

    const [eventInfoModal, setEventInfoModal] = useState(false)

    const [events, setEvents] = useState([])
    const [todos, setTodos] = useState([])

    const [currentView, setCurrentView] = useState('week');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [eventFormData, setEventFormData] = useState(initialEventFormState)
    const [datePickerEventFormData, setDatePickerEventFormData] = useState(initialDatePickerEventFormData)

    useEffect(() => {
        //if(datePickerEventFormData?.start && datePickerEventFormData?.end) {
            fetchData(currentView, currentDate);
        //}
    },[currentDate, currentView])

    const fetchData = async(selectedView, selectedDate) => {
        console.log('USCompanyCalendar fetchData');
        const response = await selectDataForCalendar('productionqueue',
                                                        'Order',
                                                        selectedView,
                                                        selectedDate,//startDate,
                                                        datePickerEventFormData?.end,//endDate,
                                                        'StartDate',
                                                        'EndDate',
                                                        null,//searchCondition,
                                                        null//selectedListView
                                                    );
        console.log('USCompanyCalendar :: ', response);
        response?.tableData.map((dataItem) => {
            dataItem.start = dataItem.start ? new Date(dataItem.start) : null;
            dataItem.end = dataItem.end ? new Date(dataItem.end) : null;
        });
        console.log('USCompanyCalendar2 :: ', response);
        setEvents(response.tableData);
    }

    //handler for states
    const handleSelectSlot = (event) => {
        setOpenSlot(true)
        setCurrentEvent(event)
    }

    const handleSelectEvent = (event) => {
        setCurrentEvent(event)
        setEventInfoModal(true)
    }

    const handleClose = () => {
        setEventFormData(initialEventFormState)
        setOpenSlot(false)
    }

    const handleDatePickerClose = () => {
        setDatePickerEventFormData(initialDatePickerEventFormData)
        setOpenDatepickerModal(false)
    }

    //creating Events
    const onAddEvent = (e) => {
        e.preventDefault()
        console.log('currentEvent?.start',currentEvent?.start);
        const data = {
          ...eventFormData,
          _id: Date.now(),
          start: currentEvent?.start,
          end: currentEvent?.end,
        }

        const newEvents = [...events, data]

        setEvents(newEvents)
        handleClose()
    }

    const onAddEventFromDatePicker = (e) => {
        e.preventDefault()

        const addHours = (date, hours) => {
          return date ? date.setHours(date.getHours() + hours) : undefined
        }

        const setMinToZero = (date) => {
          date.setSeconds(0)

          return date
        }

        const data = {
          ...datePickerEventFormData,
          _id: Date.now(),
          start: setMinToZero(datePickerEventFormData.start),
          end: datePickerEventFormData.allDay
            ? addHours(datePickerEventFormData.start, 12)
            : setMinToZero(datePickerEventFormData.end),
        }

        const newEvents = [...events, data]

        setEvents(newEvents)
        setDatePickerEventFormData(initialDatePickerEventFormData)
    }

    const onDeleteEvent = () => {
        setEvents(() => [...events].filter((e) => e._id !== currentEvent?._id))
        setEventInfoModal(false)
    }

    const handleNavigate = (a,b,c) => {
        console.log('handleNavigate a ', a);
        console.log('handleNavigate b ', b);
        setCurrentDate(a);
        setCurrentView(b);
    }

    return (
        <Box
            mb={2}
            component="main"
            sx={{
                flexGrow: 1,
                py: 1,
            }}
            >
            <Container maxWidth={false}>
                <Card>
                    <CardHeader title={calendarTitle} subheader={calendarSubheader} />
                    <Divider />
                    <CardContent>
                        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <ButtonGroup size="large" variant="contained" aria-label="outlined primary button group">
                                <Button onClick={() => setOpenDatepickerModal(true)} size="small" variant="contained">
                                    Add event
                                </Button>
                                <Button onClick={() => setOpenTodoModal(true)} size="small" variant="contained">
                                    Create todo
                                </Button>
                            </ButtonGroup>
                        </Box> */}
                        <Divider style={{ margin: 10 }} />
                        <AddEventModal
                            open={openSlot}
                            handleClose={handleClose}
                            eventFormData={eventFormData}
                            setEventFormData={setEventFormData}
                            onAddEvent={onAddEvent}
                            todos={todos}
                        />
                        <AddDatePickerEventModal
                            open={openDatepickerModal}
                            handleClose={handleDatePickerClose}
                            datePickerEventFormData={datePickerEventFormData}
                            setDatePickerEventFormData={setDatePickerEventFormData}
                            onAddEvent={onAddEventFromDatePicker}
                            todos={todos}
                        />
                        <EventInfoModal
                            open={eventInfoModal}
                            handleClose={() => setEventInfoModal(false)}
                            onDeleteEvent={onDeleteEvent}
                            currentEvent={currentEvent}
                        />
                        <AddTodoModal
                            open={openTodoModal}
                            handleClose={() => setOpenTodoModal(false)}
                            todos={todos}
                            setTodos={setTodos}
                        />
                        <Calendar
                            localizer={localizer}
                            events={events}
                            onSelectEvent={handleSelectEvent}
                            //onSelectSlot={handleSelectSlot}
                            selectable
                            startAccessor="start"
                            components={{ event: EventInfo }}
                            endAccessor="end"
                            defaultView="week"
                            onView={(e) => setCurrentView(e)}
                            onChange={handleNavigate}
                            onNavigate={(a,b,c) => handleNavigate(a,b,c)}
                            eventPropGetter={(event) => {
                                const hasTodo = todos.find((todo) => todo._id === event.todoId)
                                return {
                                style: {
                                    backgroundColor: hasTodo ? hasTodo.color : "#b64fc8",
                                    borderColor: hasTodo ? hasTodo.color : "#b64fc8",
                                },
                                }
                            }}
                            style={{
                                height: 900,
                            }}
                        />
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}
