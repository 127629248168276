import React, { useEffect, useState } from 'react'
import Divider from '@mui/material/Divider';
import { Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';

export default function USSearchProduct({detail, handleUpdateProduct, handleAddAction, refreshToken}) {

    const [attributes, setAttributes] = useState(null);
    const [characteristics, setCharacteristics] = useState(null);
    //const [additionalItems, setAdditionalItems] = useState(null);
    const [selectedComplectName, setSelectedComplectName] = useState();
    const [selectedComplect, setSelectedComplect] = useState(null);
    const [productParts, setProductParts] = useState(null);
    const [productPrice, setProductPrice] = useState(0);
    useEffect(() => {
        if(detail) {
            setAttributes(detail?.attributes);
            //setAdditionalItems(detail?.addcharacteristics);
            setCharacteristics(detail?.characteristics);
            setProductParts(detail?.productParts);
            if(detail?.Type) {
                setSelectedComplectName(detail?.Type);
                setSelectedComplect(detail?.characteristics[detail?.Type]);
                let complectPrice = detail?.Price ? detail?.Price : 0;
                detail?.characteristics[detail?.Type]?.map((item, index) => {
                    if(item?.Price) {
                        complectPrice += item?.Price ? item?.Price : 0;
                    }
                });
                setProductPrice(complectPrice);
            } else {
                setProductPrice(detail?.Price ? detail?.Price : 0);
            }
            //handleUpdateProduct({"Price": detail?.Price, "Name" : detail?.Name, "productId" : detail?.id});
        }
    }, [detail, refreshToken]);


    const selectComplect = (selectedValue) => {
        setSelectedComplectName(selectedValue);
        setSelectedComplect(characteristics[selectedValue])
        let complectPrice = 0;
        characteristics[selectedValue]?.map((item, index) => {
            if(item?.Price) {
                complectPrice += item?.Price ? item?.Price : 0;
            }
        });
        let clearAdditionalItems = productParts;
        Object.keys(clearAdditionalItems)?.map((typeName, index) => {
            clearAdditionalItems[typeName]?.map((item, keyIndex) => {
                item.isSelected = false;
            })
        })
        setProductParts(clearAdditionalItems);
        setProductPrice(detail?.Price + complectPrice);
        //handleUpdateProduct({"Price": productPrice, "Name" : detail?.Name, "productId" : detail?.id, 'additionalParts' : additionalItems, 'type' : selectedComplectName});
    }

    const selectAdditionalItem = (aiId, sectionIndex) => {
        productParts[sectionIndex][aiId].isSelected = !productParts[sectionIndex][aiId].isSelected;
        let changedProductPrice = productPrice;
        if(productParts[sectionIndex][aiId].isSelected) {
            changedProductPrice += productParts[sectionIndex][aiId].Price;
        } else {
            changedProductPrice -= productParts[sectionIndex][aiId].Price;
        }
        setProductPrice(changedProductPrice);
        //handleUpdateProduct({"Price": productPrice, "Name" : detail?.Name, "productId" : detail?.id, 'additionalParts' : additionalItems, 'type' : selectedComplectName});
    }

    const loanPercent = {
        6: 1,
        60: 14,
        24: 8.5,
        12: 7.5,
        9: 5.5
    };

    const handleChange = (productCost, selectedRange) =>{
        var s = productCost; //сумма кредита
        var r = loanPercent[selectedRange]/100/12; // месячная ставка
        var n = selectedRange; //кол-во месяцев
        var k = s * (r+(r/(((1+r)**n)-1)));
        return k.toFixed(2);
    }

    const saveProduct = () => {
        handleUpdateProduct({"Price": productPrice, "Name" : detail?.Name, "productId" : detail?.id, 'additionalParts' : productParts, 'Type' : selectedComplectName});
    }

    return (
        <Card>
            {detail && <CardContent>
                {detail.id && <div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <Typography variant='h5'>
                                {detail?.Name}
                                {handleUpdateProduct && <Button onClick={saveProduct}>Добавить в договор</Button>}
                            </Typography>
                            <Typography>
                                <b>{<IntlMessages id="common.price" />}: </b>{productPrice}
                            </Typography>
                            <div>
                                <b>{<IntlMessages id="LOAN_6M" />}: </b>{handleChange(productPrice,6)} руб.
                            </div>
                            <div>
                                <b>{<IntlMessages id="LOAN_9M" />}: </b>{handleChange(productPrice,9)} руб.
                            </div>
                            <div>
                                <b>{<IntlMessages id="LOAN_12M" />}: </b>{handleChange(productPrice,12)} руб.
                            </div>
                            <div>
                                <b>{<IntlMessages id="LOAN_24M" />}: </b>{handleChange(productPrice,24)} руб.
                            </div>
                            <div>
                                <b>{<IntlMessages id="LOAN_60M" />}: </b>{handleChange(productPrice,60)} руб.
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                            {characteristics && <div>
                                <Divider textAlign="center" style={{ marginTop: '10px'}} ><Typography variant='h6'>Комплектации</Typography></Divider>
                                <RadioGroup row>
                                    <div className='row'>
                                        {Object.keys(characteristics)?.map((value, key) => (
                                            <div className='col-sm-12 col-md-4 col-lg-4' key={value}>
                                                <FormControlLabel control={<Radio checked={value == selectedComplectName} onChange={() => selectComplect(value)} />} label={value} />
                                            </div>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                            <Divider textAlign="center" style={{ marginTop: '10px'}} ><Typography variant='h6'>Характеристики</Typography></Divider>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                                    {detail?.Height &&
                                        <Typography>
                                            <b>{<IntlMessages id="common.height" />}: </b>{detail?.Height}
                                        </Typography>
                                    }
                                    {detail?.Length &&
                                        <Typography>
                                            <b>{<IntlMessages id="common.lenght" />}: </b>{detail?.Length}
                                        </Typography>
                                    }
                                    {detail?.Width &&
                                        <Typography>
                                            <b>{<IntlMessages id="common.width" />}: </b>{detail?.Width}
                                        </Typography>
                                    }
                                    {detail?.Material &&
                                        <Typography>
                                            <b>{<IntlMessages id="common.material" />}: </b>{detail?.Material}
                                        </Typography>
                                    }
                                    {detail?.Thickness &&
                                        <Typography>
                                            <b>{<IntlMessages id="product.Thickness" />}: </b>{detail?.Thickness}
                                        </Typography>
                                    }
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    {attributes && attributes?.map((item, key) => (
                                        <Typography key={key}>
                                            <b>{item.Name}: </b>{item.Count}
                                        </Typography>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                            <Divider textAlign="center" style={{ marginTop: '10px'}} ><Typography variant='h6'>Выбранная Комплектаця</Typography></Divider>
                            <div className='row'>
                                {selectedComplect && selectedComplect?.map((chrxItem, key) => (
                                    <div className='col-sm-12 col-md-6 col-lg-6' key={key}>
                                        <Typography key={key} variant='body2'>
                                            {chrxItem.Name} {chrxItem.Description}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}

                {/* {additionalItems && <Divider  textAlign="center" style={{marginTop: '20px' }}><Typography variant='h6'>Дополнительная комплектация</Typography></Divider>}

                {additionalItems && <FormGroup row>
                    <div className="row">
                        {additionalItems?.map((value, key) => (
                            <div className="col-sm-12 col-md-6 col-lg-6" key={value.id} style={{ paddingTop: '0px' }}>
                                <FormControlLabel control={<Checkbox onChange={() => selectAdditionalItem(key)} />} label={`${value.Name} ${value.Description}`} checked={value?.isSelected ? true : false}/>
                            </div>
                        ))}
                    </div>
                </FormGroup>} */}

                {productParts && <Divider  textAlign="center" style={{marginTop: '20px' }}><Typography variant='h6'>Дополнительная комплектация</Typography></Divider>}
                {productParts && <FormGroup row>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12" >
                            {Object.keys(productParts).map((sectionName, sectionIndex) => (
                                <div className="row" key={sectionName+':'+sectionIndex}>
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingTop: '0px', backgroundColor: '#72d1e7' }}>
                                        {sectionName}
                                    </div>
                                    {productParts[sectionName]?.map((value, key) => (
                                        <div className="col-sm-12 col-md-6 col-lg-6" key={value.id} data-id={value.id} style={{ paddingTop: '0px' }}>
                                            <FormControlLabel control={<Checkbox onChange={() => selectAdditionalItem(key, sectionName)} />} label={`${value.Name} ${value.Description}`} checked={value?.isSelected ? true : false}/>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </FormGroup>}
                </CardContent>
            }
        </Card>
    )
}

