import { Avatar, Button, ButtonGroup, Card, CardContent, CardHeader, Modal } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import IntlMessages from '../../../../util/IntlMessages';

export default function CalculateLoan({isOpen, contractPrice, handleClose}) {

    const [selectedBank, setSelectedBank] = useState(); //list of available banks
    const [selectedPerids, setSelectedPerids] = useState(); // list of periods and percents according to seected bank
    const [numberOfMonths, setNumberOfMonths] = useState();//selected pament period according to selected bank
    const [monthPercent, setMonthPercent] = useState(); //selected pament yearly percents according to selected bank

    const [loan, setLoan] = useState(contractPrice); // product cost
    const [resultData, setResultData] = useState(); // payment table data

    const [overPrice, setOverPice] = useState();

    const monthsToBank = {  //data for bank loan informmation
        belarusbank: {
            6: 6,
            12: 12,
            24: 14.5,
        },
        sberbank: {
            9 : 6,
            12 : 12,
            24 : 14.5,
            60 : 17.2
        }
    }

    const handleChange = () =>{
        let s = loan; //сумма кредита
        var r = monthPercent/100/12; // месячная ставка
        var n = numberOfMonths; //кол-во месяцев
        let k = s * (r+(r/(((1+r)**n)-1)));
        k = k.toFixed(2)
        let loanData = [];
        let overpriceCalculate = 0;
        for(let i = 1; i<=numberOfMonths;i++) {
            const percentPayment = (s*r).toFixed(2);
            const loanPayment = (k-percentPayment).toFixed(2);
            const endPeriod = (s-loanPayment).toFixed(2)
            loanData.push({
                month: i,
                leftLoan: s,
                montlyPayment: k,
                percentPayment: percentPayment,
                loanPayment: loanPayment,
                endPeriod: endPeriod,
            });
            overpriceCalculate += parseFloat(percentPayment);
            s = endPeriod;
        }
        setOverPice(overpriceCalculate.toFixed(2));
        setResultData(loanData);
    }

    useEffect(() => {
        if(selectedBank) {
            setSelectedPerids(monthsToBank[selectedBank]);
        }
    },[selectedBank]);

    useEffect(() => {
        handleChange();
    },[monthPercent]);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10"
            style={{ height: "90%", overflowY: 'scroll' }}
        >
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            O
                        </Avatar>
                    }
                    action={
                        <>
                            <ButtonGroup>
                                <Button onClick={handleClose}>Close</Button>
                            </ButtonGroup>
                        </>
                    }
                    title="Расчет Кредита"
                />
                <CardContent>
                    <div className='row'>
                        <div className='col-sm-3 col-md-3 col-lg-3'>
                            <div className='row' style={{ display: 'block' }}>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    Цена: {contractPrice}
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    Переплата: {overPrice}
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label for="selectedBank">Выбрать Банк:</label>
                                    <select id="selectedBank" className="form-select form-select-sm d-inline-block" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)}>
                                        <option value={null}>Выбрать</option>
                                        <option value="belarusbank">Беларусьбанк</option>
                                        <option value="sberbank">Сбербанк</option>
                                    </select>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label for="percentPerYear">Выбрать срок кредитования:</label>
                                    <select id="percentPerYear" className="form-select form-select-sm d-inline-block" value={numberOfMonths} onChange={(e) => {setNumberOfMonths(e.target.value); setMonthPercent(selectedPerids[e.target.value])}}>
                                        <option value={null}>Выбрать</option>
                                        {selectedPerids && Object.keys(selectedPerids)?.map((item) => (
                                            <option key={`percentPerYear_${item}`} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Месяц</th>
                                        <th scope="col">Остаток Долга</th>
                                        <th scope="col">Ежемесячный платеж</th>
                                        <th scope="col">Процентная часть</th>
                                        <th scope="col">Долговая часть</th>
                                        <th scope="col">Остаток</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultData && resultData.map((rowItem, rowItemIndex) => (
                                        <tr key={`row_${rowItemIndex}`}>
                                            <td >
                                                {rowItem.month}
                                            </td>
                                            <td >
                                                {rowItem.leftLoan}
                                            </td>
                                            <td >
                                                {rowItem.montlyPayment}
                                            </td>
                                            <td >
                                                {rowItem.percentPayment}
                                            </td>
                                            <td >
                                                {rowItem.loanPayment}
                                            </td>
                                            <td >
                                                {rowItem.endPeriod}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Modal>
    )
}
