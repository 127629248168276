import React, {useEffect, useState} from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { deleteRecord, saveRecord, selectObjectRecords } from '../../../Api/OrgRequests/OrgRequestHandler';
import { addNotification } from '../../../reduxSettings/reduxSlices/NotificationSlice';
import { useDispatch } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { NativeSelect, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { openDialog } from '../../../reduxSettings/reduxSlices/systemSlice';
import USTableDropdown from './USTableComponents/USTableDropdown';
import USListViewDialog from './USListViewDialog';

function USTableNew({objectName, currentAppUrl='', parentObjectName, parentFieldName, parentObjectId, tableTitle, isRelatedList=false}) {

    const nextRow = ">";
    const prevRow = "<";
    const dispatch = useDispatch();
    const navigate = useHistory();
    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    const [recordsCount, setRecordsCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [sortOrderField, setSortOrderField] = useState(null);
    const [sortOrderDirection, setSortOrderDirection] = useState(null);
    const [sortOrderIcon, setOrderIcon] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchCondition, setSearchCondition] = useState(null);

    const [listView, setListView] = useState();
    const [selectedListView, setSelectedListView] = useState();
    const [selectedListViewApiName, setSelectedListViewApiName] = useState();
    const [relatedObjects, setRelatedObjects ] = useState(null);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const fetchData = async(selectedObjectName, selectedCurrentPage, selectedRowPerPage, selectedOrderField, selectedOrderDirection) => {
        parentFieldName = parentFieldName ? parentFieldName : null;
        parentObjectId = parentObjectId ? parentObjectId : null;
        const response = await selectObjectRecords(`/company/selectObjectRecords`,
                                                selectedObjectName,
                                                selectedCurrentPage,
                                                selectedOrderField,
                                                selectedOrderDirection,
                                                selectedRowPerPage,
                                                parentFieldName,
                                                parentObjectId,
                                                searchCondition,
                                                selectedListViewApiName);
        setListView(response?.listView);
        let listViewSelected = false;
        if(!selectedListViewApiName) {
            response?.listView?.map((listViewItem) => {
                if(!listViewSelected) {
                    setSelectedListView(listViewItem);
                    setSelectedListViewApiName(listViewItem?.ApiName);
                    listViewSelected=true;
                    return;
                }
            });
        }
        setRelatedObjects({...response?.relatedObjects});
        setRecordsCount(response?.count);
        setRows(response?.tableData);
        if(response?.count) {
            let nPages = Math.ceil(response?.count/rowsPerPage);
            setNumberOfPages(nPages);
        }
        setColumns(response.tableHeader);
    }

    const handleChangeListView = (selectedListView) => {
        const lView = listView.find((item) => {
            if(item.ApiName == selectedListView) {
                return item;
            }
        });
        setSelectedListView(lView);
        setSelectedListViewApiName(selectedListView);
    }

    const setPageNumber = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    }

    const setRowPerPage = (recPerPage) => {
        setRowsPerPage(recPerPage);
    }

    useEffect(() => {
        if(rows && columns) {
            fetchData(objectName, currentPage, rowsPerPage, sortOrderField, sortOrderDirection);
        }
    },[rowsPerPage, currentPage, selectedListViewApiName, searchCondition]);

    useEffect(() => {
        fetchData(objectName, currentPage, rowsPerPage, sortOrderField, sortOrderDirection);
    },[objectName]);
    /* end init table */

    const refreshTable = () => {
        fetchData(objectName, currentPage, rowsPerPage, sortOrderField, sortOrderDirection);
    }

    /* Update table cell */
    const [isEditCell, setIsEditCell] = useState({row: null, cell: null });
    const editTableCell = (e, fieldName, rowIndex, cellIndex) => {
        setIsEditCell({row: rowIndex, cell: cellIndex });
    }

    const changeCellValue = (newValue, rowIndex, fieldName) => {
        let changedData = [...rows];
        changedData[rowIndex][fieldName] = newValue;
        setRows(changedData);
    }

    const handleBlur = (rowIndex) => {
        // let changedData = [...rows];
        // changedData[rowIndex][fieldName] = newValue;
        updateRecord(rows[rowIndex]);
        setIsEditCell({row: null, cell: null });
    }

    const updateRecord = async (recordToUpdate) => {
        const response = await saveRecord(objectName,recordToUpdate.id,recordToUpdate);
        dispatch(addNotification({"type" : "success","title":"Record Updated", "message":  "Selected record updated successfully"}));
    }

    const handleShowEditDialog = () => {
        dispatch(openDialog({objectName : objectName, dialogTitle : 'New/Edit ' + objectName, parentFieldName : parentFieldName, parentRecordId: parentObjectId}));
    }

    const getRelatedObjectName = (objectName, objectId) => {
        console.log('objectName', objectName);
        console.log('relatedObjects', relatedObjects);
        if(relatedObjects?.hasOwnProperty(objectName)) {
            if(relatedObjects[objectName]?.hasOwnProperty(objectId)) {
                return relatedObjects[objectName][objectId];
            } else {
                return  objectId;
            }
        }
        return objectId;
    }

    const getSortUpIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-sort-up" viewBox="0 0 16 16">
                    <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
                </svg>
    }

    const getSortDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-sort-up" viewBox="0 0 16 16">
                    <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
                </svg>
    }

    const updatedFieldSort = (fieldName) => {
        let ordDirection = '';
        if(sortOrderField != fieldName) {
            setSortOrderDirection('ASC');
            ordDirection = 'ASC';
            setOrderIcon(getSortDownIcon());
        } else {
            if(sortOrderDirection != 'ASC') {
                setOrderIcon(getSortDownIcon());
                ordDirection = 'ASC';
            } else {
                ordDirection = 'DESC';
                setOrderIcon(getSortUpIcon());
            }

            setSortOrderDirection(ordDirection);
        }

        setSortOrderField(fieldName);
        fetchData(objectName, 0, rowsPerPage, fieldName, ordDirection);
    }

    const [openListView, setOpenListView] = useState(false);
    const [listViewAction, setListViewAction] = useState();
    const handleManageListView = (action) => {
        console.log('action', action);
        if(action != 'delete') {
            setListViewAction(action);
            setOpenListView(true);
        } else if (action == 'delete') {
            handleDeleteListView();
        }
    }

    const handleDeleteListView = async(recId) => {
        const response = await deleteRecord('listview', selectedListView);
        dispatch(addNotification({type: "info", message: "Record Deleted"}));
    }

    const handleDelete = async(recId) => {
        const response = await deleteRecord(objectName, recId);
        dispatch(addNotification({type: "info", message: "Record Deleted"}));
        refreshTable();
    }

    const handleClose = () => {
        setOpenListView(false);
        fetchData(objectName, currentPage, rowsPerPage, sortOrderField, sortOrderDirection);
    }

    const [searchInputValue, setSearchInputValue] = useState('');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch(searchInputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchInputValue, 500]);

    const handleSearch = (searchString) => {
        if(searchString && searchString.length > 0) {
            let searchCondition = {};
            columns.map((headreItem) => {
                searchCondition[headreItem.fieldName] = searchString;
            });
            setSearchCondition(searchCondition);
        } else {
            setSearchCondition(null);
        }
    }

    const  butifyDateFormat = (value) => {
        if(value != null) {
            let colData = new Date(value);
            let day = colData.getDate();
            let month = colData.getMonth();
            let year = colData.getFullYear();
            return month+1 + "/" + day + "/" + year;
        }
        return '';
    }

    const getPagination = (value) => {
        for(let i = 0; i < value; i++) {
            return <li className={`${currentPage == i ? 'active':''}`}><a onClick={(e) => setPageNumber(e, i)}>{i}</a></li>
        }
    }

    return (
        <div className="row">
            {(rows && columns) && <div className="col-md-offset-1 col-md-12">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col col-sm-3 col-xs-12">
                                <Stack direction="column">
                                    <h4 className="title">{tableTitle ? tableTitle : objectName}</h4>
                                    {!isRelatedList && <NativeSelect
                                        defaultValue={selectedListViewApiName}
                                        onChange={(e) => handleChangeListView(e.target.value)}
                                    >
                                        {listView && listView.map((listViewItem, indexKey) => (
                                            <option key={indexKey} value={listViewItem.ApiName} >{listViewItem.Name}</option>
                                        ))}
                                    </NativeSelect>}
                                </Stack>
                            </div>
                            <div className="col-sm-9 col-xs-12 text-right">
                                <div className="btn_group" >
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-default" onClick={handleShowEditDialog}>New</button>
                                        <button type="button" className="btn btn-default" onClick={() => navigate.push(`/setup/view/${parentObjectName}/${objectName}/${parentObjectId}`)}>Bulk Import</button>
                                        <button type="button" className="btn btn-default">Print</button>
                                    </div>
                                    <div className='mt-10' style={{ justifyContent:"flex-end", alignItems:"flex-end" }}>
                                        <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchInputValue(e.target.value)}></input>
                                        {!isRelatedList && <USTableDropdown
                                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-gear" viewBox="0 0 16 16">
                                                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"></path>
                                                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"></path>
                                            </svg>}
                                            values={[{label: 'label.New', value: 'new'},
                                                    //{label: 'label.Clone', value: 'clone'},
                                                    //{label: 'label.Rename', value: 'rename'},
                                                    //{label: 'label.sharing', value: 'sharing'},
                                                    {label: 'label.showlistfilter', value: 'listfilter'},
                                                    //{label: 'label.fieldsToDisplay', value: 'listToDisplay'},
                                                    {label: 'label.delete', value: 'delete'}
                                                ]}
                                            selectedValue={handleManageListView}
                                        />}
                                        {/* <USTableDropdown
                                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-list-check" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
                                            </svg>}
                                            values={[{label: 'label.Table', value: 'table'},
                                                {label: 'label.Kanban', value: 'kanban'}
                                            ]}
                                            selectedValue={handleManageListView}
                                        /> */}
                                        <button type="button" className="btn btn-secondary" onClick={() => refreshTable()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"
                                            />
                                            </svg>
                                        </button>
                                        {/* <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(!isOpen)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-pencil" viewBox="0 0 16 16">
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                            </svg>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {columns.map((headreItem, headerItemIndex) => (
                                        <th scope="col" key={`header_${headerItemIndex}`} onClick={() => updatedFieldSort(headreItem.fieldName)}><IntlMessages id={headreItem.fieldLabel}/> {sortOrderField == headreItem.fieldName && sortOrderIcon}</th>
                                    ))}
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((rowItem, rowItemIndex) => (
                                    <tr key={`row_${rowItemIndex}`}>
                                        {columns.map((cellItem, cellItemIndex) => (
                                            <td key={`cell_${cellItemIndex}`} onDoubleClick={(e) => editTableCell(e, cellItem.fieldName, rowItemIndex, cellItemIndex)}>
                                                {(isEditCell.row == rowItemIndex && isEditCell.cell == cellItemIndex) ?
                                                    <div className="input-group">
                                                        {cellItem.fieldType == 'string' && <input type="text" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={() => handleBlur(rowItemIndex)}></input>}
                                                        {(cellItem.fieldType == 'datetime' || cellItem.fieldType == 'date') && <input type="datetime-local" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={() => handleBlur(rowItemIndex)}></input>}
                                                        {cellItem.fieldType == 'integer' && <input type="number" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={() => handleBlur(rowItemIndex)}></input>}
                                                        {cellItem.fieldType == 'float' && <input type="number" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={() => handleBlur(rowItemIndex)}></input>}
                                                        {cellItem.fieldType == 'boolean' && <input className="form-control" type="checkbox" value="" id={`checkbox_${cellItemIndex}`} checked={rowItem[cellItem.fieldName] ? true : false} onChange={() => changeCellValue(!rowItem[cellItem.fieldName], rowItemIndex, cellItem.fieldName)} onBlur={() => handleBlur(rowItemIndex)}/>}
                                                    </div>
                                                    :
                                                    (cellItem.fieldType == 'bigint') ?
                                                        <a onClick={() =>
                                                            navigate.push(`${currentAppUrl? '/'+currentAppUrl:''}/view/${cellItem?.referenceObject ? cellItem?.referenceObject : objectName}/${rowItem[cellItem.fieldName]}`)
                                                        }>{cellItem.fieldName != 'id' ? getRelatedObjectName(cellItem.referenceObject, rowItem[cellItem.fieldName]) : 'view'}</a>
                                                    :
                                                        <div>
                                                            {cellItem.fieldType == 'boolean' && <input className="form-control" type="checkbox" value="" id={`checkbox_${cellItemIndex}`} checked={rowItem[cellItem.fieldName] ? true : false} />}
                                                            {(cellItem.fieldType != 'datetime' && cellItem.fieldType != 'date' && cellItem.fieldType != 'boolean') && rowItem[cellItem.fieldName]}
                                                            {(cellItem.fieldType == 'datetime' || cellItem.fieldType == 'date') && butifyDateFormat(rowItem[cellItem.fieldName])}
                                                        </div>
                                                }
                                            </td>
                                        ))}
                                        <td>
                                            <ul className="action-list">
                                                <li><ModeEditOutlineOutlinedIcon onClick={() => navigate.push(`${currentAppUrl? '/'+currentAppUrl:''}/view/${objectName}/${rowItem['id']}`)}/></li>
                                                <li><DeleteForeverOutlinedIcon  onClick={() => handleDelete(rowItem['id'])}/></li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="panel-footer">
                        <div className="row">
                            <div className="col col-sm-6 col-xs-6">Показано {(rowsPerPage < recordsCount) ? rowsPerPage: recordsCount} из {recordsCount} записей</div>
                            <div className="col-sm-1 col-xs-1">
                                <select className="form-select form-select-sm" value={rowsPerPage} onChange={(e) => setRowPerPage(e.target.value)}>
                                    <option >10</option>
                                    <option >25</option>
                                    <option >50</option>
                                    <option >100</option>
                                </select>
                            </div>
                            <div className="col-sm-5 col-xs-5">
                                <ul className="pagination hidden-xs justify-content-end">

                                    <li><a href="#" onClick={(e) => setPageNumber(e, currentPage-1)}>{prevRow}</a></li>
                                    {
                                       numberOfPages && getPagination(numberOfPages)
                                    }
                                    <li><a href="#" onClick={(e) => setPageNumber(e, currentPage+1)}>{nextRow}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openListView && <USListViewDialog isOpen={openListView} objectName={objectName} selectedListView={selectedListView} handleClose={handleClose} selectedAction={listViewAction}/>}
        </div>
    );

}

export default USTableNew;
