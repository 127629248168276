import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, AppBar, Drawer, IconButton, Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import screenfull from 'screenfull';
import MenuIcon from '@mui/icons-material/Menu';
import { collapsedSidebarAction } from '../../../reduxSettings/reduxSlices/basicSlice';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
// components
import Notifications from './components/Notifications';
import ChatSidebar from './components/ChatSidebar';
//import DashboardOverlay from '../DashboardOverlay/DashboardOverlay';
import LanguageProvider from './components/LanguageProvider';
import SearchForm from './components/SearchForm';
import QuickLinks from './components/QuickLinks';
import MobileSearchForm from './components/MobileSearchForm';
import Cart from './components/Cart';

import { getUserDetails, logout } from '../../../reduxSettings/reduxSlices/userAuthSlice';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
        transform: 'scale(.8)',
        opacity: 1,
        },
        '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
        },
    },
}));

 function USHeader({cartReference, showCart, children}) {
    //const { isMobileSearchFormVisible } = this.state;
    //const { horizontalMenu, agencyMenu, location } = this.props;

    const { isLogged, user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    // automatically authenticate user if token is found
    useEffect(() => {
        if(isLogged) {
            //dispatch(getUserDetails())
        }
    }, [isLogged])

    const val = useSelector((state) => state.basic.navCollapsed);

    const navigate = useHistory();
    const [customizer, setCustomizer] = useState(false);
    const [isMobileSearchFormVisible, setIsMobileSearchFormVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // function to change the state of collapsed sidebar
    const onToggleNavCollapsed = (event) => {
        dispatch(collapsedSidebarAction(!val));
    }

    // open dashboard overlay
    const openDashboardOverlay = (e) => {
        var el = document.getElementsByClassName('dashboard-overlay')[0];
        el.classList.toggle("d-none");
        el.classList.toggle("show");
        if (el.classList.contains('show')) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "";
        }
        e.preventDefault();
    }

    // close dashboard overlay
    function closeDashboardOverlay() {
        var e = document.getElementsByClassName('dashboard-overlay')[0];
        e.classList.remove('show');
        e.classList.add('d-none');
        document.body.style.overflow = "";
    }

    // toggle screen full
    function toggleScreenFull() {
        screenfull.toggle();
    }

    // mobile search form
    function openMobileSearchForm() {
        setIsMobileSearchFormVisible(true);
    }

    const horizontalMenu = false;
    const agencyMenu = false;

    const checkIfNull = (val) => {
           return val != null ? val : false;
    }
    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <div className="d-flex align-items-center">
                    {(horizontalMenu || agencyMenu) &&
                        <div className="site-logo">
                            <Link to="/" className="logo-mini">
                                <img src={require('Assets/sidebar/img/appLogo.png').default} className="mr-15" alt="site logo" width="35" height="35" />
                            </Link>
                            <Link to="/" className="logo-normal">
                                <img src={require('Assets/sidebar/img/appLogo.png').default} className="img-fluid" alt="site-logo" width="67" height="17" />
                            </Link>
                        </div>
                    }
                    {!agencyMenu &&
                        <ul className="list-inline mb-0 navbar-left">
                            {!horizontalMenu ?
                                <li className="list-inline-item" onClick={(e) => onToggleNavCollapsed(e)}>
                                    <Tooltip title="Sidebar Toggle" placement="bottom">
                                        <IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
                                            <MenuIcon />
                                        </IconButton>
                                    </Tooltip>
                                </li> :
                                <li className="list-inline-item">
                                    <Tooltip title="Sidebar Toggle" placement="bottom">
                                        <IconButton color="inherit" aria-label="Menu" className="humburger p-0" component={Link} to="/">
                                            <i className="ti-layout-sidebar-left"></i>
                                        </IconButton>
                                    </Tooltip>
                                </li>
                            }
                            {!horizontalMenu && <QuickLinks />}
                            <li className="list-inline-item search-icon d-inline-block">
                                <SearchForm />
                                <IconButton mini="true" className="search-icon-btn" onClick={() => openMobileSearchForm()}>
                                    <i className="zmdi zmdi-search"></i>
                                </IconButton>
                                <MobileSearchForm
                                    isOpen={isMobileSearchFormVisible}
                                    onClose={() => setIsMobileSearchFormVisible(false)}
                                />
                            </li>
                        </ul>
                    }
                </div>
                <ul className="navbar-right list-inline mb-0">
                    <li className="list-inline-item summary-icon">
                        <Tooltip title="Summary" placement="bottom">
                            <a href="#" className="header-icon tour-step-3" onClick={(e) => openDashboardOverlay(e)}>
                                <i className="zmdi zmdi-info-outline"></i>
                            </a>
                        </Tooltip>
                    </li>
                    {/*<LanguageProvider />*/}
                    <Notifications />
                    {showCart && <Cart cartReference={cartReference} />}
                    {/*<li className="list-inline-item setting-icon">
                        <Tooltip title="Chat" placement="bottom">
                            <IconButton aria-label="settings" onClick={() => setCustomizer(true)}>
                                <i className="zmdi zmdi-comment"></i>
                            </IconButton>
                        </Tooltip>
                    </li>*/}
                    <li className="list-inline-item">
                        <Tooltip title="Full Screen" placement="bottom">
                            <IconButton aria-label="settings" onClick={() => toggleScreenFull()}>
                                <i className="zmdi zmdi-crop-free"></i>
                            </IconButton>
                        </Tooltip>
                    </li>
                    <li className="list-inline-item">
                        <Tooltip title="Profile" placement="bottom">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    >
                                        {!isLogged && <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />}
                                        {isLogged && <Avatar alt="Remy Sharp" src={user?.LogoUrl} />}
                                </StyledBadge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={checkIfNull(open)}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                                },
                                '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {(isLogged && user?.settings?.ProfileSettings) && <MenuItem onClick={() => navigate.push("/user")}>
                                <Avatar sx={{ width: 24, height: 24 }}/> Profile
                            </MenuItem>}
                            {isLogged && <MenuItem onClick={() => navigate.push("/")}>
                                <Avatar sx={{ width: 24, height: 24 }}/> My account
                            </MenuItem>}
                            {children}
                            {isLogged && <Divider />}
                            {(isLogged && user?.settings?.OrgSettings) && <MenuItem onClick={() => navigate.push("/setup")}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                            </MenuItem>}
                            {!isLogged && <MenuItem onClick={() => navigate.push(cartReference ? cartReference + "/login" : "/login")}>
                                <ListItemIcon>
                                    <LoginOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                Login
                            </MenuItem>}
                            {isLogged && <MenuItem onClick={() => dispatch(logout())}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>}
                        </Menu>
                    </li>
                </ul>
                <Drawer
                    anchor={'right'}
                    open={ customizer ? true : false }
                    onClose={() => setCustomizer(false)}
                >
                    <ChatSidebar />
                </Drawer>
            </Toolbar>
        </AppBar>
    );
}

 export default USHeader;
