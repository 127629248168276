import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../../../../reduxSettings/reduxSlices/NotificationSlice';
import { Avatar, Button, Card, CardContent, CardHeader } from '@mui/material';
import { red } from '@mui/material/colors';
import IntlMessages from '../../../../../../util/IntlMessages';
import USCMLayout from './USCMLayout';
import { getPredefinedRecord } from '../../../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { useHistory } from 'react-router-dom';
import { postRequest } from '../../../../../../Api/ApiService/Service';
import { closeNewRecordDialog } from '../../../../../../reduxSettings/reduxSlices/systemSlice';

export default function USNewRecord({recordData}) {
    const navigate = useHistory();
    const [readRecord, setReadRecord] = useState(false);
    const [sections,setSections] = useState(null);
    const [objectFields, setObjectFields] = useState(null);
    const [referendeObject, setReferenceObject] = useState(null);
    const [objectName, setObjectName] = useState();
    useEffect(() => {
        fetchData(recordData);
        setObjectName(recordData?.objectName);
    },[recordData])

    const dispatch = useDispatch();

    const fetchData = async (recordData) => {
        try {
            const currentRecord = await getPredefinedRecord(recordData);
            let hasSections = currentRecord?.layout?.sections ? true : false;
            let hasData = currentRecord?.recordData ? true : false;
            let hasRelatedObjects = currentRecord?.relatedObjects ? true : false;

            if(hasRelatedObjects) {
                setReferenceObject(currentRecord?.relatedObjects);
            }
            if(hasData) {
                let currSelRec = currentRecord?.recordData;
                setObjectFields(currSelRec);
                setRecordToSave(JSON.parse(JSON.stringify(currSelRec)));
            }
            if(hasSections) {
                setSections(currentRecord?.layout.sections);
            }
        } catch (error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const saveCurrentRecord = async(recToSave) => {
        try {
            const response = await postRequest('/company/edit/'+objectName, recToSave);
            if(response.status == 200) {
                dispatch(addNotification({"type" : "info", "message" : response.data.data.message}));
                dispatch(closeNewRecordDialog());
            } else {
                dispatch(addNotification({"type" : "error", "message" : "Error record creation"}));
            }
        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const [recordToSave, setRecordToSave] = useState();
    const updateRecordDetails = (e) => {
        //const fieldName = e.target.name;
        // let clonedObjectFields = JSON.parse(JSON.stringify(recordToSave));
        // clonedObjectFields[fieldName] = e.target.value;
        setRecordToSave(existingValues => ({
            ...existingValues,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSaveRecord = () => {
        saveCurrentRecord(recordToSave);
    }

    return (
        <Card >
            {objectName && <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        T
                    </Avatar>
                }
                action={
                    <>
                        <div>
                            <Button size="small" variant="outlined" className="ml-5" onClick={() => handleSaveRecord(navigate)}>Save</Button>
                            <Button size="small" variant="outlined" className="ml-5" onClick={() => {setReadRecord(true); dispatch(closeNewRecordDialog())}}>Cancel</Button>
                        </div>
                    </>
                }
                title={<IntlMessages id={objectName} defaultMessage={objectName}/>}
                subheader="Record"
            />}
            <CardContent>
                {sections && <USCMLayout objectName={objectName} sections={sections} providedObjectFields={objectFields} readOnly={readRecord} referendeObject={referendeObject} updateRecordDetails={updateRecordDetails}/>}
            </CardContent>
        </Card>
    )
}
