module.exports = {
    "customtab.AccountId" : "Account",
    "customtab.Component" : "Компонент",
    "customtab.created_at" : "Дата Создания",
    "customtab.updated_at" : "Дата обновления",
    "customtab.Description" : "Описание",
    "customtab.id" : "id",
    "customtab.isActive" : "Активно",
    "customtab.Label" : "Label",
    "customtab.Name" : "Название",
    "customtab.Object" : "Объект",
    "customtab.PageStyle" : "Стиль Страницы",
    "customtab.TabStyle" : "Стиль вкладки",
    "passport" : "Номер паспорта",
    "IssuedDate" : "Дата выдачи",
    "user.username" : "Имя",
    "user.sex" : "Пол",
    "user.placeOfBirth" : "Место рождения",
    "user.citizenship" : "Гражданство",
    "user.alias" : "Ник",
    "user.localSettings" : "Настройки локали",
    "user.timeZone" : "Временная зона",
    "user.locale" : "Локаль",
    "user.language" : "Язык",
    "user.senderName" : "Имя отпровителя",
    "user.emailSignature" : "Подпись отправителя",
    "common.tabs" : "Вкладка",
    "sidebar.quickcalculate" : "Быстрый просчет",
    "sidebar.help" : "Помощь",
    "sidebar.housemates" : "Домовладельцы",
    "sidebar.announcement" : "Уведомления",
    "sidebar.publicevent" : "События",
    "sidebar.survey" : "Опрос",
    "sidebar.console" : "Консоль",
    "sidebar.partcategory" : "Категории Модулей",
    "sidebar.Lead" : "Лиды",
    "sidebar.Tasks" : "Задачи",
    "sidebar.opportunity" : "Продажи",
    "sidebar.contracts" : "Договора",
    "sidebar.production" : "Производство",
    "sidebar.contact" : "Контакты",
    "carparts.suppliers" : "Поставщики",
    "carparts.orders" : "Заказы",
    "carparts.warehouse" : "Склады",
    "carparts.delivery" : "Доставка",
    "carparts.settings" : "Настройки",

    "carparts.customers": "Клиенты",

    "quicklinks.settings" : "Администрирование" ,
    "quicklinks.portal" : "Портал Компании" ,
    "quicklinks.service" : "Сервисная консоль" ,
    "quicklinks.sales" : "Консоль продаж" ,
    "quicklinks.profile" : "Пользователь" ,
    "quicklinks.site" : "Сайт компании" ,
    "production.name" : "Производство",


    "common.edit" : "Edit",
    "common.save" : "Save",
    "common.cancel" : "Cancel",
    "common.assignToUser" : "Assign To User",
    'account.table-name' : 'Account',
    'account.Name' : 'Account',

    "event.id" : "id",
    "event.Name" : "Name",
    "event.Subject" : "Subject",
    "event.Type" : "Type",
    "event.SubType" : "SubType",
    "event.Description" : "Description",
    "event.Status" : "Status",
    "event.Location" : "Location",
    "event.StartDate" : "Start Date",
    "event.EndDate" : "End Date",
    "event.Duration" : "Duration",
    "event.Reminder" : "Reminder",
    "event.isPublic" : "is Public",
    "event.isRepeatable" : "is Repeatable",
    "event.AssignedToId" : "Assigned To",
    "event.CreatedById" : "Created By",
    "event.LastModifiedById" : "Last Modified By",
    "event.AccountId" : "Account",
    "event.created_at" : "Crated Date",
    "event.updated_at" : "Updated Date",

    "dropdown.id" : "Выпадающий список",
    "dropdown.Name" : "Название",
    "dropdown.ObjectName" : "Название объекта",
    "dropdown.Value" : "Значение",
    "dropdown.APIName" : "API Name",
    "dropdown.isActive" : "Активировать",
    "dropdown.isDefault	" : "По умолчанию",
    "dropdown.AccountId" : "Организация",
    "dropdown.CreatedById" : "Создано",
    "dropdown.LastModifiedById" : "Изменено",
    "dropdown.created_at" : "Дата создания",
    "dropdown.updated_at" : "Дата изменения",

    "document.id" : "id",
    "document.EmployeeId" : "Employee",
    "document.AccountId" : "Account",
    "document.Name" : "Name",
    "document.SerialNumber" : "Serial Number",
    "document.IssueDate" : "Issue Date",
    "document.ExpirationDate" : "Expiration Date",
    "document.created_at" : "Created Date",
    "document.updated_at" : "Updated Date",
    "document.ParentUserId" : "Parent User",
    "document.URL" : "URL",
    "dates" : "Даты",
    "relatedList.Productpartsasset" : "Комлектующиие для продукта",
    "productpartsasset.AccountId" : "Организация",
    "productpartsasset.AssetId" : "Комплектующие",
    "productpartsasset.Comment" : "Комментарий",
    "productpartsasset.created_at" : "Дата создания",
    "productpartsasset.updated_at" : "Дата изменения" ,
    "productpartsasset.TotalAmount" : "Итоговая сумма" ,
    "productpartsasset.Quantity" : "Количество" ,
    "productpartsasset.ProductpartsId" : "Часть продукта" ,
    "productpartsasset.Name" : "Название" ,
    "productpartsasset.isActive" : "Активировать" ,
    "productpartsasset.id" : "Action" ,

    "relatedList.surveyelement" : "Элементы Опросника",
    "surveyelement.AccountId" : "Организация",
    "surveyelement.ColCount" : "Количество колонок",
    "surveyelement.created_at" : "Дата создания",
    "surveyelement.CreatedById" : "Создано",
    "surveyelement.Description" : "Описание",
    "surveyelement.id" : "Id",
    "surveyelement.isActive" : "Активировать",
    "surveyelement.LastModifiedById" : "Изменено",
    "surveyelement.Name"  : "Название",
    "surveyelement.Subject" : "Субъект",
    "surveyelement.SurveyPageId" : "Страница опросника",
    "surveyelement.Type" : "Тип",
    "surveyelement.updated_at" : "Дата изменения",

    "surveyelementvalue.Fields" : "Поля опросника",
    "surveyelementvalue.id" : "id",
    "surveyelementvalue.Name" : "Название",
    "surveyelementvalue.Value" : "Значение",
    "surveyelementvalue.RowName" : "Название строки",
    "surveyelementvalue.isActive" : "Активировать",
    "surveyelementvalue.CreatedById" : "Создано",
    "surveyelementvalue.LastModifiedById" : "Изменено",
    "surveyelementvalue.AccountId" : "Организация",
    "surveyelementvalue.SurveyElementId" : "Элемент опросника",
    "surveyelementvalue.created_at" : "Дата создания",
    "surveyelementvalue.updated_at" : "Дата изменения",

    "contact.id" : "ID",
    "contact.Fields" : "Информация о контакте",
    "contact.CreatedById" : "Создано",
    "contact.LastModifiedById"  : "Дата модификации",
    "contact.ParentUserId" : "Пользователь",
    "contact.Birthdate" : "День рождения",
    "contact.Status" : "Статус",
    "contact.Description" : "Описание",
    "contact.DoNotCall" : "Не звонить",
    "contact.Email" : "Email",
    "contact.Fax" : "Факс",
    "contact.HomePhone" : "Домошний телефон",
    "contact.Mobile" : "Мобильный телефон",
    "contact.Phone" : "Телефон",
    "contact.OtherPhone" : "Дополнительный телефон",
    "contact.FirstName" : "Имя",
    "contact.LastName" : "Фамилия",
    "contact.MiddleName" : "Отчество",
    "contact.Title" : "Заголовок",
    "contact.Name" : "Название",
    "contact.LastModifiedDate" : "Дата изменения",
    "contact.CreatedDate" : "Дата создания",
    "contact.created_at" : "Дата создания",
    "contact.updated_at" : "Дата изменения",
    "contact.AccountId" : "Организация",
    "contact.Type" : "Тип",
    "contact.EmployeeId" : "Работник",
    "contact.isSupplier" : "Поставщик",
    "contact.Сurrency" : "Валюта",
    "contact.DeliveryTime" : "Дата доставки",
    "contact.Color" : "Цвет",
    "contact.OnMyStorehouse" : "На складе",
    "contact.Sort" : "Сортировка",
    "contact.OnOrder" : "On Order",
    "contact.InInstalments" : "В рассрочку",
    "contact.forRegisteredUsers" : "for Registered Users",
    "contact.PriceUpdated" : "Price Updated",
    "contact.ExternalName" : "External Name",

    "task.Fields" : "Поля",
    "relatedList.Task" : "Задания",
    "task" : "Задание",
    "description" : "Описание",
    "task.Fields" : "Поля",
    "task.id" : "id",
    "task.Name" : "Название",
    "task.Phone" : "Телефонный номер",
    "task.Source" : "Источник",
    "task.ContactId" : "Контакт",
    "task.Subject" : "Субъект",
    "task.Type" : "Тип",
    "task.SubType" : "Под-тип",
    "task.Description" : "Описание",
    "task.Priority" : "Приоритет",
    "task.Status" : "Статус",
    "task.CompletedDate" : "Дата завершения",
    "task.DueDate" : "Дата выполнения",
    "task.Reminder" : "Напоминание",
    "task.AssignedToId" : "Назначена на",
    "task.CreatedById" : "Создано",
    "task.LastModifiedById" : "Изменено",
    "task.AccountId" : "Организация",
    "task.LeadId" : "Лид",
    "task.created_at" : "Дата создания",
    "task.updated_at" : "Дата изменения",

    "lead" : "Потенциальный клиент",
    "lead.Fields" : "Поля",
    "lead.Name" : "Имя",
    "lead.FirstName" : "Имя",
    "lead.LastName" : "Фамилия",
    "lead.MiddleName" : "Фамилия",
    "lead.Description" : "Описание",
    "lead.Type" : "Тип",
    "lead.Price" : "Прайс",
    "lead.Phone" : "Телефон",
    "lead.Email" : "email",
    "lead.Website" : "Сайт",
    "lead.Fax" : "Факс",
    "lead.Status" : "Статус",
    "Settings" : "Настройки",
    "lead.WhatsAppOptIn" : "Whatsapp",
    "lead.SMSOptIn" : "SMS",
    "lead.ViberOptIn" : "Viber",
    "lead.TelegramOptIn" : "Telegram",
    "lead.DoNotCall" : "Не звонить",
    "Description" : "Описание",
    "lead.Priority" : "Приоритет",
    "lead.LeadSource" : "Источник",
    "lead.Company" : "Организация",
    "lead.Industry" : "Индустрия",
    "lead.SubType" : "Под-тип",
    "lead.Rating" : "Рейтинг",
    "Dates" : "Даты",
    "lead.CompletedDate" : "Дата завершения",
    "lead.created_at" : "Дата создания",
    "lead.updated_at" : "Дата обновления",
    "lead.ContactId" : "Контакт",
    "lead.CreatedById" : "Создан",
    "lead.LastModifiedById" : "Модифицирован",
    "lead.convertToOpportunity" : "Конвертировать",
    "contract.Fields" : "Поля",
    'Contract.Details' : "Информация о контракте",
    "contract.Fields" : "Информация о контракте",
    "contract" : "Контаркты",
    "contract.Price" : "Прайс",
    "contract.PrePayment" : "Предоплата",
    "contract.TotalPrice" : "Итоговый прайс",
    "contract.Discount" : "Скидка",
    "contract.AccountId" : "ОРганизация",
    "contract.ContractEndDate" : "Дата завершения контракта",
    "contract.ContractNumber" : "Номер контракта",
    "contract.ContractStartDate" : "Дата заключения",
    "contract.created_at" : "Дата создания",
    "contract.CreatedById" : "Создан",
    "contract.CreatedDate" : "Дата создания",
    "contract.id" : "Id",
    "contract.LastModifiedById" : "Изменено",
    "contract.LastModifiedDate" : "Дата изменения",
    "contract.Name" : "Название",
    "contract.updated_at" : "Дата изменения",
    "contract.OpportunityId" : "Продажа",
    "contract.ContactId" : "Контакт",

    "productionqueue" : "Очередь производства",
    "productionqueue.Fields" : "Поля",
    "productionqueue.id" : "Id",
    "productionqueue.Name" : "Название",
    "productionqueue.Status" : "Статус",
    "productionqueue.Type" : "Тип",
    "productionqueue.AccountId" : "Организация",
    "productionqueue.ContractId" : "Договор",
    "productionqueue.StartDate" : "Начало производства",
    "productionqueue.EndDate" : "Дата исполнения",
    "productionqueue.FinishedDate" : "Фактическая дата исполнения",
    "productionqueue.OpportunityProductId" : "Продукт Продажи",
    "productionqueue.Order" : "Очередность",
    "productionqueue.ResponsableId" : "Ответсвенный",

    "opportunityproduct.Fields" : "",
    "relatedList.Opportunityproduct" : "Продукты продажи",
    "opportunityproduct.Type" : "Тип",
    "opportunityproduct.id" : "Id",
    "opportunityproduct.isActive" : "Активировать",
    "opportunityproduct.Name" : "Имя",
    "opportunityproduct.ProductCode" : "Код Продукта",
    "opportunityproduct.Date" : "Дата",
    "opportunityproduct.Discount" : "Скидка",
    "opportunityproduct.ListPrice" : "Цена по каталогу",
    "opportunityproduct.SalesPrice" : "Цена продажи",
    "opportunityproduct.SubTotal" : "Промежуточный Итог",
    "opportunityproduct.TotalPrice" : "Итоговая Цена",
    "opportunityproduct.Quantity" : "Кол-во",
    "opportunityproduct.Description" : "Описание",
    "opportunityproduct.AccountId" : "Аккаунт",
    "opportunityproduct.OpportunityId" : "Продажа",
    "opportunityproduct.ProductCompanyId" : "Производитель",

    "roletoprofile.AccountId" : "Организация",
    "roletoprofile.created_at" : "Дата создания",
    "roletoprofile.id" : "Роль профиля",
    "roletoprofile.ProfileId" : "Профиль",
    "roletoprofile.RoleId" : "Роль",
    "roletoprofile.updated_at" : "Дата обновления",

    "label.New" : "Создать",
    "label.Clone" : "Клонировать",
    "label.Rename" : "Переименовать",
    "label.sharing" : "Доступ",
    "label.showlistfilter" : "Список фильтров",
    "label.fieldsToDisplay" : "Показать колонки",
    "label.delete" : "Удалить",
    "label.Table" : "Таблица",
    "label.Kanban" : "Kanban",

    "sidebar.employeegroup" : "Производсвенной группа",
    "employeegroup.Fields" : "Поля",
    "employeegroup" : "Производсвенной группа",
    "employeegroup.id" : "Производсвенной группа",
    "employeegroup.Name" : "Имя",
    "employeegroup.isActive" : "Активировать",
    "employeegroup.Department" : "Департамент",
    "employeegroup.AccountId" : "Организация",
    "employeegroup.created_at" : "Дата создания",
    "employeegroup.updated_at" : "Дата обновления",

    "relatedList.Employeegroupassignment" : "Работники производсвенной группы",
    "employeegroupassignment" : "Работник производсвенной группы",
    "employeegroupassignment.id" : "Работник производсвенной группы",
    "employeegroupassignment.Name" : "Имя",
    "employeegroupassignment.isActive" : "Активировать",
    "employeegroupassignment.AccountId" : "Организация",
    "employeegroupassignment.EmployeeId" : "Работник",
    "employeegroupassignment.EmployeeGroupId" : "Производсвенной группа",
    "employeegroupassignment.created_at" : "Дата создания",
    "employeegroupassignment.updated_at" : "Дата обновления",

    "relatedList.Productionqueuegroupassignment" : "Производсвенные группы для Исполнения",
    "productionqueuegroupassignment" : "Производсвенная группа для Исполнения",
    "productionqueuegroupassignment.id" : "Производсвенная группа для Исполнения",
    "productionqueuegroupassignment.Name" : "Имя",
    "productionqueuegroupassignment.isActive" : "Активировать",
    "productionqueuegroupassignment.AccountId" : "Организация",
    "productionqueuegroupassignment.ProductionqueueId" : "Производственная очерещь",
    "productionqueuegroupassignment.EmployeeGroupId" : "Группа работников",
    "productionqueuegroupassignment.created_at" : "Дата создания",
    "productionqueuegroupassignment.updated_at" : "Дата обновления",

    "productparts.AccountId" : "Организация",
    "productparts.AssetId" : "Основные активы",
    "productparts.Count" : "Соличество",
    "productparts.created_at" : "Дата создания",
    "productparts.Default" : "По умолчанию",
    "productparts.id" : "Id",
    "productparts.isActive" : "Активировать",
    "productparts.MaterialCount" : "Колличество матераиалов",
    "productparts.Name" : "Название",
    "productparts.Price" : "Цена",
    "productparts.Series" : "Серия",
    "productparts.Type" : "Тип",
    "productparts.updated_at" : "Дата обновления",
    "productparts.Fields" : "Информация о детали продукта",
    "productparts.Width":"Ширина",
    "productparts.Height":"Высота",
    "productparts.Length":"Длина",
    "productparts.Radius":"Радиус",
    "relatedList.Productcompanyparts" : "Детали продукта",
    "productcompanyparts.AccountId" : "Организация" ,
    "productcompanyparts.Count" : "Колличество" ,
    "productcompanyparts.id" : "Id" ,
    "productcompanyparts.isActive" : "Активировать" ,
    "productcompanyparts.Name" : "Название" ,
    "productcompanyparts.ProductCompanyId" : "Продукт" ,
    "productcompanyparts.ProductPartsId" : "Деталь продукта" ,
    "productcompanyparts.Slug" : "Slug" ,
    "productcompanyparts.Type" : "Тип" ,
    "productcompanyparts.isDefault" : "По умолчанию" ,
    "productcompanyparts.Price" : "Цена",

    "productcategory.id" : "Id",
    "productcategory.Name" : "Name",
    "productcategory.ShowInMenu" : "Show In Menu",
    "productcategory.SortOrder" : "Order",
    "productcategory.Description" : "Description",
    "productcategory.AccountId" : "Account",
    "productcategory.ParentCategoryId" : "Parent Category",
    "productcategory.ProductCatalogId" : "Catalog",
    "productcategory.Type" : "Type",
    "productcategory.Items" : "Items",
    "productcategory.ProductCatalogId_depricated" : "Удалить",

    "productcatalog.Fields" : "Catalog",
    "productcatalog.id" : "Id",
    "productcatalog.Name" : "Name",
    "productcatalog.AccountId" : "Account",
    "productcatalog.Type" : "Type",
    "productcatalog.Slug" : "Slug",
    "productcatalog.Order" : "Order",
    "productcatalog.StoreId" : "Store",
    "productcatalog.Items" : "Items",
    "productcatalog.Image" : "Image",

    "relatedList.Address" : "Адреса",
    "address.Fields" : "Информация об адресе",
    "address.id" : "Id",
    "address.AccountId" : "Организация",
    "address.City" : "Город",
    "address.Country" : "Страна",
    "address.OrderId" : "Заказ",
    "address.Street" : "Улица",
    "address.ZipCode" : "Почтовый индекс",
    "address.AssetId" : "Объект",
    "address.ContactId" : "Контакт",
    "address.StateProvince" : "Область",
    "address.UserId" : "Пользователь",

    "relatedList.Feedback" : "Отзывы",
    "feedback.UserReply" : "Пользователь",
    "feedback.UserPost" : "Пост",
    "feedback.updated_at" : "Дата создания",
    "feedback.Status" : "Статус",
    "feedback.Product" : "Продукт",
    "feedback.ParentFeedback" : "Родительский отзыв",
    "feedback.id" : "Отзыв",
    "feedback.Likes" : "Likes",
    "feedback.Description" : "Описание",
    "feedback.created_at" : "Дата создания",

    "relatedList.Image" : "Изображение",
    "image.Title" : "Title",
    "image.alt" : "Alt",
    "image.URL" : "URL",
    "image.Active" : "Active",

    "relatedList.Contactrequest" : "Запросы",
    "contactrequest.id" : "Callback Request",
    "contactrequest.Name" : "Название",
    "contactrequest.Type" : "Тип",
    "contactrequest.Description" : "Описание",
    "contactrequest.Priority" : "Приоритет",
    "contactrequest.AccountId" : "Организация",
    "contactrequest.ContactId" : "Контакт",
    "contactrequest.created_at" : "Дата создания",
    "contactrequest.updated_at" : "Дата обновления",
    "contactrequest.Phone" : "Телефон",
    "contactrequest.StoreId" : "Магазин",
    "contactrequest.Status" : "Статус",
    "contactrequest.City" : "Город",
    "contactrequest.VisitDate" : "Дата встречи",
    "contactrequest.ContactDate" : "Дата контакта",

    "relatedList.Workschedule" : "Workschedules",
    "workschedule.start":"Start",
    "workschedule.lunch":"Lunch",
    "workschedule.finish":"Finish",
    "workschedule.id" : "Workschedule",
    "workschedule.EmployeeId" : "Employee",
    "workschedule.AccountId" : "Account",
    "workschedule.SundayStart" : "Sun. Start",
    "workschedule.MondayStart" : "Mon. Start",
    "workschedule.TuesdayStart" : "Tue. Statr",
    "workschedule.WednesdayStart" : "Wed. Start",
    "workschedule.ThursdayStart" : "Thu. Start",
    "workschedule.FridayStart" : "Fri. Start",
    "workschedule.SaturdayStart" : "Sat. Start",
    "workschedule.SundayFinish" : "Sun. Finish",
    "workschedule.MondayFinish" : "Mon. Finish",
    "workschedule.TuesdayFinish" : "Tue. Finish",
    "workschedule.WednesdayFinish" : "Wed. Finish",
    "workschedule.ThursdayFinish" : "Thu. Finish",
    "workschedule.FridayFinish" : "Fri. Finish",
    "workschedule.SaturdayFinish" : "Sat. Finish",
    "workschedule.SundayLunch" : "Sun. Lunch",
    "workschedule.MondayLunch" : "Mon. Lunch",
    "workschedule.TuesdayLunch" : "Tue. Lunch",
    "workschedule.WednesdayLunch" : "Wed. Lunch",
    "workschedule.ThursdayLunch" : "Thu. Lunch",
    "workschedule.FridayLunch" : "Fri. Lunch",
    "workschedule.SaturdayLunch" : "Sat. Lunch",
    "workschedule.created_at" : "Created Date",
    "workschedule.updated_at" : "Updated Date",
    "workschedule.AddressId" : "Address",

    "store.id" : "Id",
    "store.Name" : "Name",
    "store.AccountId" : "Account",
    "store.Description" : "Description",
    "store.Country" : "Country",
    "store.DefaultCurrency" : "Default Currency",
    "store.DefaultLanguage" : "Default Language",
    "store.OrderActivationStatus" : "Order Activation Status",
    "store.OrderLifeCycleType" : "Life Cycle Type",
    "store.PricingStrategy" : "Pricing Strategy",
    "store.ResultsPerPage" : "Results Pre Page",
    "store.isActive" : "Is Active",

    'asset.Fields' : "Action",
    'asset.id' : "Action",
    'asset.Name' : "Название",
    'asset.CreatedById' : "Создатель",
    'asset.LastModifiedById' : "Обновлено",
    'asset.Address' : "Адрес",
    'asset.AssetLevel' : "Уровень доступа",
    'asset.CurrentAmount' : "Цена",
    'asset.CurrentQuantity' : "Количество",
    'asset.Description' : "Описание",
    'asset.ExternalId' : "Внешний ID",
    'asset.HasLifecycleManagment' : "Есть период обслуживания",
    'asset.InternalAsset' : "Собственность организации",
    'asset.InstallDate' : "Дата установки",
    'asset.LifecycleStartDate' : "Дата внедрения",
    'asset.LifecycleEndDate' : "Дата списания",
    'asset.ManufactureDate' : "Дата производства",
    'asset.ProductCode' : "Код продукта",
    'asset.ProductDescription' : "Описание продукта",
    'asset.PurchaseDate' : "Дата оплаты",
    'asset.Price' : "Цена",
    'asset.SerialNumber' : "Серийный номер",
    'asset.UsageEndDate' : "Дата окончания использования",
    'asset.ProductFamily' : "Семейство продукта",
    'asset.Status' : "Статус",
    'asset.StatusReason' : "Описание статуса",
    'asset.LastModifiedDate' : "Дата обновления",
    'asset.CreatedDate' : "Дата создания",
    'asset.created_at' : "Дата создания",
    'asset.updated_at' : "Дата обновления",
    'asset.AccountId' : "Организация",
    'asset.ProvidedById' : "Поставщик",
    'asset.ServicedById' : "Обслуживающая организация ",
    'asset.ProductId' : "Продукт",
    'asset.EmployeeId' : "Работник",
    'asset.InventoryNumber' : "Инвентарный номер",
    'asset.AssignmentDate' : "Дата передачи",
    'asset.Type' : "Тип",
    'asset.EnsuranceStartDate' : "Страховка с",
    'asset.EnsuranceEndDate' : "Страховка до",
    "sidebar.requests" : "Запросы",
    "request" : "Запрос",
    "request.id" : "Id",
    "request.Name" : "Имя" ,
    "request.Type" : "Тип",
    "request.Reason" : "Причина",
    "request.AssetId" : "Запрос на",
    "request.RequestedById" : "Создал запрос",
    "request.AssignedToId" : "Ответсвенный",
    "request.AccountId" : "Организация",
    "request.Fields" : "Поля запроса",

    "productcharacteristics" : "Характеристики продукта",
    "productcharacteristics.Account" : "Организация",
    "productcharacteristics.isDefault" : "По Умолчанию",
    "productcharacteristics.Product" : "Продукт",
    "productcharacteristics.Type" : "Тип",

    "characteristicitem.id" : "Id",
    "characteristicitem.Name" : "Название",
    "characteristicitem.isActive" : "Активный",
    "characteristicitem.CharacteristicsGroupId" : "Группа Характеристик",
    "characteristicitem.Order" : "Очередность",
    "characteristicitem.ProductCharacteristicsId" : "Характеристики продукта",
    "characteristicitem.ProductId" : "Продукт",
    "characteristicitem.Type" : "Тип",
    "relatedList.Characteristicsgroupitem" : "Элемент группы характеристик",

    "characteristicsgroup" : "Группа характеристик",
    "characteristicsgroup.Fields" : "Поля",
    "characteristicsgroup.Name" : "Название",
    "characteristicsgroup.created_at" : "Дата Создания",
    "characteristicsgroup.isActive" : "Активный",
    "characteristicsgroup.updated_at" : "Дата изменения",
    "relatedList.Characteristicitem" : "Характеристика",

    "characteristicsgroupitem" : "Элемент Группы Характеристик",
    "characteristicsgroupitem.Fields" : "Поля",
    "characteristicsgroupitem.AccountId" : "Организация",
    "characteristicsgroupitem.created_at" : "Дата создания",
    "characteristicsgroupitem.isActive" : "Активный",
    "characteristicsgroupitem.ProductCharacteristicsId" : "Характеристика Продукта",
    "characteristicsgroupitem.CharacteristicsGroupId" : "Группа характеристик",
    "characteristicsgroupitem.id" : "Id",
    "characteristicsgroupitem.Name" : "Название",
    "characteristicsgroupitem.updated_at" : "Дата изменения",

    "sidebar.productionqueue" : "Производственная очередь",

    "emailconnectsettings.Name" : "Name",
    "emailconnectsettings.isActive" : "is Active",
    "emailconnectsettings.Host" : "Host",
    "emailconnectsettings.Port" : "Port",
    "emailconnectsettings.Encryption" : "Encryption",
    "emailconnectsettings.ValidateCerteficate" : "Validate Certeficate",
    "emailconnectsettings.Username" : "Username",
    "emailconnectsettings.Password" : "Password",
    "emailconnectsettings.AccountId" : "Account",
    "emailconnectsettings.created_at" : "Created Date",
    "emailconnectsettings.updated_at" : "Last Update Date",

    "emailfiltersettings.Name" : "Name",
    "emailfiltersettings.isActive" : "is Active",
    "emailfiltersettings.EmailFrom" : "Email From",
    "emailfiltersettings.FolderName" : "Folder Name",
    "emailfiltersettings.SubjectHas" : "Subject Has",
    "emailfiltersettings.TextHas" : "Text Has",
    "emailfiltersettings.DateFrom" : "Date From",
    "emailfiltersettings.EmailConnectSettingsId " : "Email Connect Settings",
    "emailfiltersettings.AccountId" : "Account",
    "emailfiltersettings.created_at" : "Created Date",
    "emailfiltersettings.updated_at" : "Last Update Date",

    "filemapping.Name" : "Name",
    "filemapping.isActive" : "is Active",
    "filemapping.EmailConnectSettingsId" : "Email Connect Settings",
    "filemapping.EmailFilterSettingsId" : "Email Filter Settings",
    "filemapping.AccountId" : "Account",
    "filemapping.created_at" : "Created Date",
    "filemapping.updated_at" : "Last Update Date",

    "filemappingitem.Name" : "Name",
    "filemappingitem.isActive" : "is Active",
    "filemappingitem.ColumnFrom" : "Email Connect Settings",
    "filemappingitem.ColumnTo" : "Email Filter Settings",
    "filemappingitem.SkipIfEmpty" : "Skip If Empty",
    "filemappingitem.FileMappingId " : "File Mapping",
    "filemappingitem.AccountId" : "Account",
    "filemappingitem.created_at" : "Created Date",
    "filemappingitem.updated_at" : "Last Update Date",

    "bankdetails.id" : "Bank Details",
    "bankdetails.Name" : "Name",
    "bankdetails.AccountId" : "Account",
    "bankdetails.AccountNumber" : "Account Number",
    "bankdetails.BankCorrespondNumber" : "Bank Correspond Number",
    "bankdetails.BIC" : "BIC",
    "bankdetails.City" : "City",
    "bankdetails.Country" : "Country",
    "bankdetails.CodeOfBankOffice": "Code Of Bank Office",
    "bankdetails.CreatedById" : "Created By",
    "bankdetails.CreatedDate" : "CreatedDate",
    "bankdetails.Description" : "Description",
    "bankdetails.EmployeeId" : "Employee",
    "bankdetails.isMain" : "is Main",
    "bankdetails.LastModifiedById" : "Last Modified By",
    "bankdetails.LastModifiedDate" : "Last Modified Date",
    "bankdetails.StateProvince" : "State/Province",
    "bankdetails.Street" : "Street",
    "bankdetails.UNP" : "UNP",
    "bankdetails.UserId" : "User",
    "bankdetails.ZipCode" : "Zipcode",
    "bankdetails.AccountId" : "Account",

    "common.import-type":"Тип загрузки",
    "common.import-type-unite":"Объеденить прайсы",
    "common.import-type-replace":"Заменить Прайсы",
    "common.homepage" : "Главная",
    "common.hottubs" : "Hot Tubs",
    "common.brazier" : "Braziers",
    "common.terrace" : "Terrace",
    "common.contacts" : "Контакты",
    "common.aboutUs": "About Us",
    "common.service": "Service",
    "common.number": "Number",
    "common.byitnow":"Buy it now",
    "components.buyit": "Buy it",
    "common.addtocart":"Add to cart",
    "common.addtowishlist":"Add to wishlist",
    "common.birthdate" :"Birthdate",
    "common.moredetails": "More details...",
    "common.partners": "For Partners",
    "common.active":"Active",
    "common.default":"is Default",
    "common.sendEmail": "Отправить Email",
    "common.name":"Название",
    "common.profile":"Мой Профиль",
    "common.settings":"Настройки",
    "common.profileSettings":"Настройки профиля",
    "common.permissions" : "Доступы",
    "common.roleSettings":"Настройка ролей",
    "common.rolePermissions":"Доступы для Ролей",
    "common.objectmanager" : "Менеджер объектов",
    "common.product":"Товар",
    "common.load-product":"Загрузить товары",
    "common.productcode" : "Product Code",
    "common.material" : "Материал",
    "common.height" : "Высота",
    "common.width" : "Ширина",
    "common.lenght" : "Длина",
    "common.radius" : "Радиус",
    "common.Thickness" : "Толщина",
    "common.productType":"Тип товара",
    "common.available" : "В наличии",
    "common.contact":"Контакт",
    "common.manager":"Менеджер",
    "common.status":"Статус",
    "common.vendor-code": "Артикул",
    "common.price":"Цена",
    "common.price-settings":"Обработка прайсов",
    "common.checkforvin" : "Проверка по VIN",
    "common.deliveryaddress" : "Адрес доставки",
    "common.checkallprices":"Check All Prices",
    "common.quantity":"Quantity",
    "common.purchase-date":"Purchase Date",
    "common.install-date":"Install Date",
    "common.usage-end-date":"Usage End Date",
    "common.serial-number":"Serial Number",
    "common.type":"Type",
    "common.industry":"Industry",
    "common.subIndustry":"Sub-Industry",
    "common.description":"Description",
    "common.ensurance-start-date":"Ensurance Start Date",
    "common.ensurance-end-date":"Ensurance End Date",
    "common.guarantee-end-date":"Guarantee End Date",
    "common.first-name":"First Name",
    "common.last-name":"Last Name",
    "common.middle-name":"Middle Name",
    "common.middle-name-end-date":"Middle Name",
    "common.phone":"Phone number",
    "common.home-phone": "Home Phone",
    "common.fax":"Fax",
    "common.education": "Education",
    "common.additionalDetails":"Additional Details",
    "common.createdDate":"Created Date",
    "common.lastModifiedDate":"Last Modified Date",
    "common.anualRevenue":"Anual Revenue",
    "common.numberOfEmployees": "Number Of Employees",
    "common.numberOfOfices":"Number Of Ofices",
    "common.rating": "Rating",
    "common.telegram":"Telegram",
    "common.viber":"Viber",
    "common.whatsapp":"WhatsApp",
    "common.slack":"Slack",
    "common.wechat":"WeChat",
    "common.vkontakte" : "Vkontakte",
    "common.instagram" : "Instagram",
    "common.odnoklassniki" : "Odnoklassniki",
    "common.facebook" : "Facebook",
    "common.twitter" : "Twitter",
    "common.youtube" : "Youtube",
    "common.linkedIn" : "LinkedIn",
    "common.registered":"Registered",
    "common.upload":"Загрузка",
    "common.order-list":" Клиентские заказы",
    "common.new-order":"Новый заказ",
    "common.request-to-supplier":"Заказы к поставщикам",
    "common.articles" : "Articles" ,
    "common.reviews" : "Reviews" ,
    "car.brandid": "Id Марки",
    "car.ispopular": "Популярный?",
    "car.logo": "Логотип",
    "car.showonmain": "Показать на главной",
    "car.isactive": "Активный?",
    "car.car": "Легковое авто",
    "car.motobike": "Мотоцикл",
    "car.truck": "Грузовое авто",
    "car.van": "Фургон",
    "car.place-to-display": "Место отображения",
    "car.show-on-top": "Показать сверху",
    "car.show-on-bottom": "Показать снизу",
    "address.type":"Тип",
    "sidebar.marketing" : "Marketing",
    "sidebar.aboutUs" : "About Us",
   "sidebar.us_company-label" : "Company Details",
   "sidebar.us_companyDescription" : "Company Details",
   "sidebar.us_company-general" : "General",
   "sidebar.us_company-info" : "Company Information",
   "sidebar.us_company-employees" : "Employees",
   "sidebar.us_company-dashboard" : "Dasboard",
   "sidebar.us_company-assets" : "Assets",
   "sidebar.us_stores" : "Stores",
   "sidebar.site-builder": "Site Builder",
   "sidebar.assets" : "Assets",
   "sidebar.assetsDetails" : "Assets Details",
   "sidebar.us_company-sales-label": "Sales",
   "sidebar.clientsManagement": "Clients Managment",
   "sidebar.us_orders": "Заказы",
   "sidebar.orders": "Заказы",
   "sidebar.us_clients" : "Клиенты",
   "sidebar.leads" : "Лиды",
   "sidebar.opportunity" : "Сделка",
   "sidebar.us_suppliers": "Поставщики",
   "sidebar.markup": "Наценки",
   "sidebar.us_consumers": "Consumers",
   "sidebar.us_products": "Products",
   "sidebar.products": "Products",
   "sidebar.us_company-activity-label": "Activities",
   "sidebar.us_company-requests" : "Requests",
   "sidebar.us_company-events" : "Events",
   "sidebar.us_tasks": "Tasks",
   "sidebar.us_company-vacation" : "Vacation",
   "sidebar.us_company-career-label" : "Career",
   "sidebar.us_learning": "Learning",
   "sidebar.us_achievements": "Achievements",
   "sidebar.us_progress": "Progress",
   "sidebar.us_company-discounts" : "Discounts",
   "sidebar.us_company-desktop" : "Desktop",
   "sidebar.us_company-hr" : "HR Department",
   "sidebar.us_company-vacancies" : "Vacancies",
   "sidebar.us_company-positions" : "Positions",
   "sidebar.us_company-accounting" : "Accounting",
   "sidebar.us_company-accounting-calculate_salary" : "Calculate Salary",
   "sidebar.us_company-accounting-calculate_main" : "Main calculating",
   "sidebar.us_company-accounting-reports" : "Reports",
   "sidebar.company": "Company",
   "sidebar.mypurchases": "My Purchases",
   "sidebar.myfavoriteshops": "My Shops",
   "sidebar.shopshares":"My Shop Shares",
   "sidebar.myorders": "My Orders",
   "sidebar.producttrack": "Track Products",
   "sidebar.mycareer": "My Career",
   "sidebar.myjobs": "My Jobs",
   "sidebar.mycources": "My Cources",
   "sidebar.mycv": "My CV",
   "sidebar.trackvacancy": "Track Vacancies",
   "sidebar.myfinance": "My Finance",
   "sidebar.mycompany": "My Companies",
   "sidebar.realestate": "My Real Estate",
   "sidebar.myhome": "My Home",
   "sidebar.myhometasks": "My Home Tasks",
   "sidebar.wishlist": "Wish List",
   "content.company-details.main" : "General Information",
   "content.company-details.brand" : "Brand",
   "content.company-details.password" : "Password",
   "company-details.comp_name" : "Company Name",
   "company-details.full_comp_name" : "Full Company Name",
   "company-details.registrationDate" : "Registration Date",
   "company-details.registrationAddress" : "Registration Address",
   "company-details.addresses" : "Company Addresses",
   "company-details.bank-details" : "Company Bank Details",
   "company-details.issued-by" : "Issued By",
    "company-details.regDetails":"Registration Details",
   "address.billing-address" : "Billing Address",
   "address.shipping-address" : "Shipping Address",
   "address.modal-header" : "Add New Address",
   "address.country" : "Country",
   "address.street" : "Street",
   "address.city" : "City",
   "address.state" : "State/Province",
   "address.zipcode" : "Zip/Postal Code",
   "address.isRegistration" : "Registered Address",

   "address.inn" : "INN/UNP",
   "address.kpp" : "KPP",
   "address.ogrn" : "OGRN",
   "address.okpo" : "OKPO",
   "address.okato" : "OKATO",
   "address.okogy" : "OKOGY",
   "address.okfs" : "OKFS",
   "address.okopf" : "OKOPF",
   "address.okved" : "OKVED",

   "accountDetails.modal-header" : "Add New Account Details",
   "accountDetails.bank-name" : "Bank Name",
   "accountDetails.bank-address" : "Bank Address",
   "accountDetails.bank-account-number" : "Account Number",
   "accountDetails.bank-correspond-number" : "Bank Correcpond Number",
   "accountDetails.bank-bic" : "BIC",
   "accountDetails.bank-unp" : "UNP",
   "accountDetails.bank-bankcode" : "Code of bank office",
   "accountDetails.bank-ismain" : "Is Main",
   "employee" : "Работник" ,
   "employee.Fields" : "Поля",
   "employee.id" : "Работник",
   "employee.CreatedById" : "Создал",
   "employee.LastModifiedById" : "Модифицировал",
   "employee.ParentUserId" : "Связанный Пользователь",
   "employee.LastModifiedDate" : "Дата изменения",
   "employee.CreatedDate" : "Дата создания",
   "employee.created_at" : "Дата создания",
   "employee.updated_at" : "Дата изменения",
   "employee.AccountId" : "Организация",
   "employee.JobTitle" : "Профессия",
   "employee.Department" : "Департамент",
   "employee.Role" : "Роль",
   "employee.Office" : "Офис",
   "employee.HiredDate" : "Дата приема на работу",
   "employee.Active" : "Активировать",
   "employee.WorkPhone" : "Рабочий телефон",
   "employee.WorkEmail" : "Рабочий email",
   "employee.AvatarURL" : "Фото",
   "employee.Name" : "Имя",

   "employee.personal-information" :"Personal Information",
   "employee.first-name" :"First Name",
   "employee.last-name" :"Last Name",
   "employee.middle-name" :"Middle Name",
   "employee.work-phone" :"Work Phone",
   "employee.work-email" :"Work Email",
   "employee.work-telegram" :"Work telegram",
   "employee.birthdate" :"Birthdate",

   "employee.employee-information" :"Name",
   "employee.office" :"Office",
   "employee.department" :"Employee Department",
   "employee.title" :"Employee Title",
   "employee.role" :"Employee Role",
   "employee.hire-date" :"Hired Date",

   "employee.contact-information" :"Contact Information",
   "employee.phone" :"Phone",
   "employee.second-phone" :"Second Phone",
   "employee.emergency-phone" :"Emergency Phone",
   "employee.altPhone": "Alt Phone",
   "employee.email" :"Email",
   "employee.viber" :"Viber",
   "employee.telegram" :"Telegram",
   "employee.whatsapp" :"WhatsApp",

   "employee.workschedule" :"Work Schedule",
   "employee.skills" :"Skills",
   "employee.certificates" :"Certificates",
   "employee.documents" :"Documents",
   "employee.professional-assesments" :"Professional Assesments",
   "employee.professionalSkills" :"Professional Skills",
   "employee.career-path" :"Career Path",
   "employee.requests" :"Requests",
   "employee.assets" :"Assets",
   "employee.children" :"Children",
   "employee.salary" :"Salary",
   "employee.bonus" :"Bonuses",
   "employee.deduction" :"Deductions",

   "relatedList.Bonus" : "Бонусы",
   "bonus.AccountId" : "Организация",
   "bonus.Amount" : "Сумма",
   "bonus.created_at" : "Дата создания",
   "bonus.EffectiveDate" : "Дата применения",
   "bonus.EmployeeId" : "Работник",
   "bonus.id" : "Id",
   "bonus.updated_at" : "Дата изменения",

   "relatedList.Careerpath" : "Карьера",
   "careerpath.AccountId" : "Организация",
   "careerpath.Description" : "Описание",
   "careerpath.EmployeeId" : "Работник",
   "careerpath.EndDate" : "Дата окончания",
   "careerpath.id" : "Id",
   "careerpath.Name" : "Название",
   "careerpath.ParentUserId" : "Пользователь",
   "careerpath.StartDate" : "Дата начала",
   "careerpath.updated_at" : "Дата изменения",
   "careerpath.created_at" : "Дата создания",
   "relatedList.Certificate" : "Сертификаты",

   "certificate.id" : "Id",
   "certificate.AccountId": "Организация",
   "certificate.created_at" : "Дата создания",
   "certificate.Name": "Название",
   "certificate.EmployeeId" : "Работник",
   "certificate.serialNumber": "Серийный номер",
   "certificate.IssueDate": "Дата выдачи",
   "certificate.ExpirationDate": "Дата окончания",
   "certificate.ParentUserId":"Пользовательr",
   "certificate.SerialNumber":"Серийный номер",
   "certificate.updated_at":"Дата изменения",

   "relatedList.Child": "Дети",
   "child.AccountId" : "Организация",
   "child.Age" : "Возраст",
   "child.Birthdate" : "День рождения",
   "child.created_at" : "Дата создания",
   "child.EmployeeId" : "Работник",
   "child.FirstName" : "Имя",
   "child.Gender" : "Пол",
   "child.id" : "Id",
   "child.LastName" : "Фамилия",
   "child.MiddleName" : "Отчество",
   "child.ParentUserId" : "Пользователь",
   "child.updated_at" : "Дата изменения",
   "child.UserId" : "пользователь",

   "relatedList.Deduction" : "Штраф",
   "deduction.AccountId" : "Организация",
   "deduction.Amount" : "Сумма",
   "deduction.created_at" : "Дата создания",
   "deduction.EffectiveDate" : "Дата применения",
   "deduction.EmployeeId" : "Работник",
   "deduction.id" : "Id",
   "deduction.updated_at" : "Дата изменения",

   "relatedList.Document" : "Документы",
   "relatedList.Professionalassesment" : "Атестации",
   "professionalassesment.AccountId" : "Организация",
   "professionalassesment.AssesmentDate" : "Дата атестации",
   "professionalassesment.created_at" : "Дата создания",
   "professionalassesment.EmployeeId" : "Работник",
   "professionalassesment.id" : "Id",
   "professionalassesment.RequsterId" : "Создал запрос",
   "professionalassesment.Status" : "Статус",
   "professionalassesment.updated_at" : "Дата изменения",

   "relatedList.Salary" : "Зарплаты",
   "salary.AccountId" : "Организация",
   "salary.created_at" : "Дата создания",
   "salary.EffectiveDate" : "Дата применения",
   "salary.EmployeeId" : "Работник",
   "salary.id" : "Id",
   "salary.Rate" : "Рейт",
   "salary.updated_at" : "Дата изменения",

    "asset": "Asset",
    "asset.Fields": "Fields",
    "asset.label": "Add New Compnay Asset",
    "asset.name": "Name",
    "asset.inventory-number": "Inventory Number",
    "asset.serial-number": "Serial Number",
    "asset.assignment-date": "Assignment Date",
    "asset.status": "Status",
    "asset.provided-by": "Provided By",
    "asset.serviced-by": "Serviced By",

    "children.name": "Name",
    "children.first-name": "First Name",
    "children.last-name": "Last Name",
    "children.middle-name": "Middle Name",
    "children.birthdate": "Birthdate",
    "children.gender": "Gender",
    "children.age": "Age",

    "careeerpath.category": "Category",
    "careeerpath.update": "Update",
    "careeerpath.Date": "Date",

    "request.start-date" : "Start Date",
    "request.end-date" : "End Date",
    "request.duration" : "Duration",
    "request.type" : "Type",
    "request.status" : "Status",

    "profassesment.date": "Date",
    "profassesment.requester": "Requester",
    "profassesment.status": "Status",

    "certificate.name": "Name",
    "certificate.serialNumber": "Serial Number",
    "certificate.issueDate": "Issue Date",
    "certificate.expirationDate": "Expiration Date",

    "skill.name":"Name",
    "skill.type":"Type",
    "skill.experience":"Experience",

    "document.name" : "Name",
    "document.serialNumber": "Serial Number",
    "document.issueDate": "Issue Date",
    "document.expirationDate": "Expiration Date",

    "salary.period":"Period",
    "salary.rate":"Rate",
    "salary.amount":"Amount",
    "relatedList.Order" : "Заказы",
    "order.id" : "Id",
    "order.Name" : "Имя",
    "order.OrderNumber" : "Номер заказа",
    "order.CreatedById" : "Создано",
    "order.LastModifiedById" : "Изменено",
    "order.BillingAddress" : "Адрес клиента",
    "order.ShippingAddress" : "Адрес доставки",
    "order.Description" : "Описание",
    "order.Amount" : "Количество",
    "order.StartDate" : "Дата создания",
    "order.EndDate" : "Дата исполнения",
    "order.OrderType" : "Тип",
    "order.ReductionOrder" : "Скидка",
    "order.Status" : "Статус",
    "order.LastModifiedDate" : "Дата изменения",
    "order.CreatedDate" : "Дата создания",
    "order.created_at" : "Дата создания",
    "order.updated_at" : "Дата изменения",
    "order.AccountId" : "Организация",
    "order.ContactId" : "Контакт",
    "order.ContractId" : "Контракт",
    "order.EmployeeId" : "Работник",
    "order.Quantity" : "Количество",
    "order.Prepayment" : "Предоплата",
    "order.StoreId" : "Магазин",
    "order.UserId" : "Пользователь",
    "order.PaymentMethod" : "Метод оплаты",
    "order.SalesChannel" : "Канал продажи",
    "order.OrderSource" : "Источник заказа",
    "order.Fields" : "Ордер",
    "order.label" : "Add New Order",
    "order.client-phone-number":"Client Phone Number",
    "order.client":"Client",
    "order.add-new-client":"Add New Client",
    "order.client-type":"Client Type",
    "order.contract-number": "Номер заказа",
    "order.supplier": "Поставщик",

    "order.status.New": "New",
    "order.status.awaiting-processing": "Awaiting processing",
    "order.status.awaiting-payment": "Awaiting payment",
    "order.status.in-the-pipeline": "In the pipeline",
    "order.status.order-at-a-supplier": "Order at a supplier",
    "order.status.validated-by-supplier": "Validated by supplier",
    "order.status.rejected-by-supplier": "Rejected by supplier",
    "order.status.sent-to-central-warehouse": "Sent to central warehouse",
    "order.status.failed-to-reserve-position": "Failed to reserve position",
    "order.status.partial-reservation": "Partial reservation",
    "order.status.ready-for-shipment": "Ready for shipment",
    "order.status.Minimum-delivery-amount": "Minimum delivery amount",
    "order.status.assembled": "Assembled",
    "order.status.on-the-way": "On the way",
    "order.status.delivered": "Delivered",
    "order.status.issued": "Issued",
    "order.status.deleted": "Deleted",
    "order.status.shipped": "Shipped",
    "order.status.error": "Error",
    "order.status.success": "Success",
    "order.status.awaiting-payment": "Awaiting payment",
    "order.status.payment-confirmed": "Payment confirmed",
    "order.status.partially-confirmed": "Partially confirmed",
    "order.status.payment-not-confirmed": "Payment not confirmed",
    "order.status.on-the-move": "On the move",
    "order.status.canceled": "Canceled",
    "order.status.waiting-completion-transaction": "The order is waiting for the completion of the transaction",
    "Details" : "Детали",
    "Dates" : "Даты",
    "opportunity":"Продажа",
    "opportunity.Fields": "Поля",
    "Opportunity" : "Сделка",
    "relatedList.Opportunity" : "Сделки",
    "opportunity.id" : "Сделка",
    "opportunity.Name" : "Имя",
    "opportunity.AccountId" : "Организация",
    "opportunity.ContactId" : "Контакт",
    "opportunity.ContractId" : "Контракт",
    "opportunity.CreatedById" : "Создано",
    "opportunity.LastModifiedById" : "Изменено",
    "opportunity.Description" : "Описаине",
    "opportunity.Amount" : "Цена",
    "opportunity.DiscountPercent" : "Скидка %",
    "opportunity.ExpectedRevenue" : "Ожидаемая прибыль",
    "opportunity.LeadSource" : "Источник клиента",
    "opportunity.Currency" : "Валюта",
    "opportunity.Score" : "Рейтинг",
    "opportunity.OrderNumber" : "Номер заказа",
    "opportunity.Private" : "Приватный",
    "opportunity.Probability" : "Вероятность",
    "opportunity.Quantity" : "Количество",
    "opportunity.Stage" : "Этап",
    "opportunity.Type" : "Тип",
    "opportunity.TrackNumber" : "Номер слежения",
    "opportunity.created_at" : "Дата создания",
    "opportunity.updated_at" : "Дата изменения",

    "relatedList.Opportunityproductpart" : "Opportunity Product Parts",
   "opportunityproductpart" : "Opportunity Product Part",
   "opportunityproductpart.AccountId" : "Организация",
   "opportunityproductpart.Description" : "Описание",
   "opportunityproductpart.Discount" : "Скидка",
   "opportunityproductpart.id" : "Id",
   "opportunityproductpart.isActive" : "Активный",
   "opportunityproductpart.ListPrice" : "List Price",
   "opportunityproductpart.Name" : "Название",
   "opportunityproductpart.OpportunityProductId" : "ПРодукт в продаже",
   "opportunityproductpart.ProductPartId" : "Элемент продукта",
   "opportunityproductpart.Quantity" : "Количество",
   "opportunityproductpart.SalesPrice" : "Sales Price",
   "opportunityproductpart.SubTotal" : "Sub Total",
   "opportunityproductpart.TotalPrice" : "Цена",

    "call.table-label": "Client Activities",
    "call.contact-type": "Client Contact Type",

    "client.label":"Client Information",
    "client.product-label":"Product Information",
    "client.current-activities":"Current Client Activities",

    "product.Fields" : "Продукт",
    "product.editProduct":"Edit Product",
    "product.code":"Код",
    "product.family":"Тип продукта",
    "product.ProductCode" : "Код продукта",
    "product.ProductFamily" : "Тип продукта",
    "product.QuantityUnitOfMeasure" : "Единица измерения",
    "product.DisplayURL" : "URL",
    "product.Likes" : "Количество лайков",
    "product.Rate" : "Рейтинг",
    "product.Price" : "Цена",
    "product.Discount" : "Скидка",
    "product.AvalableForCredit" : "Доступно кредитование",
    "product.Description" : "Полное описание",
    "product.siberianLarch" : "Лиственница сибирская",
    "product.deliveryDays" : "Дата доставки",
    "product.deliveryTime" : "Время доставки",
    "product.productionDays" :"Дней в производстве",
    "product.wallThickness" : "Толщина стен",
    "product.series": "Серия",
    "product.vendorcode":"Код продавца",
    "product.oemnumber":"OEM номер",
    "product.details":"Список запчастей",
    "product.applicable":"Подходит",
    "product.infoanalog":"Информация / Аналоги",
    "product.AccountId" : "Акаунт",
    "product.Active" : "Активирован",
    "product.Article" : "Артикль",
    "product.Count" : "Количество",
    "product.created_at" : "Дата создания",
    "product.CreatedById" : "Создан",
    "product.CreatedDate" : "Дата создания",
    "product.Default" : "Стандартный",
    "product.EstimatedDeliveryInDays" : "Количество дней доставки",
    "product.ExternalDataSource" : "Внешние источник данных",
    "product.ExternalId" : "Внешний идентификатор",
    "product.Height" : "Высота",
    "product.id" : "Продукт",
    "product.IsHot" : "Горячее",
    "product.IsNew" : "Новое",
    "product.IsSale" : "Распродажа",
    "product.LastModifiedById" : "Редактировался",
    "product.LastModifiedDate" : "Дата последнего редактирования",
    "product.Likes" : "Likes",
    "product.Manufacturer" : "Производство",
    "product.Material" : "Материал",
    "product.MaxPrice" : "Максимальная цена",
    "product.Name" : "Имя",
    "product.NormalProductCode" : "Нормализованый код продукта",
    "product.PreviousPrice" : "Предыдущая цена",
    "product.ProductionInDays" : "Дней в производстве",
    "product.Radius" : "Радиус",
    "product.Rate" : "Рейтинг",
    "product.Reviews" : "Отзывы",
    "product.Series" : "Серия",
    "product.ShortDescription" : "Короткое описание",
    "product.SKU" : "SKU",
    "product.Slug" : "Slug",
    "product.Stock" : "На складе",
    "product.SupplierPrice" : "Цена поставщика",
    "product.SupplierProductCode" : "Код продукта от поставщика",
    "product.Thickness" : "Толщина",
    "product.updated_at" : "Дата изменения",
    "product.Width" : "Ширина",
    "product.Length" : "Длина",

    "productscompany.AccountId" : "Организация",
    "productscompany.Article" : "Артикул",
    "productscompany.AvalableForCredit" : "Можнов в кредит",
    "productscompany.Count" : "Количество",
    "productscompany.created_at" : "Дата создания",
    "productscompany.CreatedById" : "Создано ",
    "productscompany.CreatedDate" : "Дата создания",
    "productscompany.Description" : "Описание",
    "productscompany.Discount" : "Скидка",
    "productscompany.DisplayURL" : "URL изобажения",
    "productscompany.EstimatedDeliveryInDays" : "Дней в производстве",
    "productscompany.ExternalDataSource" : "External Data Source",
    "productscompany.ExternalId" : "ExternalId",
    "productscompany.Height" : "Высота",
    "productscompany.id" : "Id",
    "productscompany.isActive" : "Активный",
    "productscompany.LastModifiedById" : "Изменено ",
    "productscompany.LastModifiedDate" : "Дата изменения",
    "productscompany.Length" : "Длина",
    "productscompany.Manufacturer" : "Прозиведено",
    "productscompany.Material" : "Материал",
    "productscompany.MaxPrice" : "Максимальная цена",
    "productscompany.Name" : "Название",
    "productscompany.NormalProductsCompanyCode" : "Нормализованый код продукта",
    "productscompany.PreviousPrice" : "Предыдущая цена",
    "productscompany.Price" : "Цена",
    "productscompany.ProductsCompanyCode" : "Код продукта",
    "productscompany.ProductsCompanyFamily" : "Семейство продукта",
    "productscompany.ProductsCompanyionInDays" : "Products Companyi on In Days",
    "productscompany.QuantityUnitOfMeasure" : "Единица измерения",
    "productscompany.Radius" : "Радиус",
    "productscompany.Series" : "Серия",
    "productscompany.ShortDescription" : "Короткое описание",
    "productscompany.SKU" : "SKU",
    "productscompany.Slug" : "Slug",
    "productscompany.Stock" : "На складе",
    "productscompany.SupplierPrice" : "Цена поставищка",
    "productscompany.SupplierProductsCompanyCode" : "Код продукта поставщика",
    "productscompany.Thickness" : "Толщина",
    "productscompany.updated_at" : "Дата изменения",
    "productscompany.Width" : "Ширина",

    "page.Article" : "Пост",
    "article.Fields" : "Пост",
    "article.AccountId" : "Организация",
    "article.StoreId" : "Сайт\Магазин",
    "article.created_at" : "Дата создания",
    "article.Active" : "Активный",
    "article.Title" : "Название",
    "article.Name" : "Имя",
    "article.ShortDescription" : "Краткое описание",
    "article.LogoURL" : "Логотип URL",
    "article.Tags" : "Тэги",
    "article.Order" : "Очередь",
    "article.id" : "Пост Id",
    "article.Type" : "Тип",

    "marketing.gift" : "Подарочные карты",
    "marketing.discounts" : "Скидки",
    "marketing.vouchers" : "Флаеры",
    "users.Active" : "Активно",
    "users.created_at" :"Дата создания",
"LOAN_6M" : "Рассрочка на 6 месяцев",
    "LOAN_9M" : "Кредит на 9 месяцев",
    "LOAN_12M" : "Кредит на 12 месяцев",
    "LOAN_24M" : "Кредит на 24 месяцев",
    "LOAN_60M" : "Кредит на 60 месяцев",
    "users.whatlanguagesdoyouspeak" : "Какими языками владеете",
    "users.AcademicDegree" : "Ученая степень, ученое звание",
    "users.Department" : "Департамент",
    "users.Division" : "Отделение",
    "users.email" : "Email",
    "users.id" : "Id",
    "users.ProfileId" : "Профиль",
    "users.ScientificWorks": "Какие имеете научные труды или изобретения",
    "users.workHistory" : "Выполняемая работа с начала трудовой деятельности (включая учебу в высших и средних учебных заведениях, военную службу, работу, учебу, стажировку за границей)",
    "users.workNote" : "При заполнении данного пункта учрежндения, организации и предприятия необходимо именовать так, как они назывались в свое время, военную службузаписывать с указанием должности.",
    "users.participationInElections" : "Участие в выборных в законодательные и представительные органы",
    "users.tradeunion" : "участие в профессиональном союзе",
    "users.tradeunionnote" : "Членом какого профсоюза состоите, с какого времени",
    "users.govermentawards":"Какие имеете правительственные награды и почетные звания",
    "users.govermentawardsnote":"Когда и кем награждены, присвоены",
    "users.armyRank":"Отношение к воинской обязанности и военское звание",
    "users.militaryspecialty" : "Военно учетная специальность",
    "users.familyStatus": "Семейное положение",
    "users.familyStatusNote": "Перечислить членов семьи с казанием возраста",
    "users.passportDetails": "Паспортные данные",
    "users.identityPasportNumber":"Идентификационный номер",
    "users.passportSeries": "Серия и номер паспорта",
    "users.passportMinistryOf": "Кем выдан",
    "users.dateOfIssue" : "Дата выдачи",
    "users.passportIdentificationNum": "Идентификационный №",
    "users.fillDate": "Дата заполнения",
    "users.citizenship": "Citizenship",
    "users.accountsettings": "Account Settings",
    "users.settings": "User Settings",
    "users.mycompany": "My Company",
    "users.careerpath": "Career Path",
    "users.Alias" : "Alias",
    "users.Username" : "Username",
    "users.EmailSignature" : "Email Signature",
    "users.SenderName" : "Sender Name",
    "users.SenderAddress" : "Sender Address",
    "users.LocaleSettings" : "Locale Settings",
    "users.contacts" : "Contact Details",
    "users.timeZone" : "Time Zone",
    "users.locale" : "Locale",
    "users.language" : "Language",
    "users.aboutme" : "About Me",
    "users.sex" : "Sex",

    "order.status.New": "Создано",
    "order.status.awaiting-processing": "Ожидает обработки",
    "order.status.awaiting-payment": "Ожидает предоплаты",
    "order.status.in-the-pipeline": "В работе",
    "order.status.order-at-a-supplier": "Заказ у поставщика",
    "order.status.validated-by-supplier": "Подтвержден поставщиком",
    "order.status.rejected-by-supplier": "Отказ поставщика",
    "order.status.sent-to-central-warehouse": "Отправлено на центральный склад",
    "order.status.failed-to-reserve-position": "Не удалось зарезервировать позицию",
    "order.status.partial-reservation": "Частичное резервирование",
    "order.status.ready-for-shipment": "Готов к отгрузке",
    "order.status.Minimum-delivery-amount": "Минимальная сумма доставки",
    "order.status.assembled": "Собран",
    "order.status.on-the-way": "В пути",
    "order.status.delivered": "Доставлено",
    "order.status.issued": "Выдан",
    "order.status.deleted": "Удален",
    "order.status.shipped": "Доставлен",
    "order.status.error": "Ошибка",
    "order.status.awaiting-payment": "Ожидает оплаты (Эквайринг)",
    "order.status.payment-confirmed": "Оплата подтверждена",
    "order.status.partially-confirmed": "Частично подтвержден поставщиком",
    "order.status.payment-not-confirmed": "Оплата не подтверждена (Эквайринг)",
    "order.status.on-the-move": "В пути",
    "order.status.canceled": "Отменен",
    "order.status.waiting-completion-transaction": "Заказ ожидает завершения транзакции",

    "relatedList.Asset" : "Активы",
    "relatedList.Cartitem" : "Товары карзины",
    "cartitem.AccountId" : "Аккаунт",
    "cartitem.Active" : "Активирован",
    "cartitem.CartId" : "Карзина",
    "cartitem.created_at" : "Дата создания",
    "cartitem.Discount" : "Скидка",
    "cartitem.id" : "Товар карзины",
    "cartitem.Image" : "Изображение",
    "cartitem.Name" : "Имя",
    "cartitem.OrderId" : "Заказ",
    "cartitem.Price" : "Цена",
    "cartitem.ProductId" : "Продукт",
    "cartitem.Quantity" : "Количество",
    "cartitem.Total" : "Итого",
    "cartitem.updated_at" : "Дата изменения",
    "cartitem.UserCarId" : "Автомобиль ползователя",

    "relatedList.Productcategoryproduct" : "Категории продуктов",
    "productcategoryproduct.AccountId" : "Аккаунт",
    "productcategoryproduct.id" : "Категория товара",
    "productcategoryproduct.isPrimaryCategory" : "Основная категория?",
    "productcategoryproduct.Name" : "Имя",
    "productcategoryproduct.ProductId" : "Продукт/товар",


    "relatedList.Orderproduct" : "Заказаные продукты",
    "orderproduct.AccountId" : "Аккаунт",
    "orderproduct.AvailableQuantity" : "Доступное количество",
    "orderproduct.created_at" : "Дата создания",
    "orderproduct.CreatedById" : "Создано",
    "orderproduct.CreatedDate" : "Дата создания",
    "orderproduct.EndDate" : "Дата исполнения",
    "orderproduct.id" : "Заказ",
    "orderproduct.LastModifiedById" : "Изменено",
    "orderproduct.LastModifiedDate" : "Дата изменения",
    "orderproduct.OrderId" : "Заказ",
    "orderproduct.Price" : "Цена",
    "orderproduct.ProductCode" : "Код продукта",
    "orderproduct.ProductId" : "Продукт",
    "orderproduct.Quantity" : "Количество",
    "orderproduct.UnitPrice" : "Цена за единицу",
    "orderproduct.updated_at" : "Дата изменения",

    "relatedList.Productcharacteristics" : "Характеристики продукта",
    "productcharacteristics.AccountId" : "Аккаунт",
    "productcharacteristics.created_at" : "Дата создания",
    "productcharacteristics.Description" : "Описание",
    "productcharacteristics.id" : "Характеристика продукта",
    "productcharacteristics.isActive" : "Активный",
    "productcharacteristics.Name" : "Название",
    "productcharacteristics.ProductChildName" : "Название продукта",
    "productcharacteristics.ProductId" : "Продукт",
    "productcharacteristics.updated_at" : "Дата создания",

    "relatedList.Productitem" : "Элемент продукта",
    "productitem.AccountId" : "Аккаунт",
    "productitem.ChildProductId" : "Дочерний Продукт",
    "productitem.Count" : "Количество",
    "productitem.created_at" : "Дата создания",
    "productitem.Default" : "По умолчанию",
    "productitem.id" : "Элемент продукта",
    "productitem.isActive" : "Активирован",
    "productitem.ParentProductId" : "Родительский продукт",
    "productitem.Price" : "Цена",
    "productitem.updated_at" : "Дата создания",

    "relatedList.Productlike" : "Лайки",
    "productlikes.id" : "Like",
    "productlikes.Product" : "Продукт",
    "productlikes.User" : "Пользователь",

    "sidebar.handbook": "Справочник",
    "sidebar.cities": "Города",
    "sidebar.currencies": "Валюты",
    "sidebar.carsbrands": "Список авто брэндов",
    "sidebar.parts-brands": "Брэнды запчастей",
    "sidebar.exceptions-to-the-brands": "Исключения для Брэндов ",
    "sidebar.us_company-label" : "Организация",
    "sidebar.us_company-info" : "Информация об организации",
    "sidebar.us_company-employees" : "Сотрудники",
    "sidebar.us_company-dashboard" : "Статистика",
    "sidebar.us_company-assets" : "Основные средства",
    "sidebar.us_company-sales-label": "Продажи",
    "sidebar.us_clients-management": "Управление клиентами",
    "sidebar.company_products": "Продукты компании",
    "sidebar.complects": "Комплектующие",
    "sidebar.all_products": "Все продукты",
    "sidebar.us_stores" : "Магазины",
    "sidebar.us_orders": "Заказы",
    "sidebar.us_clients" : "Клиенты",
    "sidebar.us_suppliers": "Поставщики",
    "sidebar.us_consumers": "Заказчики",
    "sidebar.us_products": "Продукты",
    "sidebar.us_company-activity-label": "Активности",
    "sidebar.us_company-requests" : "Запросы",
    "sidebar.us_company-events" : "События",
    "sidebar.us_tasks": "Задания",
    "sidebar.us_company-vacation" : "Отпуск",
    "sidebar.us_company-career-label" : "Карьера",
    "sidebar.us_learning": "Обучение",
    "sidebar.us_achievements": "Достижения",
    "sidebar.us_progress": "Програесс",
    "sidebar.us_company-discounts" : "Скидка",
    "sidebar.us_company-desktop" : "Рабочий стол",
    "sidebar.us_company-hr" : "Отдел кадров",
    "sidebar.us_company-vacancies" : "Наши вакансии",
    "sidebar.us_company-positions" : "Наши позиции",
    "sidebar.us_company-accounting" : "Бухгалтерия",
    "sidebar.us_company-accounting-calculate_salary" : "Расчет зарплат",
    "sidebar.us_company-accounting-calculate_main" : "Доходы\Расходы",
    "sidebar.us_company-accounting-reports" : "Отчеты",

   "sidebar.app": "Приложение",
   "sidebar.horizontal": "горизонтальный",
   "sidebar.horizontalMenu": "горизонтальное меню",
   "sidebar.general": "Генеральная",
   "sidebar.component": "Компонент",
   "sidebar.features": "Особенности",
   "sidebar.applications": "Приложения",
   "sidebar.dashboard": "Панель приборов",
   "sidebar.dashboard1": "Панель приборов 1",
   "sidebar.modules": "Модули",
   "sidebar.agency": "Агентство",
   "sidebar.pages": "страницы",
   "sidebar.gallery": "Галерея",
   "sidebar.pricing": "ценообразование",
   "sidebar.terms&Conditions": "Условия Условия",
   "sidebar.feedback": "Обратная связь",
   "sidebar.report": "отчет",
   "sidebar.faq(s)": "задаваемые вопросы (ы)",
   "sidebar.advancedComponent": "Расширенный компонент",
   "sidebar.blank": "пустой",
   "sidebar.session": "сессия",
   "sidebar.login": "Авторизоваться",
   "sidebar.register": "регистр",
   "sidebar.lockScreen": "Экран блокировки",
   "sidebar.forgotPassword": "Забыли пароль",
   "sidebar.404": "404",
   "sidebar.500": "500",
   "sidebar.uiComponents": "Компоненты пользовательского интерфейса",
   "sidebar.alerts": "Оповещения",
   "sidebar.appBar": "Панель приложений",
   "sidebar.avatars": "Аватары",
   "sidebar.buttons": "Кнопки",
   "sidebar.bottomNavigations": "Нижние навигационные",
   "sidebar.badges": "Значки",
   "sidebar.cards": "Карты",
   "sidebar.cardsMasonry": "Карточки Masonry",
   "sidebar.chip": "чип",
   "sidebar.dialog": "диалог",
   "sidebar.dividers": "циркуль",
   "sidebar.drawers": "подштанники",
   "sidebar.popover": "Трещать",
   "sidebar.expansionPanel": "Панель расширения",
   "sidebar.gridList": "Сетка Список",
   "sidebar.list": "Список",
   "sidebar.menu": "Меню",
   "sidebar.popoverAndToolTip": "поп Нада также Инструмент наконечник",
   "sidebar.progress": "Прогресс",
   "sidebar.snackbar": "Закусочная",
   "sidebar.selectionControls": "Контроль выбора",
   "sidebar.advanceUiComponents": "Дополнительные компоненты пользовательского интерфейса",
   "sidebar.dateAndTimePicker": "Выбор даты и времени",
   "sidebar.tabs": "Вкладки",
   "sidebar.stepper": "Шаговый",
   "sidebar.notification": "уведомление",
   "sidebar.sweetAlert": "Сладкое предупреждение",
   "sidebar.autoComplete": "Автозаполнение",
   "sidebar.aboutUs": "О нас",
   "sidebar.instruction" : "Инструкции",
   "sidebar.widgets": "Виджеты",
   "sidebar.inbox": "входящие",
   "sidebar.forms": "формы",
   "sidebar.formElements": "Элементы формы",
   "sidebar.textField": "Текстовое поле",
   "sidebar.selectList": "Выбрать список",
   "sidebar.charts": "Графики",
   "sidebar.reCharts": "диаграммы",
   "sidebar.reactChartjs2": "Реакция Chartjs 2",
   "sidebar.icons": "Иконки",
   "sidebar.themifyIcons": "Имитировать значки",
   "sidebar.simpleLineIcons": "Иконки простой линии",
   "sidebar.materialIcons": "Иконки материалов",
   "sidebar.fontAwesome": "Шрифт Awesome",
   "sidebar.maps": "Карты",
   "sidebar.googleMaps": "Карты Гугл",
   "sidebar.leafletMaps": "Листовые карты",
   "sidebar.tables": "таблицы",
   "sidebar.basic": "основной",
   "sidebar.dataTable": "Таблица данных",
   "sidebar.reactTable": "Таблица реактивов",
   "sidebar.responsive": "отзывчивый",
   "sidebar.userList": "Список пользователей",
   "sidebar.users": "пользователей",
   "sidebar.userProfile1": "Профиль пользователя 1",
   "sidebar.userProfile2": "Профиль пользователя 2",
   "sidebar.userManagement": "Управление пользователями",
   "sidebar.userProfile": "Профиль пользователя",
   "sidebar.calendar": "Календарь",
   "sidebar.cultures": "Культуры",
   "sidebar.dnd": "просьба не беспокоить",
   "sidebar.selectable": "Выбор",
   "sidebar.customRendering": "Пользовательский рендеринг",
   "sidebar.chat": "чат",
   "sidebar.toDo": "Делать",
   "sidebar.editor": "редактор",
   "sidebar.wysiwygEditor": "Редактор WYSIWYG",
   "sidebar.quillEditor": "Редактор Quill",
   "sidebar.reactAce": "Реагировать Ace",
   "sidebar.dragAndDrop": "Перетаскивание",
   "sidebar.reactDragula": "Реагировать на Драгулу",
   "sidebar.reactDnd": "Реагировать на Dnd",
   "sidebar.blogManagement": "Управление блогами",
   "sidebar.ecommerce": "Электронная торговля",
   "sidebar.shopList": "Список магазинов",
   "sidebar.shopGrid": "Сеть магазинов",
   "sidebar.invoice": "Выставленный счет",
   "sidebar.multilevel": "многоуровневый",
   "sidebar.sublevel": "Подуровень",
   "widgets.totalEarns": "Всего заработает",
   "widgets.emailsStatistics": "Статистика электронной почты",
   "widgets.totalRevenue": "Общий доход",
   "widgets.onlineVistors": "Интернет-висторы",
   "widgets.trafficSources": "Источники трафика",
   "widgets.RecentOrders": "Недавние заказы",
   "widgets.topSellings": "Лучшие продажи",
   "widgets.productReports": "Отчеты о продукции",
   "widgets.productStats": "Статистика продукта",
   "widgets.ComposeEmail": "Написать письмо",
   "widgets.employeePayroll": "Заработная плата сотрудников",
   "widgets.visitors": "Посетители",
   "widgets.orders": "заказы",
   "widgets.orderStatus": "Статус заказа",
   "widgets.totalSales": "Тотальная распродажа",
   "widgets.netProfit": "Чистая прибыль",
   "widgets.overallTrafficStatus": "Общее состояние трафика",
   "widgets.tax": "налог",
   "widgets.expenses": "РАСХОДЫ",
   "widgets.currentTime": "Текущее время",
   "widgets.currentDate": "Текущая дата",
   "widgets.todayOrders": "Сегодня заказы",
   "widgets.toDoList": "Сделать списки",
   "widgets.discoverPeople": "Откройте для себя людей",
   "widgets.commments": "Комментарии",
   "widgets.newCustomers": "новые клиенты",
   "widgets.recentNotifications": "Последние уведомления",
   "widgets.appNotifications": "Уведомления приложений",
   "widgets.newEmails": "Новые электронные письма",
   "widgets.siteVisitors": "Посетители сайта",
   "widgets.recentActivities": "Последние действия",
   "widgets.recentOrders": "Недавние заказы",
   "widgets.gallery": "Галерея",
   "widgets.pricing": "ценообразование",
   "widgets.enterpriseEdition": "Enterprise Edition",
   "widgets.personalEdition": "Персональная версия",
   "widgets.teamEdition": "Командный выпуск",
   "widgets.ratings": "Рейтинги",
   "widgets.socialCompanines": "Социальные компании",
   "widgets.standard": "стандарт",
   "widgets.advanced": "продвинутый",
   "widgets.master": "Мастер",
   "widgets.Mega": "Мега",
   "widgets.logIn": "Авторизоваться",
   "widgets.signUp": "Зарегистрироваться",
   "widgets.lockScreen": "Экран блокировки",
   "widgets.alertsWithLink": "Оповещения с ссылкой",
   "widgets.additionalContent": "Дополнительный контент",
   "widgets.alertDismiss": "Оповещение об увольнении",
   "widgets.uncontrolledDisableAlerts": "Неконтролируемые отключенные оповещения",
   "widgets.contexualAlerts": "Контекстные оповещения",
   "widgets.alertsWithIcons": "Оповещения с иконками",
   "widgets.Simple App Bars": "Sобновить App Bars",
   "widgets.appBarsWithButtons": "Панель приложений с кнопками",
   "widgets.imageAvatars": "Iмага Аватары",
   "widgets.lettersAvatars": "Письма Аватары",
   "widgets.iconsAvatars": "Иконки Аватары",
   "widgets.flatButtons": "Плоские кнопки",
   "widgets.raisedButton": "Поднятая кнопка",
   "widgets.buttonWithIconAndLabel": "Кнопка с иконкой и ярлыком",
   "widgets.floatingActionButtons": "Плавающие кнопки действий",
   "widgets.iconButton": "Кнопка значка",
   "widgets.socialMediaButton": "Кнопка Социальные медиа",
   "widgets.reactButton": "Кнопка ответа",
   "widgets.buttonOutline": "Контур кнопки",
   "widgets.buttonSize": "Размер кнопки",
   "widgets.buttonState": "Состояние кнопки",
   "widgets.buttonNavigationWithNoLabel": "Кнопка Навигация без метки",
   "widgets.buttonNavigation": "Навигация по кнопке",
   "widgets.iconNavigation": "Навигация по иконкам",
   "widgets.badgeWithHeadings": "BПрикрепленное изображение с заголовками",
   "widgets.contexualVariations": "Контекстные вариации",
   "widgets.badgeLinks": "Ссылки на значки",
   "widgets.materialBadge": "Значок материала",
   "widgets.simpleCards": "Простые карты",
   "widgets.backgroundVarient": "Справочная информация",
   "widgets.cardOutline": "План карты",
   "widgets.overlayCard": "Карта наложения",
   "widgets.cardGroup": "Группа карт",
   "widgets.cardTitle": "Название карты",
   "widgets.speacialTitleTreatment": "Юридическая информация",
   "widgets.chipWithClickEvent": "Чип с кликом",
   "widgets.chipArray": "Чип-массив",
   "widgets.dialogs": "Диалоги",
   "widgets.listDividers": "Список разделителей",
   "widgets.insetDividers": "Вставляемые разделители",
   "widgets.temporaryDrawers": "Временные ящики",
   "widgets.permanentDrawers": "Постоянные ящики",
   "widgets.simpleExpansionPanel": "Простая панель расширения",
   "widgets.controlledAccordion": "Контролируемый аккордеон",
   "widgets.secondaryHeadingAndColumns": "Вторичные заголовки и столбцы",
   "widgets.imageOnlyGridLists": "Только списки гридов",
   "widgets.advancedGridLists": "Расширенные списки гридов",
   "widgets.singleLineGridLists": "Однолинейные списки сетки",
   "widgets.simpleLists": "Простые списки",
   "widgets.folderLists": "Списки папок",
   "widgets.listItemWithImage": "Список элементов с изображением",
   "widgets.switchLists": "Списки переключателей",
   "widgets.insetLists": "Списки вставки",
   "widgets.nestedLists": "Вложенные списки",
   "widgets.checkboxListControl": "Контроль списка флажков",
   "widgets.pinedSubHeader": "Выровненный подзаголовок",
   "widgets.InteractiveLists": "Интерактивные списки",
   "widgets.simpleMenus": "Простые меню",
   "widgets.selectedMenu": "Выбранное меню",
   "widgets.maxHeightMenu": "Меню максимальной высоты",
   "widgets.changeTransition": "Изменить переход",
   "widgets.paper": "Бумага",
   "widgets.anchorPlayGround": "Якорная игровая площадка",
   "widgets.tooltip": "ToolTip",
   "widgets.positionedToolTips": "Расположенная закусочная",
   "widgets.circularProgressBottomStart": "Круговой ход",
   "widgets.interactiveIntegration": "Интерактивная интеграция",
   "widgets.determinate": "детерминированный",
   "widgets.linearProgressLineBar": "Линейная линия линейки хода",
   "widgets.indeterminate": "неопределенный",
   "widgets.buffer": "буфер",
   "widgets.query": "запрос",
   "widgets.transitionControlDirection": "Направление управления переключением",
   "widgets.simpleSnackbar": "Простая закусочная",
   "widgets.positionedSnackbar": "Расположенная закусочная",
   "widgets.contexualColoredSnackbars": "Контекстные цветные закусочные",
   "widgets.simpleCheckbox": "Простой флажок",
   "widgets.interminateSelection": "Межминистерский отбор",
   "widgets.disabledCheckbox": "Отключен флажок",
   "widgets.customColorCheckbox": "Пользовательский флажок цвета",
   "widgets.VerticalStyleCheckbox": "Флажок вертикального стиля",
   "widgets.horizontalStyleCheckbox": "Флажок горизонтального стиля",
   "widgets.radioButtons": "Радио-кнопки",
   "widgets.disabledRadio": "Отключенное радио",
   "widgets.withError": "С ошибкой",
   "widgets.switches": "Swiches",
   "widgets.dateAndTimePicker": "Выбор даты и времени",
   "widgets.defaultPicker": "Выбор по умолчанию",
   "widgets.timePicker": "Выбор времени",
   "widgets.weekPicker": "Выбор недели",
   "widgets.defaultDatePicker": "Выбор даты по умолчанию",
   "widgets.customPicker": "Пользовательский подборщик",
   "widgets.tabs": "Вкладки",
   "widgets.fixedTabs": "Фиксированные вкладки",
   "widgets.basicTab": "Основная вкладка",
   "widgets.centeredLabels": "Центрированные ярлыки",
   "widgets.forcedScrolledButtons": "Принудительные кнопки прокрутки",
   "widgets.iconsTabs": "Вкладки вкладок",
   "widgets.withDisableTabs": "С отключенными вкладками",
   "widgets.iconWithLabel": "Иконка с меткой",
   "widgets.stepper": "Шаговый",
   "widgets.horizontalLinear": "Горизонтальная линейная",
   "widgets.horizontalNonLinear": "Горизонтальный нелинейный",
   "widgets.horizontalLinerAlternativeLabel": "Альтернативная метка горизонтального вкладыша",
   "widgets.horizontalNonLinerAlternativeLabel": "Горизонтальная альтернативная этикетка без вкладыша",
   "widgets.verticalStepper": "Вертикальный степпер",
   "widgets.descriptionAlert": "Описание Alert",
   "widgets.customIconAlert": "Пользовательский значок оповещения",
   "widgets.withHtmlAlert": "С Html бдительный",
   "widgets.promptAlert": "Быстрое оповещение",
   "widgets.passwordPromptAlert": "Предупреждение о запросе пароля",
   "widgets.customStyleAlert": "Пользовательский стиль оповещения",
   "widgets.autoComplete": "Автозаполнение",
   "widgets.reactSelect": "Выбор реакции",
   "widgets.downshiftAutoComplete": "Автоматический переход на пониженную передачу",
   "widgets.reactAutoSuggests": "Реагировать на авто",
   "widgets.aboutUs": "О нас",
   "widgets.ourVission": "Наши деления",
   "widgets.ourMissions": "Наши миссии",
   "widgets.ourMotivation": "Наша мотивация",
   "widgets.defualtReactForm": "Defualt React Form",
   "widgets.url": "Веб-сайт",
   "widgets.textArea": "Текстовая область",
   "widgets.file": "файл",
   "widgets.formGrid": "Сетка формы",
   "widgets.inlineForm": "Встроенная форма",
   "widgets.inputSizing": "Размер входного сигнала",
   "widgets.inputGridSizing": "Ширина сетки ввода",
   "widgets.hiddenLabels": "Скрытые ярлыки",
   "widgets.formValidation": "Проверка формы",
   "widgets.number": "Число",
   "widgets.date": "Дата",
   "widgets.time": "Время",
   "widgets.color": "цвет",
   "widgets.search": "Поиск",
   "widgets.selectMultiple": "Выберите несколько",
   "widgets.inputWithSuccess": "Вход с успехом",
   "widgets.inputWithDanger": "Вход с опасностью",
   "widgets.simpleTextField": "Простое текстовое поле",
   "widgets.componet": "Компоненты",
   "widgets.layouts": "Макеты",
   "widgets.inputAdorements": "Входные адепты",
   "widgets.formattedInputs": "Форматированные входы",
   "widgets.simpleSelect": "Простой выбор",
   "widgets.nativeSelect": "Нативный выбор",
   "widgets.MutltiSelectList": "Выбрать список Mutlti",
   "widgets.lineChart": "Линейный график",
   "widgets.barChart": "Барная диаграмма",
   "widgets.stackedBarChart": "Сложенная гистограмма",
   "widgets.lineBarAreaChart": "Диаграмма площади линии",
   "widgets.areaChart": "Диаграмма района",
   "widgets.stackedAreaChart": "Сложенная область",
   "widgets.verticalChart": "Вертикальная диаграмма",
   "widgets.radarChart": "Радарная диаграмма",
   "widgets.doughnut": "Пончик",
   "widgets.polarChart": "Полярная диаграмма",
   "widgets.pieChart": "Круговая диаграмма",
   "widgets.bubbleChart": "Диаграмма пузырьков",
   "widgets.horizontalBar": "Бар Horizonatl",
   "widgets.basicTable": "Основная таблица",
   "widgets.contexualColoredTable": "Контекстная цветная таблица",
   "widgets.dataTable": "Таблица данных",
   "widgets.employeeList": "Список сотрудников",
   "widgets.responsiveTable": "Отзывчивая таблица",
   "widgets.responsiveFlipTable": "Отзывчивый откидной стол",
   "widgets.reactGridControlledStateMode": "Реагировать режим контролируемого состояния сетки",
   "widgets.googleMaps": "Карты Гугл",
   "widgets.productsReports": "Отчеты о продукции",
   "widgets.taskList": "Список заданий",
   "widgets.basicCalender": "Основной календарь",
   "widgets.culturesCalender": "Календари культур",
   "widgets.dragAndDropCalender": "Перетаскивание и удаление календаря",
   "widgets.selectableCalender": "Выбираемый календарь",
   "widgets.customRendering": "Пользовательский рендеринг",
   "widgets.customCalender": "Пользовательский календарь",
   "widgets.searchMailList": "Поиск в списке рассылки",
   "components.buyNow": "купить сейчас",
   "compenets.choose": "выберите",
   "compenets.username": "имя пользователя",
   "compenets.passwords": "Пароли",
   "widgets.forgetPassword": "Забыть пароль",
   "compenets.signIn": "Войти в систему",
   "compenets.dontHaveAccountSignUp": "У вас нет учетной записи Зарегистрироваться",
   "compenets.enterUserName": "Введите имя пользователя",
   "compenets.enterEmailAddress": "Введите адрес электронной почты",
   "compenets.confirmPasswords": "Подтвердить пароли",
   "components.alreadyHavingAccountSignIn": "У вас уже есть учетная запись",
   "components.enterYourPassword": "Введите ваш пароль",
   "components.unlock": "отпереть",
   "components.enterPasswords": "Введите пароли",
   "components.resestPassword": "Пароль Resest",
   "components.pageNotfound": "Страница не найдена",
   "components.goToHomePage": "Вернуться на домашнюю страницу",
   "components.sorryServerGoesWrong": "К сожалению, сервер не работает",
   "components.persistentDrawer": "Постоянный ящик",
   "components.back": "назад",
   "components.next": "следующий",
   "components.completeStep": "Полный шаг",
   "components.withHtml": "С Html",
   "components.prompt": "Незамедлительный",
   "components.withDescription": "С описанием",
   "components.success": "успех",
   "components.passwordPrompt": "Запрос пароля",
   "components.warning": "Предупреждение",
   "components.customIcon": "Пользовательский значок",
   "components.customStyle": "Пользовательский стиль",
   "components.basic": "основной",
   "components.submit": "Отправить",
   "components.sendMessage": "Отправить сообщение",
   "components.addNewTasks": "Добавить новые задачи",
   "components.addToCart": "Добавить в корзину",
   "components.payNow": "Заплатить сейчас",
   "components.print": "Распечатать",
   "components.cart": "телега",
   "components.viewCart": "Просмотр корзины",
   "components.checkout": "Проверять, выписываться",
   "widgets.QuickLinks": "Быстрые ссылки",
   "widgets.upgrade": "Обновить",
   "widgets.app": "Приложение",
   "widgets.addNew": "Добавить новое",
   "widgets.orderDate": "Дата заказа",
   "widgets.status": "Положение дел",
   "widgets.trackingNumber": "Номер Отслеживания",
   "widgets.action": "действие",
   "widgets.designation": "обозначение",
   "widgets.subject": "предмет",
   "widgets.send": "послать",
   "widgets.saveAsDrafts": "Сохранить как черновики",
   "widgets.onlineSources": "Интернет-источники",
   "widgets.lastMonth": "Прошлый месяц",
   "widgets.widgets": "Виджеты",
   "widgets.listing": "список",
   "widgets.paid": "оплаченный",
   "widgets.refunded": "Возвращено",
   "widgets.done": "Готово",
   "widgets.pending": "в ожидании",
   "widgets.cancelled": "отменен",
   "widgets.approve": "Одобрить",
   "widgets.following": "Следующий",
   "widgets.follow": "следовать",
   "widgets.graphs&Charts": "Графики и диаграммы",
   "widgets.open": "открыто",
   "widgets.bounced": "Возвращенные",
   "widgets.spam": "Спам",
   "widgets.unset": "Отозвать",
   "widgets.bandwidthUse": "Использование полосы пропускания",
   "widgets.dataUse": "Data использование",
   "widgets.unsubscribe": "Отказаться от подписки",
   "widgets.profile": "Профиль",
   "widgets.messages": "Сообщения",
   "widgets.support": "Поддержка",
   "widgets.faq(s)": "Вопрос-ответ (ы)",
   "widgets.upgradePlains": "Обновление равнин",
   "widgets.logOut": "Выйти",
   "widgets.mail": "почта",
   "widgets.adminTheme": "Тема администратора",
   "widgets.wordpressTheme": "Тема Wordpress",
   "widgets.addToCart": "Добавить в корзину",
   "widgets.plan": "План",
   "widgets.basic": "основной",
   "widgets.pro": "профессионал",
   "widgets.startToBasic": "Начать к основным",
   "widgets.upgradeToPro": "Обновление до Pro",
   "widgets.upgradeToAdvance": "Обновление до продвинутого",
   "widgets.comparePlans": "Сравнить планы",
   "widgets.free": "Свободно",
   "widgets.frequentlyAskedQuestions": "Часто задаваемые вопросы",
   "widgets.searchIdeas": "Поиск идей",
   "widgets.startDate": "Дата начала",
   "widgets.endDate": "Дата окончания",
   "widgets.category": "категория",
   "widgets.apply": "Подать заявление",
   "widgets.downloadPdfReport": "Загрузить отчет Pdf",
   "widgets.yesterday": "Вчера",
   "widgets.totalOrders": "Всего заказов",
   "widgets.totalVisitors": "Всего посетителей",
   "widgets.typeYourQuestions": "Введите ваши вопросы",
   "widgets.username": "имя пользователя",
   "widgets.password": "пароль",
   "widgets.signIn": "Войти в систему",
   "widgets.enterYourPassword": "Введите ваш пароль",
   "widgets.alreadyHavingAccountLogin": "Уже есть учетная запись",
   "widgets.composeMail": "Написать письмо",
   "widgets.issue": "вопрос",
   "widgets.recentChat": "Последние сообщения",
   "widgets.previousChat": "Предыдущий чат",
   "widgets.all": "Все",
   "widgets.filters": "фильтры",
   "widgets.deleted": "удаленный",
   "widgets.starred": "Помеченные",
   "widgets.frontend": "Внешний интерфейс",
   "widgets.backend": "Backend",
   "widgets.api": "Api",
   "widgets.simpleAppBar": "Простая панель приложений",
   "widgets.recents": "Недавние",
   "widgets.cardLink": "Ссылка на карту",
   "widgets.anotherLink": "Еще одна ссылка",
   "widgets.cardSubtitle": "подзаголовок карты",
   "widgets.confirmationDialogs": "Диалоги подтверждения",
   "widgets.deletableChip": "Удаляемый чип",
   "widgets.customDeleteIconChip": "Пользовательское удаление значка",
   "widgets.openAlertDialog": "Открыть диалоговое окно оповещений",
   "widgets.openResponsiveDialog": "Открыть ответный диалог",
   "widgets.openSimpleDialog": "Открыть простой диалог",
   "widgets.openFormDialog": "Диалог открытой формы",
   "widgets.follower": "толкатель",
   "widgets.important": "Важный",
   "widgets.private": "Частный",
   "widgets.openLeft": "Открыть влево",
   "widgets.openRight": "Открыть право",
   "widgets.openTop": "С открытым верхом",
   "widgets.openBottom": "Открытое дно",
   "widgets.selectTripDestination": "Выберите пункт назначения поездки",
   "widgets.pinnedSubheaderList": "Список закрепленных подзаголовков",
   "widgets.singleLineItem": "Одиночная позиция",
   "widgets.acceptTerms": "Принять условия",
   "widgets.optionA": "Вариант A",
   "widgets.optionB": "Вариант B",
   "widgets.optionC": "Вариант C",
   "widgets.optionM": "Вариант M",
   "widgets.optionN": "Вариант N",
   "widgets.optionO": "Вариант O",
   "widgets.customColor": "Пользовательский цвет",
   "widgets.centeredTabs": "Центрированные вкладки",
   "widgets.multipleTabs": "Несколько вкладок",
   "widgets.preventScrolledButtons": "Запретить прокручиваемые кнопки",
   "widgets.browse": "Просматривать",
   "widgets.formValidate": "Форма подтверждения",
   "widgets.code": "Код",
   "widgets.company": "Компания",
   "widgets.price": "Цена",
   "widgets.change": "изменение",
   "widgets.high": "Высокая",
   "widgets.low": "Низкий",
   "widgets.volume": "объем",
   "widgets.personalDetails": "Личная информация",
   "widgets.occupation": "оккупация",
   "widgets.companyName": "название компании",
   "widgets.phoneNo": "Номер телефона",
   "widgets.city": "город",
   "widgets.zipCode": "Почтовый Индекс",
   "widgets.updateProfile": "Обновить профиль",
   "widgets.reject": "отклонять",
   "widgets.exportToExcel": "Экспорт в превосходить",
   "widgets.addNewUser": "Добавить пользователя",
   "widgets.workWeek": "Рабочая неделя",
   "widgets.agenda": "Повестка дня",
   "widgets.conference": "Конференция",
   "widgets.multilevel": "многоуровневый",
   "widgets.dailySales": "Ежедневные продажи",
   "widgets.today": "Cегодня",
   "widgets.campaignPerformance": "Эффективность кампании",
   "widgets.supportRequest": "Запрос поддержки",
   "widgets.usersList": "Список пользователей",
   "widgets.lastWeek": "На прошлой неделе",
   "themeOptions.sidebarOverlay": "Наложение боковой панели",
   "themeOptions.sidebarBackgroundImages": "Фоновые изображения боковой ",
   "themeOptions.miniSidebar": "Мини-боковая панель",
   "themeOptions.boxLayout": "Макет коробки",
   "themeOptions.rtlLayout": "Rtl расположение",
   "themeOptions.darkMode": "Темный режим",
   "themeOptions.sidebarImage": "Изображение боковой панели",
   "themeOptions.appSettings": "Настройки приложения",
   "themeOptions.sidebarLight": "Легкий",
   "themeOptions.sidebarDark": "Темно",
   "button.cancel": "Отмена",
   "button.add": "Добавить",
   "button.update": "Обновить",
   "button.reply": "Ответить",
   "button.delete": "Удалить",
   "button.yes": "да",
   "button.viewAll": "Посмотреть все",
   "button.like": "подобно",
   "button.assignNow": "Назначить сейчас",
   "button.seeInsights": "См. Insights",
   "sidebar.dateTimePicker": "Выбор даты и времени",
   "components.summary": "Резюме",
   "hint.whatAreYouLookingFor": "Что ты ищешь",
   "components.yesterday": "Вчера",
   "components.last7Days": "Последние 7 дней",
   "components.last1Month": "Последние 1 месяц",
   "components.last6Month": "Последние 6 месяцев",
   "components.spaceUsed": "Используемое пространство",
   "components.followers": "толкатель",
   "components.trending": "простирания",
   "components.paid": "оплаченный",
   "components.refunded": "Возвращено",
   "components.done": "Готово",
   "components.pending": "в ожидании",
   "components.cancelled": "отменен",
   "components.approve": "Одобрить",
   "components.week": "Неделю",
   "components.month": "Месяц",
   "components.year": "Год",
   "components.today": "Cегодня",
   "components.popularity": "популярность",
   "components.email": "Эл. адрес",
   "components.drafts": "Черновики",
   "components.sent": "Отправлено",
   "components.trash": "дрянь",
   "components.all": "Все",
   "components.do": "Делать",
   "components.title": "заглавие",
   "components.projectName": "название проекта",
   "components.companyName": "название компании",
   "components.openAlert": "Открыть оповещение",
   "components.slideInAlertDialog": "Слайд в диалоговом окне оповещений",
   "components.openFullScreenDialog": "Открыть полноэкранные диалоги",
   "components.basicChip": "Основной чип",
   "components.clickableChip": "Кликаемый чип",
   "components.left": "Оставил",
   "components.right": "Правильно",
   "components.expansionPanel1": "Панель расширения 1",
   "components.expansionPanel2": "Панель расширения 2",
   "components.generalSetting": "Общая настройка",
   "components.advancedSettings": "Расширенные настройки",
   "components.firstName": "Имя",
   "components.lastName": "Фамилия",
   "components.occupation": "оккупация",
   "components.phoneNo": "Номер телефона",
   "components.address": "Адрес",
   "components.city": "город",
   "components.state": "состояние",
   "components.zipCode": "Почтовый Индекс",
   "components.social Connection": "Социальная связь",
   "widgets.buyMore": "Купить больше",
   "widgets.trafficChannel": "Канал трафика",
   "widgets.stockExchange": "Фондовая биржа",
   "widgets.tweets": "твиты",
   "widgets.ourLocations": "Наши местоположения",
   "widgets.sales": "Продажи",
   "widgets.to": "к",
   "widgets.shipTo": "Адрес получателя",
   "widgets.description": "Описание",
   "widgets.unitPrice": "Цена за единицу",
   "widgets.total": "Всего",
   "widgets.note": "Заметка",
   "widgets.chipWithAvatar": "Чип с аватаром",
   "widgets.chipWithTextAvatar": "Чип с текстом Аватар",
   "widgets.chipWithIconAvatar": "Чип с иконкой Аватар",
   "widgets.customClickableChip": "Пользовательский клик-чип",
   "widgets.outlineChip": "Контурный чип",
   "widgets.disableChip": "Отключить чип",
   "widgets.alertDialog": "Диалоговое окно оповещений",
   "widgets.animatedSlideDialogs": "Анимированные слайд-диалоги",
   "widgets.fullScreenDialogs": "Полноэкранные диалоги",
   "widgets.formDialogs": "Диалоги формы",
   "widgets.simpleDialogs": "Простые диалоги",
   "widgets.responsiveFullScreen": "Отзывчивый полный экран",
   "widgets.primary": "первичный",
   "widgets.social": "Социальное",
   "widgets.user": "пользователь",
   "widgets.admin": "Администратор",
   "widgets.permanentdrawer": "Постоянный ящик",
   "widgets.persistentdrawer": "Постоянный ящик",
   "widgets.swiches": "Swiches",
   "widgets.horizontalLinearAlternativeLabel": "Горизонтальная линейная альтернативная метка",
   "widgets.horizontalNonLinearAlternativeLabel": "Горизонтальная нелинейная альтернативная метка",
   "widgets.notifications": "Уведомления",
   "widgets.basicAlert": "Основное оповещение",
   "widgets.successAlert": "Успешное предупреждение",
   "widgets.warningAlert": "Предупреждающее предупреждение",
   "widgets.reactAutoSuggest": "Реагировать Авто Предложить",
   "widgets.components": "Компоненты",
   "widgets.inputAdornments": "Входные адепты",
   "widgets.multiSelectList": "много Выбрать список",
   "widgets.contextualColoredTable": "Контекстная цветная таблица",
   "widgets.updateYourEmailAddress": "Обновить адрес электронной почты",
   "widgets.selectADefaultAddress": "Выберите адрес по умолчанию",
   "widgets.activity": "Мероприятия",
   "widgets.basicCalendar": "Основной календарь",
   "widgets.culturesCalendar": "Календарь культур",
   "widgets.dragAndDropCalendar": "Календарь перетаскивания",
   "widgets.quillEditor": "Редактор Quill",
   "widgets.reactDND": "Реакция DND",
   "widgets.dragula": "Dragula",
   "button.acceptTerms": "Принять условия",
   "button.reject": "отклонять",
   "button.addNew": "Добавить новое",
   "button.goToCampaign": "Перейти в кампанию",
   "button.viewProfile": "Просмотреть профиль",
   "button.sendMessage": "Отправить сообщение",
   "button.saveNow": "Сохрани теперь",
   "button.pen": "Ручка",
   "button.search": "Поиск",
   "button.downloadPdfReport": "Загрузить отчет Pdf",
   "button.primary": "первичный",
   "button.secondary": "второстепенный",
   "button.danger": "Опасность",
   "button.info": "Информация",
   "button.success": "успех",
   "button.warning": "Предупреждение",
   "button.link": "Link",
   "button.smallButton": "Маленькая кнопка",
   "button.largeButton": "Большая кнопка",
   "button.blockLevelButton": "Кнопка уровня блока",
   "button.primaryButton": "Основная кнопка",
   "button.button": "кнопка",
   "button.save": "Сохранить",
   "button.openMenu": "Открыть меню",
   "button.openWithFadeTransition": "Открыть с переходом увядать",
   "button.openPopover": "Открыть трещать",
   "button.accept": "принимать",
   "button.click": "Нажмите",
   "button.complete": "полный",
   "button.back": "назад",
   "button.next": "следующий",
   "button.completeStep": "Полный шаг",
   "button.error": "ошибка",
   "button.writeNewMessage": "Написать новое сообщение",
   "button.saveChanges": "Сохранить изменения",
   "button.addNewUser": "Добавить пользователя",
   "button.more": "Больше",
   "hint.searchMailList": "Поиск в списке рассылки",
   "widgets.AcceptorrRejectWithin": "Принять или отклонить",
   "widgets.quoteOfTheDay": "Цитата дня",
   "widgets.updated10Minago": "Обновлено 10 мин назад",
   "widgets.personalSchedule": "Персональный график",
   "widgets.activeUsers": "Активные пользователи",
   "widgets.totalRequest": "Общий запрос",
   "widgets.new": "новый",
   "widgets.ShareWithFriends": "Поделиться с друзьями!",
   "widgets.helpToShareText": "Помогите нам распространить мир, поделившись нашим сайтом со своими друзьями и последователями в социальных сетях!",
   "widgets.thisWeek": "На этой неделе",
   "widgets.howWouldYouRateUs": "Как вы оцениваете нас?",
   "widgets.booking": "бронирование",
   "widgets.confirmed": "подтвердил",
   "widgets.monthly": "ежемесячно",
   "widgets.weekly": "еженедельно",
   "widgets.target": "цель",
   "widgets.totalActiveUsers": "Всего активных пользователей",
   "sidebar.user": "пользователь",
   "sidebar.miscellaneous": "Разное",
   "sidebar.promo": "рекламный",
   "themeOptions.themeColor": "Цвет темы",
   "module.inbox": "входящие",
   "module.drafts": "Черновики",
   "module.sent": "Отправлено",
   "module.trash": "дрянь",
   "module.spam": "Спам",
   "module.frontend": "Внешний интерфейс",
   "module.backend": "Backend",
   "module.api": "Api",
   "module.issue": "вопрос",
   "components.emailPrefrences": "Настройки электронной почты",
   "components.myProfile": "Мой профайл",
   "sidebar.gettingStarted": "Начиная",
   "widgets.deadline": "Крайний срок",
   "widgets.team": "команда",
   "widgets.projectManagement": "Управление проектом",
   "widgets.latestPost": "Последний пост",
   "widgets.projectTaskManagement": "Управление проектами",
   "widgets.selectProject": "Выберите проект",
   "widgets.activityBoard": "Доска объявлений",
   "widgets.checklist": "контрольный список",
   "sidebar.shop": "Магазин",
   "sidebar.cart": "телега",
   "sidebar.checkout": "Проверять, выписываться",
   "components.product": "Продукт",
   "components.quantity": "Количество",
   "components.totalPrice": "Итоговая цена",
   "components.removeProduct": "Удалить продукт",
   "components.mobileNumber": "Мобильный номер",
   "components.address2Optional": "Адрес 2 (необязательно)",
   "components.country": "Страна",
   "components.zip": "застежка-молния",
   "components.saveContinue": "Сохранить и продолжить",
   "components.placeOrder": "Разместить заказ",
   "components.payment": "Оплата",
   "components.billingAddress": "Платежный адрес",
   "components.ShippingAddressText": "Адрес доставки совпадает с адресом фактуры.",
   "components.CartEmptyText": "Ваша корзина пуста!",
   "components.NoItemFound": "Не найдено ни одного товара",
   "components.goToShop": "Идти в магазин",
   "components.cardNumber": "Номер карты",
   "components.expiryDate": "Срок годности",
   "components.cvv": "CVV",
   "components.nameOnCard": "Имя на карте",
   "components.confirmPayment": "Подтвердить платеж",
   "sidebar.saas": "SAAS",
   "sidebar.multiLevel": "MultiLevel",
   "sidebar.level1": "1-й уровень",
   "sidebar.level2": "Уровень 2",
   "sidebar.boxed": "в штучной упаковке",
   "sidebar.news": "Новости",
   "sidebar.extensions": "расширения",
   "sidebar.imageCropper": "Изображение Cropper",
   "sidebar.videoPlayer": "Видео проигрыватель",
   "sidebar.dropzone": "Зона сброса",
   "widgets.baseConfig": "Базовая конфигурация",
   "widgets.customControlBar": "Пользовательская панель управления",
   "widgets.withDownloadButton": "С кнопкой загрузки",
   "widgets.httpLiveStreaming": "HTTP потоковое видео",
   "widgets.keyboardShortcuts": "Горячие клавиши",
   "button.useDefaultImage": "Использовать изображение по умолчанию",
   "button.cropImage": "Обрезать изображение",
   "widgets.preview": "предварительный просмотр",
   "widgets.croppedImage": "Обрезанное изображение",
   "sidebar.crm": "CRM",
   "sidebar.dashboard": "приборная доска",
   "widgets.transactionList": "список транзакций",
   "widgets.transferReport": "отчет о передаче",
   "widgets.expenseCategory": "категория расходов",
   "widgets.upcomingEvents": "Предстоящие События",
   "widgets.OngoingProjects": "текущие проекты",
   "widgets.ProjectStatus": "статус проекта",
   "widgets.LiveChatSupport": "поддержка в чате",
   "sidebar.projects": "проектов",
   "sidebar.projectDetail": "Деталь проекта",
   "sidebar.clients": "клиенты",
   "sidebar.reports": "отчеты"
}
