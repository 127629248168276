import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../../../Api/ApiService/Service';
import IntlMessages from 'Util/IntlMessages';
import { Button, Checkbox, FormControl } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
export default function USManageSidebar() {
    const {id} = useParams();
    const history = useHistory();
    const [NavLinks, setNavLinks] = useState(null);
    const [sidebar, setSidebar] = useState(null);
    const [applications, setApplication] = useState(null);
    const [profileName, setProfileName] = useState();
    useEffect(() => {
        fetchSidebarData();
    },[id])

    const fetchSidebarData = async() => {
        const response = await getRequest('/company/getProfile/'+id);
        console.log('response?.data?.data',response?.data);
        setNavLinks(response?.data?.data);
        setProfileName(response?.data?.data?.Name);
        setSidebar(response?.data?.data?.sidebars);
        setApplication(response?.data?.data?.applications);
    }

    const saveProfile = async() => {
        const response = await postRequest('/company/saveProfile', {"layout" : {"applications" : applications, "sidebars" : sidebar}, "ProfileId" : id});
        history.push('/setup/profile');
    }

    const handleUpdateSectionSidebar = (sectionName, subsectionName) => {
        let tempSidebar = {...sidebar};
        tempSidebar[sectionName][subsectionName].isVisible = !tempSidebar[sectionName][subsectionName].isVisible;
        console.log(tempSidebar[sectionName][subsectionName]['isVisible']);
        setSidebar(tempSidebar);
    }

    const handleUpdateNavItemSidebar = (sectioName, subSectioName, menuItem) => {
        let tempSidebar = {...sidebar};
        tempSidebar[sectioName][subSectioName]["links"][menuItem]['isVisible'] = !sidebar[sectioName][subSectioName]["links"][menuItem]['isVisible'];
        setSidebar(tempSidebar);
    }

    const handleUpdateChildNavItemSidebar = (sectioName, subSectioName, menuItem, subNavKey) => {
        let tempSidebar = {...sidebar};
        tempSidebar[sectioName][subSectioName]["links"][menuItem]['child_routes'][subNavKey]['isVisible'] = !sidebar[sectioName][subSectioName]["links"][menuItem]['child_routes'][subNavKey]['isVisible'];
        setSidebar(tempSidebar);
    }

    const handleUpdateApplication = (applicationIndex) => {
        let appl = [...applications];
        appl[applicationIndex].isVisible = !appl[applicationIndex].isVisible;
        setApplication(appl);
    }

    return (
        <div>
            {profileName}
            <div>
                <Button onClick={saveProfile}>Save</Button>
                <Button onClick={() => history.push('/setup/profile')}>Return</Button>
            </div>
            <div style={{ backgroundColor:'#5dc4db' }}>Application Access</div>
            {applications && applications.map((sectioName, indexKey) => (
                <div className="row" key={indexKey}>
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                        {sectioName['Name']}
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                        <Checkbox label="isVisible" checked={sectioName.isVisible} onChange={() => handleUpdateApplication(indexKey)}/>
                    </div>
                </div>
            ))}
            <FormControl>
                <div style={{ backgroundColor:'#5dc4db' }}>Sidebar sections</div>
                {sidebar && Object.keys(sidebar).map((sectioName, index2Key) => (
                    <div key={index2Key}>
                        <div style={{ marginLeft:"20px", backgroundColor:'#05d1ff' }}>{sectioName}</div>
                        {Object.keys(sidebar[sectioName]).map((subSectioName, index3Key) => (
                            <div style={{ marginLeft:"30px", borderBottom: '2px solid black' }} className="row" key={index3Key}>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    Section Name : <IntlMessages id={`${sidebar[sectioName][subSectioName]['messageId']}`} />
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <Checkbox label="isVisible" checked={sidebar[sectioName][subSectioName].isVisible} onChange={() => handleUpdateSectionSidebar(sectioName, subSectioName)}/>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    {sidebar[sectioName][subSectioName]["links"].map((menuItem, menuItemKey) => (
                                        <div className='row' style={{ marginLeft:"50px", backgroundColor:'#9dd967', borderBottom: '2px solid black' }} key={menuItemKey}>
                                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                                <IntlMessages id={`${menuItem['menu_title']}`} />
                                            </div>
                                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                                <Checkbox label="isVisible"  checked={menuItem['isVisible']} onChange={() => handleUpdateNavItemSidebar(sectioName, subSectioName, menuItemKey)}/>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                                {(menuItem['child_routes']?.length > 0) && menuItem['child_routes'].map((subNavItem, subNavKey) => (
                                                    <div className="row" style={{ marginLeft:"70px",borderBottom: '2px solid black' }} key={subNavKey}>
                                                        <div className='col-sm-4 col-md-4 col-lg-4'>
                                                            <IntlMessages id={`${subNavItem['menu_title']}`} />
                                                        </div>
                                                        <div className='col-sm-4 col-md-4 col-lg-4'>
                                                            <Checkbox label="isVisible"  checked={subNavItem['isVisible']} onChange={() => handleUpdateChildNavItemSidebar(sectioName, subSectioName, menuItemKey, subNavKey)}/>
                                                        </div>
                                                        <div className='col-sm-4 col-md-4 col-lg-4'>
                                                            <Checkbox label="isVisible"  checked={subNavItem['new_item']}/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </FormControl>
        </div>
    )
}
