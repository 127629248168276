import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import IntlMessages from '../../../../../../util/IntlMessages';
import { Element } from '../../../../../../helpers/RouteHelper';

export default function USCMLayout({sections, providedObjectFields, readOnly, referendeObject, updateRecordDetails, objectName}) {

    const getReferenceTitle = (fieldName, refId, referendeObjectList) => {
        if(referendeObjectList?.hasOwnProperty(fieldName)) {
            return referendeObjectList[fieldName][refId];
        }
        return null;
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = providedObjectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return providedObjectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    const [fullLayout, setFullLayout] = useState(null);
    useEffect(() => {
        if(sections && providedObjectFields && objectName && referendeObject) {
            setFullLayout(buildLayout());
        }
    },[providedObjectFields])

    const buildLayout = () => {
        return (<div>
            {sections && providedObjectFields && objectName && referendeObject ? sections.map((section, i) => (
                <div className="row p-10" key={'section:'+i}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Box style={{width: '100%', height:'25px', backgroundColor: '#e2eaf2'}} >
                            {section?.sectionName && <IntlMessages id={section?.sectionName} defaultMessage={section?.sectionName}/>}
                        </Box>
                        {(section?.style && section.style === 'columns') ?
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                    {section?.leftSideFields && section.leftSideFields.map((lField, j) => {
                                        if(lField.show) {
                                            let depLookUp = lField.hasOwnProperty('depended_picklist') ? chekIfDependedLookup(lField) : null;
                                            const lookupTitle = lField.field_type && lField.field_type == 'bigint' ? getReferenceTitle(lField.field_api_name, providedObjectFields[lField.field_api_name], referendeObject) : null;
                                            return <Element objectName={objectName} referenceTitle={lookupTitle} key={`key_${lField.field_api_name}`} fieldDetails={lField} handleEdit={updateRecordDetails} value={providedObjectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"  readOnly={readOnly}/>
                                        }
                                        return null;
                                    })}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                    {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                        if(rField.show) {
                                            let depLookUp = rField.hasOwnProperty('depended_picklist') ? chekIfDependedLookup(rField) : null;
                                            const lookupTitle = rField.field_type && rField.field_type == 'bigint' ? getReferenceTitle(rField.field_api_name, providedObjectFields[rField.field_api_name], referendeObject) : null;
                                            return <Element objectName={objectName} referenceTitle={lookupTitle} key={`key_${rField.field_api_name}`} fieldDetails={rField} handleEdit={updateRecordDetails} value={providedObjectFields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard"  readOnly={readOnly}/>
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                                :
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12" style={{ display:'block' }}>
                                    {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                        if(lField.show) {
                                            let depLookUp = lField.hasOwnProperty('depended_picklist') ? chekIfDependedLookup(lField) : null;
                                            const lookupTitle = lField.field_type && lField.field_type == 'bigint' ? getReferenceTitle(lField.field_api_name, providedObjectFields[lField.field_api_name], referendeObject) : null;
                                            return <Element objectName={objectName} referenceTitle={lookupTitle} key={`key_${lField.field_api_name}`} fieldDetails={lField} handleEdit={updateRecordDetails} value={providedObjectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={readOnly}/>
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                )) : null
            }
        </div>)
    }

    return (
        <>{fullLayout}</>
    )
}
