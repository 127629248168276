import { Card, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import USModal from '../../USModal/USModal';

export default function USTableContractDetails({opportunityProducts}) {
    const navigate = useHistory();
    const [recordDetailsIsOpen, setRecordDetailsIsOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    return (
        <Card>
            <CardContent>
                <div className="row">
                    {opportunityProducts && opportunityProducts?.map((productItem, index) => (
                        <div key={`opportunityproduct${index}`} className="col-md-offset-1 col-md-12  mb-20">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col col-sm-11 col-xs-11">
                                            <a onClick={() => {setSelectedRecord(productItem); setRecordDetailsIsOpen(true)}}>{productItem?.Name}</a>
                                        </div>
                                        <div className="col col-sm-1 col-xs-1">
                                            Цена: {productItem?.TotalPrice} Руб.
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Просмотр</th>
                                                <th scope="col">Название</th>
                                                <th scope="col">Количество</th>
                                                <th scope="col">Цена</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productItem?.opportunityproductparts?.map((partItem, partItemIndex) => (
                                                <tr key={`productparts_${partItemIndex}`}>
                                                    <td>
                                                        <a onClick={() => navigate.push(`sales/view/opportunityproductpart/${partItem.id}`)}>подробнее</a>
                                                    </td>
                                                    <td>
                                                        {partItem?.Name}
                                                    </td>
                                                    <td>
                                                        {partItem?.Quantity}
                                                    </td>
                                                    <td>
                                                        {partItem?.TotalPrice}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </CardContent>
            {selectedRecord && <USModal objectName={'opportunityproduct'} id={selectedRecord.id} isOpenModal={recordDetailsIsOpen} closeModal={() => setRecordDetailsIsOpen(false)} />}
        </Card>

    )
}
