import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Modal } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { getRelatedOpenOpportunity } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import IntlMessages from '../../../../util/IntlMessages';
import { useHistory } from 'react-router-dom';
export default function USCheckIfOppExist({isOpen, handleAction, contactId, selectOpportunity}) {

    const navigate = useHistory();

    useEffect(() => {
        if(contactId) {
            fetchData();
        }
    },[contactId])

    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    const [relatedObjects, setRelatedObjects ] = useState(null);

    const fetchData = async() => {
        if(contactId) {
            const response = await getRelatedOpenOpportunity(contactId);
            if(!response?.count && contactId) {
                selectOpportunity(null);
            }
            setRelatedObjects({...response?.relatedObjects});
            setRows(response.tableData);
            setColumns(response.tableHeader);
        }
    }

    const [searchCondition, setSearchCondition] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState('');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch(searchInputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchInputValue, 500]);

    const handleSearch = (searchString) => {
        if(searchString && searchString.length > 0) {
            let searchCondition = {};
            columns.map((headreItem) => {
                searchCondition[headreItem.fieldName] = searchString;
            });
            setSearchCondition(searchCondition);
        } else {
            setSearchCondition(null);
        }
    }

    const getRelatedObjectName = (objectName, objectId) => {
        if(relatedObjects?.hasOwnProperty(objectName)) {
            if(relatedObjects[objectName]?.hasOwnProperty(objectId)) {
                return relatedObjects[objectName][objectId];
            } else {
                return  objectId;
            }
        }
        return objectId;
    }

    const handleClose = (cellItem, fieldName) => {
        handleAction();
        navigate.push(`/sales/view/${cellItem?.referenceObject ? cellItem?.referenceObject : 'opportunity'}/${fieldName}`)
    }

    const getColumnData = (value, fieldType) => {
        switch (fieldType) {
            case 'datetime':
            return butifyDateFormat(value);

            case 'date':
            return butifyDateFormat(value);

            default:
            return value;
        }
    }

    const  butifyDateFormat = (value) => {
        if(value != null) {
            let colData = new Date(value);
            let day = colData.getDate();
            let month = colData.getMonth();
            let year = colData.getFullYear();
            return month+1 + "/" + day + "/" + year;
        }
        return '';
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleAction}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10"
            style={{ height: "90%", overflowY: 'scroll' }}
        >
            <Card>
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        O
                    </Avatar>
                    }
                    action={
                        <>
                            <ButtonGroup>
                                <Button onClick={handleAction}>Close</Button>
                            </ButtonGroup>
                        </>
                    }
                    title="Новый контракт"
                />
                <CardContent style={{ minHeight: "400px" }}>
                    {(rows && columns) && <div className="col-md-offset-1 col-md-12">
                        <div className="panel">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col col-sm-3 col-xs-12">

                                    </div>
                                    <div className="col-sm-9 col-xs-12 text-right">
                                        <div className="btn_group" >
                                            <div className='mt-10' style={{ justifyContent:"flex-end", alignItems:"flex-end" }}>
                                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchInputValue(e.target.value)}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            {columns.map((headreItem, headerItemIndex) => (
                                                <th scope="col" key={`header_${headerItemIndex}`} ><IntlMessages id={headreItem.fieldLabel}/></th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((rowItem, rowItemIndex) => (
                                            <tr key={`row_${rowItemIndex}`}>

                                                {columns.map((cellItem, cellItemIndex) => (
                                                    <td key={`cell_${cellItemIndex}`} >

                                                            {(cellItem.fieldType == 'bigint') ?
                                                                <a onClick={() =>
                                                                    selectOpportunity(rowItem)
                                                                }>{cellItem.fieldName != 'id' ? getRelatedObjectName(cellItem.referenceObject, rowItem[cellItem.fieldName]) : 'Выбрать'}</a>
                                                            :
                                                                <div>
                                                                    {cellItem.fieldName != 'Name' ?
                                                                        getColumnData(rowItem[cellItem.fieldName], cellItem.fieldType)
                                                                    :
                                                                        <a className="rct-link-btn" onClick={() =>
                                                                            handleClose(cellItem, rowItem['id'])
                                                                        }>{rowItem[cellItem.fieldName]}</a>
                                                                    }
                                                                </div>
                                                            }
                                                    </td>
                                                ))}

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </CardContent>
            </Card>
        </Modal>
    )
}
