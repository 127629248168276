import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import IntlMessages from 'Util/IntlMessages';
import USInputHelperIcon from '../USPopups/USInputHelperIcon';

export default function USNumber({fieldDetails, handleChange, value, variant, readOnly, keyItem, isRequired }) {

    const checkIfNull = (val) => {
        return val != null ? val : '';
    }

    const [val, setVal] = useState(value);
    const [valDetails, setValDetails] = useState();
    const updateValue = (ev, index) => {
        setValDetails({target:{value: ev.target.value, name: ev.target.name}});
    };

    useEffect(() => {
        if(valDetails) {
            setVal(valDetails?.target?.value);
            const timeoutId = setTimeout(() => {
                handleChange(valDetails, keyItem);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [valDetails, 500]);

    return (
        <TextField
            required={isRequired}
            id={fieldDetails.field_api_name}
            name={fieldDetails.field_api_name}
            label={<IntlMessages id={fieldDetails.field_label} />}
            onChange={(e) => updateValue(e, keyItem)}
            value={checkIfNull(val)}
            variant={variant}
            fullWidth
            margin="dense"
            helperText={fieldDetails.helperText}
            InputProps={{
                readOnly: (fieldDetails.field_access == 'edit' && !readOnly ? false : true),
                inputMode: 'numeric',
                endAdornment:
                (fieldDetails.helperText != '') ? (<USInputHelperIcon text={fieldDetails.helperText}/>)  : null
            ,
            }}
        />
    );
}

