import { Route } from "react-router-dom";
import USPublicFooter from "./components/USPublicFooter";
import USPublicHeader from "./components/USPublicHeader";
import USPublicHome from "./pages/USPublicHome";
import USPublicLogin from "./pages/USPublicLogin";

function USPublicSite() {

    return (
        <div className="rct-app-content">
            <div className="app-header">
                <USPublicHeader />
            </div>
            <div className="app-horizontal collapsed-sidebar">
                <div className="app-container">
                    <div className="rct-page-wrapper">
                        <div className="rct-page">
                            {/*<Route path="/register" component={USPublicLogin} />*/}
                            <Route path="/login" component={USPublicLogin} />
                            <Route exact path="/" component={USPublicHome} />
                        </div>
                    </div>
                </div>
            </div>
            <USPublicFooter />
        </div>
    )
}

export default USPublicSite;
