import React, { useEffect, useState } from 'react'
import { getListViewFilters, getObjectFields } from '../../../../Api/OrgRequests/OrgRequestHandler';
import { NativeSelect, TextField } from '@mui/material';
import {  useIntl } from 'react-intl';

export default function USListViewFilters({listViewId, objectName, handleUpdateDetails}) {

    const intl = useIntl();
    const [listViewFilters, setListViewFilters] = useState();
    const [fieldsToSelect, setFieldsToSelect] = useState();
    const [operators, setOperators] = useState([
        {'Name':'Равно', 'Value':'='},
        {'Name':'Не Равно', 'Value':'!='},
        {'Name':'Больше', 'Value':'>'},
        {'Name':'Меньше', 'Value':'<'},
        {'Name':'Меньше или равно', 'Value':'<='},
        {'Name':'Больше или равно', 'Value':'>='},
        {'Name':'Содержит', 'Value':'contains'},
        {'Name':'Не содержит', 'Value':'not_contains'}
    ]);

    const fetchData = async() => {
        try {
            if(listViewId) {
                const selectedObjectFields = await getObjectFields(objectName);
                setFieldsToSelect(selectedObjectFields?.data);
                const response = await getListViewFilters(listViewId);
                setListViewFilters([...response.data, {ListViewId : listViewId, FieldName: '', Operator: '', FilterValue :'' }]);
            }
        } catch (e) {
            console.log('exception', e);
        }
    }

    useEffect(() => {
        fetchData();
    },[listViewId]);

    const handleAddFilter = () => {
        setListViewFilters([...listViewFilters, {ListViewId : listViewId, FieldName: '', Operator: '', FilterValue :'' }]);
    }

    const handleChange = (e, filterIndex) => {
        let updatedListViewFilters = listViewFilters;
        updatedListViewFilters[filterIndex] = {
            ...updatedListViewFilters[filterIndex],
            [e.target.name] : e.target.value
        }
        setListViewFilters([...updatedListViewFilters]);
        handleUpdateDetails([...updatedListViewFilters], 'listviewfilter')
    }

    return (
        <>
            {listViewFilters && listViewFilters?.map((filterItem, filterIndex) => (
                <div key={filterIndex} className='row'>
                    <div className='col-sm-12 col-xs-12 col-md-4 col-lg-4'>
                        {fieldsToSelect && <NativeSelect
                            name="FieldName"
                            defaultValue={filterItem?.FieldName}
                            onChange={(e) => handleChange(e, filterIndex)}
                        >
                            {fieldsToSelect && fieldsToSelect.map((fieldItem, indexKey) => (
                                <option key={indexKey} value={fieldItem.fieldName} >{intl.formatMessage({id:fieldItem.fieldLabel})}</option>
                            ))}
                        </NativeSelect>}
                    </div>
                    <div className='col-sm-12 col-xs-12 col-md-4 col-lg-4 mt-10'>
                        {operators && <NativeSelect
                            name="Operator"
                            defaultValue={filterItem?.FieldName}
                            onChange={(e) => handleChange(e, filterIndex)}
                        >
                            {operators.map((listViewItem, indexKey) => (
                                <option key={indexKey} value={listViewItem.Value} >{listViewItem.Name}</option>
                            ))}
                        </NativeSelect>}
                    </div>
                    <div className='col-sm-12 col-xs-12 col-md-4 col-lg-4 mt-10'>
                        <TextField
                            id="fieldValue"
                            label="Значение"
                            type="text"
                            name="FilterValue"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                            value={filterItem.FilterValue}
                            onChange={(e) => handleChange(e, filterIndex)}
                        />
                    </div>
                </div>
            ))}

            <a onClick={handleAddFilter} >Добавить еще фильтр</a>
        </>
    )
}
