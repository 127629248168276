// sidebar nav links
export default {
    "category1": {
        "isVisible":true,
        "messageId" : "sidebar.general",
         "links" : [
            {
                "id": 0,
                "menu_title": "sidebar.us_company-label",
                "menu_icon": "zmdi zmdi-assignment-account",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "isVisible":true,
                        "menu_title": "sidebar.us_company-dashboard",
                        "new_item": false,
                        "path": "/setup"
                    },
                    {
                        "menu_title": "sidebar.us_company-general",
                        "new_item": false,
                        "menu_icon": "zmdi zmdi-view-dashboard",
                        "path": "/setup/general"
                    },
                    {
                        "menu_title": "sidebar.us_company-info",
                        "new_item": false,
                        "path": "/setup/company-information"
                    },
                    {
                        "menu_title": "sidebar.us_company-employees",
                        "new_item": false,
                        "path": "/setup/employee"
                    },

                    {
                        "menu_title": "sidebar.us_stores",
                        "new_item": false,
                        "path": "/setup/store"
                    },
                    {
                        "menu_title": "sidebar.site-builder",
                        "new_item": false,
                        "path": "/setup/site-builder"
                    },
                    {
                        "menu_title": "common.settings",
                        "new_item": false,
                        "menu_icon": "zmdi zmdi-settings",
                        "path": "/setup/settings"
                    },
                    {
                        "menu_title": "common.objectmanager",
                        "new_item": false,
                        "menu_icon": "zmdi zmdi-settings",
                        "path": "/setup/ObjectManager"
                    }
                ]
            },
            {
                "id": 14,
                "menu_title": "production.name",
                "menu_icon": "zmdi zmdi-calendar-note",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "id" : 1,
                        "open":false,
                        "menu_title": "sidebar.us_products",
                        "new_item": false,
                        "path": "/product"
                    },
                    {
                        "id" : 2,
                        "open":false,
                        "menu_title": "common.load-product",
                        "new_item": false,
                        "path": "/setup/loadproduct"
                    },
                    {
                        "id" : 3,
                        "open":false,
                        "menu_title": "sidebar.complects",
                        "menu_icon": "zmdi zmdi-email",
                        "path": "/setup/productparts",
                        "new_item": false,
                        "child_routes": null
                    },
                    {
                        "id" : 4,
                        "open":false,
                        "menu_title": "sidebar.company_products",
                        "menu_icon": "zmdi zmdi-email",
                        "path": "/setup/productscompany",
                        "new_item": false,
                        "child_routes": null
                    },
                    {
                        "id" : 5,
                        "open":false,
                        "menu_title": "sidebar.partcategory",
                        "menu_icon": "zmdi zmdi-email",
                        "path": "/setup/partcategory",
                        "new_item": false,
                        "child_routes": null
                    },
                    {
                        "id" : 6,
                        "open":false,
                        "menu_title": "productparts",
                        "menu_icon": "zmdi zmdi-email",
                        "path": "/setup/productparts",
                        "new_item": false,
                        "child_routes": null
                    },
                    {
                        "id" : 7,
                        "open":false,
                        "menu_title": "sidebar.us_company-assets",
                        "new_item": false,
                        "path": "/setup/asset",
                        "child_routes": null
                    }
                ]
            },
            {
                "id": 1,
                "menu_title": "sidebar.us_company-sales-label",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.handbook",
                        "open": false,
                        "child_routes": [
                            {
                               "menu_title": "sidebar.carsbrands",
                               "new_item": false,
                               "path": "/setup/handbook/carslist"
                            },
                            {
                                "menu_title": "sidebar.cities",
                                "new_item": false,
                                "path": "/setup/handbook/cities"
                            },
                            {
                                "menu_title": "sidebar.currencies",
                                "new_item": false,
                                "path": "/setup/handbook/currencies"
                            },
                            {
                                "menu_title": "sidebar.parts-brands",
                                "new_item": false,
                                "path": "/setup/handbook/parts-brands"
                            }
                        ]
                    },
                    {
                        "menu_title": "sidebar.us_clients-management",
                        "new_item": false,
                        "path": "/setup/clients-management"
                    },
                    {
                        "menu_title": "sidebar.us_orders",
                        "new_item": false,
                        "path": "/setup/order"
                    },
                    {
                        "menu_title": "sidebar.us_clients",
                        "new_item": false,
                        "path": "/setup/clients"
                    },
                    {
                        "menu_title": "sidebar.us_consumers",
                        "new_item": false,
                        "path": "/setup/consumers"
                    },
                    {
                        "menu_title": "sidebar.us_suppliers",
                        "new_item": false,
                        "path": "/setup/suppliers"
                    },
                    {
                        "menu_title": "sidebar.markup",
                        "new_item": false,
                        "path": "/setup/markup"
                    }
                ]
            },
            {
                "id": 2,
                "menu_title": "sidebar.marketing",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "marketing.posts",
                        "new_item": false,
                        "path": "/setup/article"
                    },
                    {
                        "menu_title": "marketing.gift",
                        "new_item": false,
                        "path": "/setup/marketing/gift"
                    },
                    {
                        "menu_title": "marketing.discounts",
                        "new_item": false,
                        "path": "/setup/marketing/discount"
                    },
                    {
                        "menu_title": "marketing.vouchers",
                        "new_item": false,
                        "path": "/setup/marketing/voucher"
                    }
                ]
            },
            {
                "id": 3,
                "menu_title": "sidebar.us_company-hr",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.us_company-vacancies",
                        "new_item": false,
                        "path": "/setup/hr/vacancies"
                    },
                    {
                        "menu_title": "sidebar.us_company-positions",
                        "new_item": false,
                        "path": "/setup/hr/positions"
                    },
                    {
                        "menu_title": "sidebar.us_company-accounting-reports",
                        "new_item": false,
                        "path": "/setup/hr/report"
                    }
                ]
            },
            {
                "id": 4,
                "menu_title": "sidebar.us_company-accounting",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.us_company-accounting-calculate_main",
                        "new_item": false,
                        "path": "/setup/accounting/calculation"
                    },
                    {
                        "menu_title": "sidebar.us_company-accounting-calculate_salary",
                        "new_item": false,
                        "path": "/setup/accounting/salaries"
                    },
                    {
                        "menu_title": "sidebar.us_company-accounting-reports",
                        "new_item": false,
                        "path": "/setup/accounting/reports"
                    }
                ]
            },
            {
                "id": 5,
                "menu_title": "sidebar.us_company-career-label",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": true,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.us_learning",
                        "new_item": false,
                        "path": "/setup/learning"
                    },
                    {
                        "menu_title": "sidebar.us_achievements",
                        "new_item": false,
                        "path": "/setup/achievements"
                    },
                    {
                        "menu_title": "sidebar.us_progress",
                        "new_item": false,
                        "path": "/setup/progress"
                    },
                    {
                        "menu_title": "sidebar.us_company-vacation",
                        "new_item": false,
                        "path": "/setup/vacations"
                    }
                ]
            },
            {
                "id": 6,
                "menu_title": "sidebar.us_company-activity-label",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": true,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.us_company-requests",
                        "new_item": false,
                        "path": "/setup/requests"
                    },
                    {
                        "menu_title": "sidebar.us_company-events",
                        "new_item": false,
                        "path": "/setup/events"
                    },
                    {
                        "menu_title": "sidebar.us_tasks",
                        "new_item": false,
                        "path": "/setup/tasks"
                    }
                ]
            },
            {
              "id": 7,
                "menu_title": "sidebar.calendar",
                "menu_icon": "zmdi zmdi-calendar-note",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                   {
                      "path": "/setup/calendar/basic",
                      "new_item": false,
                      "menu_title": "components.basic"
                   },
                   {
                      "path": "/setup/calendar/cultures",
                      "new_item": false,
                      "menu_title": "sidebar.cultures"
                   },
                   {
                      "path": "/setup/calendar/selectable",
                      "new_item": false,
                      "menu_title": "sidebar.selectable"
                   },
                   {
                      "path": "/setup/calendar/custom-rendering",
                      "new_item": false,
                      "menu_title": "sidebar.customRendering"
                   }
                ]
             },
             {
                "id" : 8,
                "open":false,
                "menu_title": "sidebar.us_company-desktop",
                "menu_icon": "zmdi zmdi-info",
                "path": "/horizontal/dashboard/saas",
                "new_item": false,
                "child_routes": null
            }
        ]
    },
    "category2": {
            "messageId" : "sidebar.general",
            "links" : [
        {"id" : 9,
        "open":false,
            "menu_title": "sidebar.inbox",
            "menu_icon": "zmdi zmdi-email",
            "path": "/setup/mail",
            "new_item": false,
            "child_routes": null
        },
        {"id" : 10,
        "open":false,
            "menu_title": "sidebar.chat",
            "menu_icon": "zmdi zmdi-comments",
            "path": "/setup/chat",
            "new_item": false,
            "child_routes": null
        },
        {"id" : 11,
        "open":false,
            "menu_title": "sidebar.toDo",
            "menu_icon": "zmdi zmdi-comment-text-alt",
            "path": "/setup/todo",
            "new_item": false,
            "child_routes": null
        }
   ]},
   "category3": {
        "messageId" : "sidebar.general",
        "links" : [
        {
            "id" : 12,
                "open":false,
            "menu_title": "sidebar.aboutUs",
            "menu_icon": "zmdi zmdi-info",
            "path": "/setup/about-us",
            "new_item": false

        }
    ]},
    "category4": {
        "messageId" : "components.advancedSettings",
        "links" : [
            {
                "id": 13,
                "menu_title": "common.settings",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "common.profileSettings",
                        "new_item": false,
                        "path": "/setup/profile"
                    },
                    {
                        "menu_title": "common.roleSettings",
                        "new_item": false,
                        "path": "/setup/roles"
                    }
                ]
            }
        ]
    }
}
