import { Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CircularProgress, FormControl, IconButton, MenuItem, NativeSelect, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { red } from '@mui/material/colors';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../../reduxSettings/reduxSlices/NotificationSlice';
import { deleteRecord, getTableData } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import USAlertDialog from '../../USAlertDialog/USAlertDialog';

export default function SearchProductSidebar({objectName, sortField, sortDirection='asc', parentFildName=null, parentRecordId=null, handleOpen, refreshToken, showDelete=false}) {
    const [isLoading, setIsLoading] = useState();
    const [relatedObjects, setRelatedObjects] = useState();
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [listView, setListView] = useState();
    const [selectedListView, setSelectedListView] = useState('allProducts');
    const [searchCondition, setSearchCondition] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        const response = await getTableData('company/getTableList', objectName, pageNumber, sortField, sortDirection, rowsPerPage, parentFildName, parentRecordId, searchCondition,selectedListView);
        setRelatedObjects(response?.relatedObjects);
        setCount(response?.count);
        setTableData(response?.tableData);
        setListView(response?.listView);
        if(!selectedListView) {
            response?.listView?.map((listViewItem) => {
                if(listViewItem.selected) {
                    setSelectedListView(listViewItem);
                }
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    },[objectName, refreshToken, pageNumber, selectedListView, searchCondition])

    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const handlePrevPage = () => {
        if(pageNumber > 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleChangeRecPerPage = (value) => {
        setRowsPerPage(value);
        setPageNumber(0);
    }

    const [openDetails, setOpenDetails] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const handleOpenDetails = (selectedId) => {
        setTaskId(selectedId);
        setOpenDetails(true);
    }

    const [oenDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogDesctiption, setDialogDesctiption] = useState();
    const [recordToDelete, setRecordToDelte] = useState();
    const handleOpenDeleteDialog = (recordName, recId, objectName) => {
        setDialogTitle('Delete Record');
        setDialogDesctiption('Are you sure you want to delete ' + recordName);
        setRecordToDelte({recordId: recId, objectName: objectName});
        setOpenDialog(true);

    }

    const handleDeleteDialog = (value) => {
        if(value) {
            handleDelete();
            setOpenDialog(false);
        } else {
            setOpenDialog(false);
        }
    }

    const dispatch = useDispatch();
    const handleDelete = async() => {
        try {
            const response = await deleteRecord(recordToDelete.objectName, recordToDelete.recordId);
            if(response.status == 200) {
                dispatch(addNotification({"type" : "success", "message" : "Запись удалена"}));
                fetchData();
            } else {
                dispatch(addNotification({"type" : "error", "message" : "Delete Record"}));
            }
        } catch(e) {
            dispatch(addNotification({"type" : "error", "message" : "Exception: " + e}));
        }
    }

    const [searchInputValue, setSearchInputValue] = useState('');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch(searchInputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchInputValue, 500]);

    const handleSearch = (searchString) => {
        if(searchString && searchString.length > 0) {
            let searchCondition = {};

            searchCondition['Name'] = searchString;
            searchCondition['Series'] = searchString;

            setSearchCondition(searchCondition);
        } else {
            setSearchCondition(null);
        }
    }
    return (
        <Card>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    T
                </Avatar>
                }
                action={
                    <div>
                        {selectedListView && <NativeSelect
                            defaultValue={selectedListView}
                            value={selectedListView}
                            onChange={(e) => setSelectedListView(e.target.value)}
                        >
                                {listView && listView.map((listViewItem, indexKey) => (
                                    <option key={indexKey} value={listViewItem.ApiName}>{listViewItem.Name}</option>
                                ))}
                        </NativeSelect>}
                    </div>
                }
                title="Продукты"
                subheader="Текущий список"
            />
            <CardContent>
                <input type="text" className="form-control" onChange={(e) => setSearchInputValue(e.target.value)}/>
                {!isLoading ? <Table bordered="true" striped="true" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Название</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData && tableData.map((tableRowItem, itemRowIndex) => (
                        <TableRow hover key={itemRowIndex}>
                            <TableCell>
                                <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <div className='rct-link-btn' onClick={() => handleOpen(tableRowItem.id, objectName)}>{tableRowItem.Name} {tableRowItem?.TotalPrice ? tableRowItem?.TotalPrice : ''}</div>
                                    <div>
                                        <ButtonGroup size="small" aria-label="Small button group">
                                            {showDelete && <Button onClick={() => handleOpenDeleteDialog(tableRowItem.Name, tableRowItem.id, objectName)}>Delete</Button>}
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
               </Table> :
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>}
            </CardContent>
            <CardActions>

                {pageNumber > 0 && <Button size="small" variant='outlined' onClick={handlePrevPage}>Предыдущая</Button>}
                <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Age"
                        onChange={(e)=> handleChangeRecPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                {(count > (pageNumber+1)*rowsPerPage) && <Button size="small" variant='outlined' onClick={handleNextPage}>Следующая</Button>}

            </CardActions>
            <USAlertDialog isOpen={oenDialog} dialogTitle={dialogTitle} dialogDesctiption={dialogDesctiption} handleAction={handleDeleteDialog}/>
        </Card>
    )
}
