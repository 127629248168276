import { getRequest, postRequest } from "../ApiService/Service";

export async function selectObjectRecords(requestUrl, objectName, currentPage, sortOrderField, sortOrderDirection, rowsPerPage, parentFieldName, parentObjectId, searchCondition, listViewApiName) {
    try {
        let searchParams = {};
        objectName = objectName ? objectName : null;
        parentFieldName = parentFieldName ? parentFieldName : null;
        parentObjectId = parentObjectId ? parentObjectId : null;
        searchParams['objectName'] =  objectName;
        searchParams['currentPage'] =  currentPage;
        searchParams['sortOrderField'] =  sortOrderField;
        searchParams['sortOrderDirection'] =  sortOrderDirection;
        searchParams['rowsPerPage'] =  rowsPerPage;
        searchParams['parentFieldName'] =  parentFieldName;
        searchParams['parentObjectId'] =  parentObjectId;
        searchParams['searchCondition'] = searchCondition;
        searchParams['listViewApiName'] = listViewApiName;
        const response = await postRequest(requestUrl, searchParams);
        return response?.data?.data;
    } catch(error) {
        return error;
    }
}

export async function selectDataForCalendar(objectName, sortByField, period, startDate, endDate, startDateField, endDateField, searchCondition, selectedListView) {
    try {
        let searchParams = {};
        objectName = objectName ? objectName : null;
        searchParams['objectName'] =  objectName;
        searchParams['sortByField'] =  sortByField;
        searchParams['period'] =  period;
        searchParams['startDate'] =  startDate;
        searchParams['endDate'] =  endDate;
        searchParams['startDateField'] =  startDateField;
        searchParams['endDateField'] = endDateField;
        searchParams['searchCondition'] = searchCondition;
        searchParams['selectedListView'] = selectedListView;
        const response = await postRequest(`/company/selectObjectRecordsByDate`, searchParams);
        return response?.data?.data;
    } catch(error) {
        return error;
    }

}

export async function deleteRecord(objectName, recId) {
    try{
        const response = await getRequest('/company/delete/'+objectName +  '/' + recId);
        return response;
    } catch(error) {
        return error;
    }
}

export async function saveRecord(objectName, id, objectFields) {
    try{
        const response = await postRequest('/company/edit/'+objectName +  '/' + id, objectFields);
        return response;
    } catch(error) {
        return error;
    }
}

export async function updateListView(data) {
    try {
        const response = await postRequest('/company/updateListView', data);
        return response?.data;
    } catch(error) {
        return error;
    }
}

export async function saveListFilters(data) {
    try {
        const response = await postRequest('/company/setListViewFilters/', data);
        return response?.data;
    } catch(error) {
        return error;
    }
}

export async function getListViewFilters(id) {
    try {

        const response = await getRequest('/company/getListViewFilters/' + id);
        return response?.data;
    } catch(error) {
        return error;
    }
}

export async function getObjectFields(objectName) {
    try {

        const response = await getRequest('/company/objectFields/' + objectName);
        return response?.data;
    } catch(error) {
        return error;
    }
}

