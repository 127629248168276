import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import USNewRecord from "./USNewRecord/USNewRecord";
import { closeNewRecordDialog } from "../../../../../reduxSettings/reduxSlices/systemSlice";

export default function USNewRecDialog() {

    const dispatch = useDispatch();
    const recordData = useSelector((state) => state.system.recordData);
    const newRecordDialog = useSelector((state) => state.system.newRecordDialog);
    const handleClose = () => {
        dispatch(closeNewRecordDialog());
    }

    return (
        <>
            {recordData?.objectName && <Modal
                open={newRecordDialog}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="m-10"
                style={{ height: "90%", overflowY: 'scroll' }}
            >
                {recordData?.objectName ? <USNewRecord recordData={recordData}/> : <div>Loading...</div>}
            </Modal>}
        </>
    )
}
