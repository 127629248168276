import axios from 'axios';

function createAxiosRequest() {
    const axiosClient = axios.create();
    axiosClient.defaults.baseURL = 'https://networks.by/api/';
    //axiosClient.defaults.baseURL = 'http://127.0.0.1:8000/api/';
    axiosClient.defaults.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    };

    //axiosClient.defaults.timeout = 3000;
    return axiosClient;
}

export function postGetFileRequest(URL, payload) {
    const axiosClient = createAxiosRequest();
    axiosClient.responseType = 'arraybuffer';
    return axiosClient.post(`${URL}`, payload).then(response => response);
}

export function getRequest(URL) {
    const axiosClient = createAxiosRequest();
    return axiosClient.get(`${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
    const axiosClient = createAxiosRequest();
    return axiosClient.post(`${URL}`, payload).then(response => response);
}

export function postFileRequest(URL, payload) {
    const axiosClient = createAxiosRequest();
    axiosClient.defaults.headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    };

    let data = new FormData();
    data.append("selectedFile", payload);
    return axiosClient.post(`${URL}`, data).then(response => response);
}

export function patchRequest(URL, payload) {
    const axiosClient = createAxiosRequest();
    return axiosClient.patch(`${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
    const axiosClient = createAxiosRequest();
    return axiosClient.delete(`${URL}`).then(response => response);
}

export function loadPage(pageId) {
    const axiosClient = createAxiosRequest();
    return axiosClient.get(`${pageId}`);
}

export function  storePage(pageId,data) {
    const axiosClient = createAxiosRequest();
    return axiosClient.patch(`${pageId}`, { data });
}


