/**
 * Company Routes
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Customers from './Customers';
import Suppliers from './Suppliers';
import USClientsManagement from '../../USCompanyComponents/Pages/USClientsManagement';
import SalesConsole from './SalesConsole';
import { AsyncUSClientsManagement, AsyncUSCompanyAccountingReport, AsyncUSCompanyAchievements, AsyncUSCompanyCalculations, AsyncUSCompanyCalculationSalary, AsyncUSCompanyClients, AsyncUSCompanyConsumers, AsyncUSCompanyEmployees, AsyncUSCompanyEvents, AsyncUSCompanyHrReport, AsyncUSCompanyLearning, AsyncUSCompanyMainPage, AsyncUSCompanyPositions, AsyncUSCompanyProducts, AsyncUSCompanyProgress, AsyncUSCompanyRequests, AsyncUSCompanySuppliers, AsyncUSCompanyTasks, AsyncUSCompanyVacancies, AsyncUSCompanyVacation } from '../../USCompanyComponents/USCompanyAsyncPageLoader/USCompnayPageLoader';


import SalesCompanyProducts from './SalesCompanyProducts';
import NewEditRecord from './ManageRecords/NewEditRecord';
import USRecordList from '../../USCommonComponents/USRecordDetails/USRecordList';
import USViewRecord from '../../USCommonComponents/USRecordDetails/USViewRecord';
import USEditRecord from '../../USCommonComponents/USRecordDetails/USEditRecord';
import EditProductParts from './PartCategory/EditProductParts';
import USManageProductPartsAssets from '../../USCompanyComponents/Pages/USManageProductPartsAssets/USManageProductPartsAssets';
import EditAssetRecord from './ManageRecords/EditAssetRecord';
import USBulkAddRelatedRecords from '../../USCommonComponents/USDetailsPage/USBulkAddRelatedRecords';
import USNewContractDialog from './USNewContractDialog/USNewContractDialog';
import USCompanyDashboard from '../../USCompanyComponents/Pages/USCompanyDashboard';
import EmployeeDetails from '../../USCompanyComponents/Pages/USCompanyEmployees/EmployeeDetails';
import USCompanyProductLoad from '../../USCompanyComponents/Pages/USCompanyProductLoad';
import USCompanyCalendar from '../../USCompanyComponents/Pages/USCompanyCalendar';
import USCallRequestList from './USCallRequest/USCallRequestList';
import USGeneralInformationSettings from '../../USCompanyComponents/Pages/USCompanySettings/USGeneralInformationSettings';
import USCompanyAddress from '../../USCompanyComponents/Pages/USCompanyAddress';
import USCatalogDetails from '../../USCompanyComponents/Pages/USCompanyStrores/USStoreCatalogs/USCatalogDetails';
import USCategoryDetails from '../../USCompanyComponents/Pages/USCompanyStrores/USStoreCategories/USCategoryDetails';
import USCompanyCities from '../../USCompanyComponents/Pages/USCompanyCities';
import USCompanyCurrencies from '../../USCompanyComponents/Pages/USCompanyCurrencies';
import USCarsPartsBrands from '../../USCompanyComponents/Pages/USCarsPartsBrands';
import USClientDetails from '../../USCompanyComponents/Pages/USCompanyClients/USClientDetails';
import USCompanyMarkup from '../../USCompanyComponents/Pages/USCompanyMarkup';
import USDiscounts from '../../USCompanyComponents/Pages/USDiscounts';
import USVauchers from '../../USCompanyComponents/Pages/USVauchers';

const Pages = (props) => {
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Sales Console</title>
                <meta name="description" content="Sales console" />
            </Helmet>
            <Switch>
                {/* <Route path={`/sales/products`} component={AsyncUSCompanyProducts} /> */}

                {/* Основные роуты */}


                <Route path='/address/:id' component={USCompanyAddress} />
                <Route path={`/employee/:id`} component={EmployeeDetails} />
                <Route path={`/employees`} component={AsyncUSCompanyEmployees} />
                <Route path="/dashboard" component={USCompanyDashboard} />
                <Route path={`/clients-management`} component={AsyncUSClientsManagement} />

                <Route path={`/loadproduct`} component={USCompanyProductLoad} />
                <Route path={`/productcatalog/:id`} component={USCatalogDetails} />
                <Route path={`/productcategory/:id`} component={USCategoryDetails} />

                <Route path={`/tasks`} component={AsyncUSCompanyTasks} />
                <Route path={`/requests`} component={AsyncUSCompanyRequests} />
                <Route path={`/events`} component={AsyncUSCompanyEvents} />
                <Route path={`/vacation`} component={AsyncUSCompanyVacation} />

                <Route path={`/accounting/calculation`} component={AsyncUSCompanyCalculations} />
                <Route path={`/accounting/salaries`} component={AsyncUSCompanyCalculationSalary} />
                <Route path={`/accounting/reports`} component={AsyncUSCompanyAccountingReport} />

                <Route path={`/learning`} component={AsyncUSCompanyLearning} />
                <Route path={`/achievements`} component={AsyncUSCompanyAchievements} />
                <Route path={`/progress`} component={AsyncUSCompanyProgress} />
                <Route path={`/calendar`} component={USCompanyCalendar} />
                <Route path="/general" component={AsyncUSCompanyMainPage}/>

                <Route exact from="/hr" component={AsyncUSCompanyHrReport} />
                <Route path={`/hr/report`} component={AsyncUSCompanyHrReport} />
                <Route path={`/hr/vacancies`} component={AsyncUSCompanyVacancies} />
                <Route path={`/hr/positions`} component={AsyncUSCompanyPositions} />

                <Route path={`/handbook/carslist`} component={USCallRequestList} />
                <Route path={`/handbook/cities`} component={USCompanyCities} />
                <Route path={`/handbook/currencies`} component={USCompanyCurrencies} />
                <Route path={`/handbook/parts-brands`} component={USCarsPartsBrands} />

                <Route path={`/contact/:id`} component={USClientDetails} />

                <Route path={`/consumer/:id`} component={AsyncUSCompanyConsumers} />
                <Route path={`/consumers`} component={AsyncUSCompanyConsumers} />
                <Route path={`/markups`} component={USCompanyMarkup} />
                <Route path={`/supplier/:id`} component={AsyncUSCompanySuppliers} />
                <Route path={`/suppliers`} component={AsyncUSCompanySuppliers} />
                <Route path={`/gifts`} component={USGeneralInformationSettings} />
                <Route path={`/discounts`} component={USDiscounts} />
                <Route path={`/vouchers`} component={USVauchers} />
                <Route path={`/clients`} component={AsyncUSCompanyClients} />
                {/* end Основные роуты */}

                <Route path={`/clients`} component={USClientsManagement} />
                <Route path={`/customers`} component={Customers} />
                <Route path={`/suppliers`} component={Suppliers} />

                <Route path={`/addproductparts/:id`} component={EditProductParts} /> {/* добавление комплектации из Assets переработать */}

                <Route path={`/manageProductParts/:id`} component={EditProductParts} />
                <Route path={`/view/productparts/:id`} component={EditAssetRecord} />
                <Route path={`/manageproductpartsassets/:id`} component={USManageProductPartsAssets} />


                <Route path="/view/:objectName?/:id" component={USViewRecord}/>
                <Route path="/edit/:objectName?/:id?" component={USEditRecord}/>
                <Route path="/sales" component={SalesConsole} />
                <Route path="/:objectName" component={USRecordList}/>
                <Route exact from="/" component={USCompanyDashboard} />
            </Switch>
            <USNewContractDialog />
        </div>
    );
}
export default Pages;
