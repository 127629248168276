import { Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Modal, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'
import USDetailsPage from '../USDetailsPage'
import { red } from '@mui/material/colors'

export default function USModal({objectName, id, isOpenModal, closeModal}) {

    return (
        <Modal
            open={isOpenModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10"
        >
            <Card>
                <CardHeader style={{ justifyContent: 'center'}}
                    action={
                        <>
                            <ButtonGroup>
                                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={closeModal} style={{ width: '100% !important' }}>Закрыть</button>
                            </ButtonGroup>
                        </>
                    }
                />
                <CardContent style={{ maxHeight: '800px', overflow: "scroll" }}>
                    <USDetailsPage objectName={objectName} id={id} actionButtons={true} />
                </CardContent>
                <CardActions style={{ justifyContent: 'center'}}>
                    <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={closeModal} style={{ width: '100% !important' }}>Закрыть</button>
                </CardActions>
            </Card>
        </Modal>
    )
}
