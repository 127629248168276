import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import SupportPage from '../../USCommonComponents/Support/Support';
import IntlMessages from 'Util/IntlMessages';
import { useSelector } from 'react-redux';

 function CompanyBlock(props) {

    const [userDropdownMenu, setDropdownMenu] = useState(false);
    const [isSupportModal, setIsSupportModal] = useState(false);
    const {user} = useSelector((state) => state?.auth?.user);
    const logoutUser = (e) => {
        e.preventDefault();
    }

    const onSubmitSupport = () => {
        setIsSupportModal(false);
        NotificationManager.success('Message has been sent successfully!');
    }

    return (
        <div className="top-sidebar">
            {user && <div className="sidebar-user-block">
                <Dropdown
                    isOpen={userDropdownMenu}
                    toggle={() => setDropdownMenu(!userDropdownMenu)}
                    className="rct-dropdown"
                >
                    <DropdownToggle
                        tag="div"
                        className="d-flex align-items-center"
                    >
                        <div className="user-profile">
                            <img
                                src={require('Assets/avatars/user-15.jpg').default}
                                alt="user profile"
                                className="img-fluid rounded-circle"
                                width="50"
                                height="100"
                            />
                        </div>
                        <div className="user-info">
                            <span className="user-name ml-4">{user? user.FirstName : ""}</span>
                            <i className="zmdi zmdi-chevron-down dropdown-icon mx-4"></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ul className="list-unstyled mb-0">
                            <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                                <p className="text-white mb-0 fs-14">{user? user.FirstName : ""}</p>
                                <span className="text-white fs-14">{user? user.email : ""}</span>
                            </li>
                            <li>
                                <Link to={`/user/details/${user? user.id : ""}`}>
                                    <i className="zmdi zmdi-account text-primary mr-3"></i>
                                    <span><IntlMessages id="widgets.profile" /></span>
                                </Link>
                            </li>
                            <li>
                                <Link to={{
                                    pathname: '/app/users/user-profile-1',
                                    state: { activeTab: 2 }
                                }}>
                                    <i className="zmdi zmdi-comment-text-alt text-success mr-3"></i>
                                    <span><IntlMessages id="widgets.messages" /></span>
                                    <Badge color="danger" className="pull-right">3</Badge>
                                </Link>
                            </li>
                            <li>
                                <Link to="/app/pages/feedback">
                                    <i className="zmdi zmdi-edit text-warning mr-3"></i>
                                    <span><IntlMessages id="sidebar.feedback" /></span>
                                    <Badge color="info" className="pull-right">1</Badge>
                                </Link>
                            </li>
                            <li className="border-top">
                                <a href="#" onClick={(e) => logoutUser(e)}>
                                    <i className="zmdi zmdi-power text-danger mr-3"></i>
                                    <span><IntlMessages id="widgets.logOut" /></span>
                                </a>
                            </li>
                        </ul>
                    </DropdownMenu>
                </Dropdown>
            </div>}
            <SupportPage
                isOpen={isSupportModal}
                onCloseSupportPage={() => setIsSupportModal(false)}
                onSubmit={() => onSubmitSupport()}
            />
        </div>
    );
 }
 export default CompanyBlock;
