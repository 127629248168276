import { Button, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";
import { useEffect, useState } from "react";
import { getFieldDetails } from "../../../../Api/AdminRequests/AdminRequestHandlers";
import USTable from "../../../USCommonComponents/USTable";

export default function USObjectFieldDetails() {

    const { fieldName, objectName } = useParams();
    const [fieldDetails, setFieldDetails] = useState();
    useEffect(() => {
        fetchData(fieldName, objectName);
    },[fieldName, objectName]);

    const fetchData = async(selectedFieldName, selectedObjectName) => {
        // const response = await getFieldDetails(selectedObjectName, selectedFieldName);
        // console.log('response', response);
        // setFieldDetails(response);
    }

    return (
        <USObjectManagerLayout objectName={objectName}>
            <USCard>
                <CardHeader
                    action={
                        <div>
                            <Button variant="outlined" size="small">Security Settings</Button>
                            <Button variant="outlined" size="small">Access</Button>
                            <Button variant="outlined" size="small">New</Button>
                        </div>
                    }
                    title="Details"
                    subheader=""
                />
                <USCardContent>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <TextField
                                id="standard-number"
                                label="API Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                            <TextField
                                id="standard-number"
                                label="Label"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                            <TextField
                                id="standard-number"
                                label="Deployment Status"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                            <TextField
                                id="standard-number"
                                label="Description"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Enable Reports</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} />
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Track Activities</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} />
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Track Field History</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} />
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Active</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} />
                            </Stack>
                        </div>
                    </div>
                    <USTable tableTitle="Picklist values" objectName={'dropdown'} requestUrl={'company/getTableList'} searchCondition={{'ObjectName': objectName, 'APIName': fieldName}} expandableRows={false} hideToolbar={false} hidePagination={false} handleNew={true}/>
                </USCardContent>
            </USCard>
        </USObjectManagerLayout>
    )
}
