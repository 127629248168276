import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getRequest } from '../../../Api/ApiService/Service';

export default function USPickListStepper({objectName, selectedValue, fieldName, handleUpdate}) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const [inputValue, setInputValue] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);

    const totalSteps = () => {
        return selectOptions.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleStep = (step) => () => {
        setCompleted({});
        const newCompleted = completed;
        for(let i = 0; i < totalSteps(); i++) {
            newCompleted[i] = false;
        }
        for(let i = 0; i < step; i++) {
            newCompleted[i] = true;
        }
        setCompleted(newCompleted);
        handleNext(step);
        handleUpdate(fieldName, selectOptions[step].Name);
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const handleNext = (actStep) => {
        setActiveStep(actStep);
    };


    const checkIfNull = (val) => {
        return val != null ? val : '';
    }

    const init = async (objName, fName) => {
        const responseCompanyLayout = await getRequest('utils/getDropdownItems/' + objName + '/' + fName);
        let queriedValues = responseCompanyLayout?.data?.data;
        setSelectOptions(queriedValues);
        setInputValue(selectedValue);
        let newCompleted = {};
        let foundedStep = false;
        for(let i = 0; i < queriedValues?.length; i++) {
            if(!foundedStep) {
                newCompleted[i] = true;
                if(selectedValue == queriedValues[i].Value) {
                    foundedStep = true;
                }
            }
        }
        setCompleted(newCompleted);
    }

    useEffect(() => {
        if(objectName && fieldName, selectedValue) {
            init(objectName, fieldName);
        }
    },[objectName, fieldName, selectedValue]);

    return (
        <>
            {selectOptions && <Stepper activeStep={activeStep} alternativeLabel >
                {selectOptions.map((label, stepIndex) => (
                    <Step key={label.Value} completed={completed[stepIndex]}>
                        <StepButton color="inherit" onClick={handleStep(stepIndex)} disabled={false}>
                            {label.Value}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>}
        </>

    )
}
