import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Chip, FormControl, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { red } from '@mui/material/colors';
import { getTableData } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import IntlMessages from 'Util/IntlMessages';
import USCallDetails from './USCallDetails';
import { checkExperationDate } from '../../../../helpers/helpers';

export default function USCallRequestList({objectName, handleOpen, handleCall}) {

    const [relatedObjects, setRelatedObjects] = useState();
    const [tableTitle, setTableTitle] = useState();
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState(null);
    const [columnsData, setColumnsData] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchData = async () => {
        const response = await getTableData('company/getTableList', 'contactrequest', pageNumber, 'created_at', sortOrder, rowsPerPage, null, null, '');
        console.log('response', response);
        setRelatedObjects(response?.relatedObjects);
        setCount(response?.count);
        setTableData(response?.tableData);
        setColumnsData(getColumnsData(response?.tableHeader));
        setTableTitle(objectName);
    }

    useEffect(() => {
        fetchData();
    },[pageNumber])

    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const handlePrevPage = () => {
        if(pageNumber > 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleChangeRecPerPage = (value) => {
        setRowsPerPage(value);
        setPageNumber(0);
    }

    function getColumnsData(responseObj) {
        let preparedColumns = [];
        for (let step = 0; step < responseObj?.length; step++) {
            if(responseObj[step]?.isAvailable) {
                let colElement = responseObj[step];
                preparedColumns.push(colElement);
            }
        }
        console.log('preparedColumns', preparedColumns);
        return preparedColumns;
    }

    const rowStatus = (cellValue) => {
        if(cellValue != null) {
            const recordDate = new Date(cellValue);
            const currentdate = new Date();
            const colorStatus = checkExperationDate(recordDate,currentdate);
            return <div><Chip label="test" color={colorStatus} /></div>
        }
        return '';
    }

    return (
        <Card>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    R
                </Avatar>
                }
                action={
                    <div>
                        <select>
                            <option>Some option </option>
                        </select>
                        <IconButton aria-label="settings">
                            <KeyboardDoubleArrowDownOutlinedIcon />
                        </IconButton>
                    </div>
                }
                title="Task List"
                subheader="Current list"
            />
            <CardContent>
                <input type="text" className="form-control" />
                <Table bordered striped size="small">
                    <TableHead>
                        <TableRow>
                            {columnsData && columnsData.map((headerItem, headerIndex) => (
                                <TableCell key={headerIndex}><IntlMessages id={headerItem.fieldLabel}/></TableCell>
                            ))}
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData && tableData.map((tableRowItem, itemRowIndex) => (
                            <TableRow hover key={itemRowIndex}>
                                {columnsData && columnsData.map((headerItem, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        <USCallDetails handleCall={handleCall} packageUrl="sales" objectName="contactrequest" fieldDetails={headerItem} fieldData={tableRowItem} relatedObjects={relatedObjects} rowIndex={itemRowIndex}/>
                                    </TableCell>
                                ))}
                                <TableCell>
                                    {rowStatus(tableRowItem['created_at'])}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
               </Table>
            </CardContent>
            <CardActions>
                <Button size="small" variant='outlined' onClick={handleNextPage}>Next</Button>
                <Button size="small" variant='outlined' onClick={handlePrevPage}>Previous</Button>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Age"
                        onChange={(e)=> handleChangeRecPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>

            </CardActions>
        </Card>
    )
}
