/**
 * Sidebar Content
 */
import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { useDispatch } from 'react-redux';
import IntlMessages from 'Util/IntlMessages';
import NavMenuItem from './NavMenuItem';
import { onToggleMenu } from '../../../reduxSettings/reduxSlices/sidebarSlice';
import { getRequest } from '../../../Api/ApiService/Service';

function SidebarContent() {
    const [NavLinks, setNavLinks] = useState(null);
    const dispatch = useDispatch();
    const toggleMenu = (menu, stateCategory) => {
        let data = {
            menu,
            stateCategory
        }
        dispatch(onToggleMenu(data));
    }

    useEffect(() => {
        fetchSidebarData();
    },[])

    const fetchSidebarData = async() => {
        const response = await getRequest('/company/getSetupSidebar');
        setNavLinks(response.data.data);
    }

    return (
        <div className="rct-sidebar-nav">
            {NavLinks && <nav className="navigation">
                { Object.keys(NavLinks).map((category, i) => (
                    <React.Fragment key={`navlink${category}`}>
                        {NavLinks[category]?.isVisible && <List
                            key={`sb_navlink_${i}`}
                            className="rct-mainMenu p-0 m-0 list-unstyled"
                            subheader={
                                <ListSubheader className="side-title" component="li">
                                    <IntlMessages id={NavLinks[category]?.messageId} />
                                </ListSubheader>
                            }
                        >
                            {(NavLinks[category].links).map((menu, key) => (
                                <React.Fragment key={`navlinkitem_${key}`}>
                                    {menu?.isVisible && <NavMenuItem
                                        key={`sb_navitemlink_${i}`}
                                        menu={menu}
                                        onToggleSidebarMenu={() => toggleMenu(menu, category)}
                                    />}
                                </React.Fragment>
                            ))}
                        </List>}
                    </React.Fragment>
                ))}
            </nav>}
        </div>
    );
 }

 export default SidebarContent;
