import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { getObjectFieldList, getObjectFieldValues, getRecordById, getRecordsForBulkCreate, getReferenceObjectFieldList, saveBulkRecords } from '../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { USCard, USCardContent, USCardHeading } from '../USCardComponent';
import IntlMessages from 'Util/IntlMessages';
import USIOSSwitch from '../USIOSSwitch';

export default function USBulkAddRelatedRecords() {
    const { id, parentObject, relatedObject } = useParams();
    const [newObjectLayout, setNewObjectLayout] = useState();
    const [selctedParentRecord, setSelectedParentRecrod] = useState();

    const [referencePicklist, setReferencePicklist] = useState([]);
    const [selectedReference, setSelectedReference] = useState('all');

    const [fieldPickList, setFieldPickList] = useState([]);
    const [selectedField, setSelectedField] = useState('all');

    const [filterValuesPickList, setFilterValuesPickList] = useState([]);
    const [selectedFieldValue, setSelectedFieldValue] = useState('all');

    const [selectedRecords, setSelectedRecords] = useState();

    const fetchInit = async () => {
        try {
            console.log('fetchInit', parentObject);
            const response = await getRecordById(parentObject, id);
            console.log('getRecordById', response.recordData);
            setSelectedParentRecrod(response.recordData);
            const responseObjectFields = await getReferenceObjectFieldList(relatedObject, parentObject);
            console.log('responseObjectFields', responseObjectFields);
            setReferencePicklist(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(id && parentObject && relatedObject) {
            fetchInit();
        }
    },[id, parentObject,relatedObject]);

    const fetchFieldList = async(referenceObject) => {
        try {
            const responseObjectFields = await getObjectFieldList(referenceObject);
            setFieldPickList(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    const handleSelectReference = (e) => {
        const reference = e.target.value;
        setSelectedReference(reference);
        fetchFieldList(reference["objectName"]);
    }

    const fetchFieldValues = async(selectedReferenceField) => {
        try {
            const responseObjectFields = await getObjectFieldValues(selectedReference["objectName"], selectedReferenceField);
            setFilterValuesPickList(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    const handleSelectField = (e) => {
        setSelectedField(e.target.value);
        fetchFieldValues(e.target.value);
        fetchRecords('all');
    }

    const handleSelectFilterField = (e) => {
        setSelectedFieldValue(e.target.value);
        fetchRecords(e.target.value);
    }

    const fetchRecords = async(filterParams) => {
        try {
            const params = {
                "parentRecordId" : id,
                "childObjectApi" : selectedReference["objectName"],
                "childReferenceFieldApi" : selectedReference["apiName"],
                "parentObjectApi" : parentObject,
                "currentObjectApi" : relatedObject,
                "searchParams" : [
                    {"fieldApiName" : selectedField, "fieldValue": filterParams}
                ]
            }
            console.log(params);
            const response = await getRecordsForBulkCreate(params);
            setNewObjectLayout(response?.visibleFields);
            console.log(response?.records);
            setSelectedRecords(response?.records);
        }catch(e) {
            console.log(e);
        }
    }

    const updateFieldValues = (e,partIndex) => {
        const modifiedEmployees = selectedRecords.map((obj, index) => {
            if (index == partIndex) {
                return { ...obj, [e.target.name]: e.target.value };
            }
            return obj;
        });
        setSelectedRecords(modifiedEmployees);
    }

    const handleSelectPart = (partIndex) => {
        selectedRecords[partIndex]['selected'] = !selectedRecords[partIndex]['selected'];
    }

    const navigate = useHistory();
    const handleSave = async () => {
        const response = await saveBulkRecords(selectedRecords, relatedObject);
        navigate.push(`/view/${parentObject}/${id}`);
    }

    const handleCancel = () => {
        navigate.push(`/view/${parentObject}/${id}`);
    }

    const changeCellValue = (newValue, rowIndex, fieldName) => {
        if(fieldName != 'selected') {
            let changedData = [...selectedRecords];
            changedData[rowIndex][fieldName] = newValue;
            setSelectedRecords(changedData);
        } else {
            let changedData = [...selectedRecords];
            changedData[rowIndex][fieldName] = newValue;
            setSelectedRecords(changedData);
        }
    }

    return (
        <div>
            <USCard>
                <USCardContent>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <div>
                            {selctedParentRecord && <Typography>{selctedParentRecord.Name}</Typography>}
                        </div>
                        <div>
                            <Button size="small" variant="outlined" className="ml-5" onClick={handleSave}>Save</Button>
                            <Button size="small" variant="outlined" className="ml-5" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </Stack>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardContent>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="PartCategory">Select Reference</InputLabel>
                                    <Select
                                        id="selectedReference"
                                        value={selectedReference}
                                        label="Select Filter Field"
                                        onChange={handleSelectReference}
                                    >
                                    <MenuItem value='all'>All References</MenuItem>
                                    {referencePicklist?.map((item, key) => (
                                        <MenuItem key={item["apiName"]} value={item}>{<IntlMessages id={item["objectName"]}/>}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="PartCategory">Select Filter Field</InputLabel>
                                    <Select
                                        id="selectedField"
                                        value={selectedField}
                                        label="Select Filter Field"
                                        onChange={handleSelectField}
                                    >
                                    <MenuItem value='all'>All Categories</MenuItem>
                                    {fieldPickList?.map((item, key) => (
                                        <MenuItem key={item.fieldName} name={item.fieldName} value={item.fieldName}>{<IntlMessages id={item.fieldLabel}/>}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            {filterValuesPickList && <FormControl fullWidth>
                                <InputLabel id="PartCategory">Selected Field Values</InputLabel>
                                    <Select
                                        id="selectedFieldValue"
                                        value={selectedFieldValue}
                                        label="Selected Field Values"
                                        onChange={handleSelectFilterField}
                                    >
                                    <MenuItem value='all'>Picklist</MenuItem>
                                    {filterValuesPickList?.map((item, key) => (
                                        <MenuItem key={item.Value} name={item.Value} value={item.Value}>{item.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                            {!filterValuesPickList && <TextField value={selectedFieldValue} onChange={handleSelectFilterField} label={selectedField}></TextField>}
                        </div>
                    </Stack>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardHeading
                    title="Related List manager"
                    customClasses="pb-0"
                />
                <USCardContent>

                    {/* tablenew*/}
                    <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {/* <th align="center">Name</th> */}
                                        {newObjectLayout && newObjectLayout?.map((fieldItem) => (
                                            <th key={fieldItem.field_label} align="center"><IntlMessages id={fieldItem.field_label}/></th>
                                        ))}
                                    <th align="center">Select</th>

                                    {/* {columns.map((headreItem, headerItemIndex) => (
                                        <th scope="col" key={`header_${headerItemIndex}`} onClick={() => updatedFieldSort(headreItem.fieldName)}><IntlMessages id={headreItem.fieldLabel}/> {sortOrderField == headreItem.fieldName && sortOrderIcon}</th>
                                    ))}
                                    <th scope="col">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {selectedRecords?.map((recItem, rowItemIndex) => (
                                    <tr  key={`field_${rowItemIndex}`}>
                                        {/* <td><Typography className="ml-10">{recItem.Name}</Typography></td> */}
                                        {recItem?.fields?.map((fieldItem) => {
                                            return <td key={`field_td_${fieldItem.field_api_name}`} align="center" >
                                                <div className="input-group">
                                                    {fieldItem.field_type == 'string' && <input type="text" className="form-control" value={recItem[fieldItem.field_api_name]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, fieldItem.field_api_name)} ></input>}
                                                    {(fieldItem.field_type == 'datetime' || fieldItem.field_type == 'date') && <input type="datetime-local" className="form-control" value={recItem[fieldItem.field_api_name]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, fieldItem.field_api_name)} ></input>}
                                                    {fieldItem.field_type == 'integer' && <input type="number" className="form-control" value={recItem[fieldItem.field_api_name]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, fieldItem.field_api_name)} ></input>}
                                                    {fieldItem.field_type == 'float' && <input type="number" className="form-control" value={recItem[fieldItem.field_api_name]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, fieldItem.field_api_name)}></input>}
                                                    {fieldItem.field_type == 'boolean' && <USIOSSwitch name={recItem.id} onChange={(e) => changeCellValue(!recItem[fieldItem.field_api_name], rowItemIndex, fieldItem.field_api_name)} checked={recItem[fieldItem.field_api_name]} />}
                                                    {fieldItem.fieldType == 'bigint' && <a onClick={() =>
                                                            navigate.push(`${currentAppUrl? '/'+currentAppUrl:''}/view/${cellItem?.referenceObject ? cellItem?.referenceObject : objectName}/${rowItem[cellItem.fieldName]}`)
                                                        }>{cellItem.fieldName != 'id' ? getRelatedObjectName(cellItem.referenceObject, rowItem[cellItem.fieldName]) : 'view'}</a>}
                                                </div>
                                            </td>

                                            // return <td key={fieldItem.field_api_name} align="center" ><Element keyItem={itemKey} fieldDetails={fieldItem} handleEdit={updateFieldValues} value={recItem[fieldItem.field_api_name]} dependedObjectValue={null} variant="standard" readOnly={false}/></td>
                                        })}
                                        <td align="center"><USIOSSwitch name={recItem.id} onChange={(e) => changeCellValue(!recItem['selected'], rowItemIndex, 'selected')} checked={recItem['selected']} /></td>
                                    </tr>
                                ))}

                                {/* {rows.map((rowItem, rowItemIndex) => (
                                    <tr key={`row_${rowItemIndex}`}>

                                        {columns.map((cellItem, cellItemIndex) => (
                                            <td key={`cell_${cellItemIndex}`} onDoubleClick={(e) => editTableCell(e, cellItem.fieldName, rowItemIndex, cellItemIndex)}>
                                                {(isEditCell.row == rowItemIndex && isEditCell.cell == cellItemIndex) ?
                                                    <div className="input-group">
                                                        {cellItem.fieldType == 'string' && <input type="text" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={handleBlur}></input>}
                                                        {(cellItem.fieldType == 'datetime' || cellItem.fieldType == 'date') && <input type="datetime-local" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={handleBlur}></input>}
                                                        {cellItem.fieldType == 'integer' && <input type="number" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={handleBlur}></input>}
                                                        {cellItem.fieldType == 'float' && <input type="number" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={handleBlur}></input>}
                                                    </div>
                                                    :
                                                    (cellItem.fieldType == 'bigint') ?
                                                        <a onClick={() =>
                                                            navigate.push(`${currentAppUrl? '/'+currentAppUrl:''}/view/${cellItem?.referenceObject ? cellItem?.referenceObject : objectName}/${rowItem[cellItem.fieldName]}`)
                                                        }>{cellItem.fieldName != 'id' ? getRelatedObjectName(cellItem.referenceObject, rowItem[cellItem.fieldName]) : 'view'}</a>
                                                        :
                                                        <div>
                                                            {(cellItem.fieldType != 'datetime' && cellItem.fieldType != 'date') && rowItem[cellItem.fieldName]}
                                                            {(cellItem.fieldType == 'datetime' || cellItem.fieldType == 'date') && butifyDateFormat(rowItem[cellItem.fieldName])}
                                                        </div>
                                                }
                                            </td>
                                        ))}
                                        <td>
                                            <ul className="action-list">
                                                <li><ModeEditOutlineOutlinedIcon onClick={() => navigate.push(`${currentAppUrl? '/'+currentAppUrl:''}/view/${objectName}/${rowItem['id']}`)}/></li>
                                                <li><DeleteForeverOutlinedIcon /></li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))} */}
                            </tbody>
                        </table>
                        {/* end tablenew*/}
                    {/* {selectedRecords && <div>
                        <Box >
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Name</TableCell>
                                        {newObjectLayout && newObjectLayout?.map((fieldItem) => (
                                            <TableCell key={fieldItem.field_label} align="center"><IntlMessages id={fieldItem.field_label}/></TableCell>
                                        ))}
                                        <TableCell align="center">Select</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {selectedRecords?.map((recItem, itemKey) => (
                                    <TableRow  key={recItem.id}>
                                        <TableCell><Typography className="ml-10">{recItem.Name}</Typography></TableCell>
                                        {recItem?.fields?.map((fieldItem) => {
                                            return <TableCell key={fieldItem.field_api_name} align="center" ><Element keyItem={itemKey} fieldDetails={fieldItem} handleEdit={updateFieldValues} value={recItem[fieldItem.field_api_name]} dependedObjectValue={null} variant="standard" readOnly={false}/></TableCell>
                                        })}
                                        <TableCell align="center"><USIOSSwitch name={recItem.id} onChange={(e) => handleSelectPart(itemKey) } checked={recItem['selected']} /></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </div>} */}
                </USCardContent>
            </USCard>
        </div>
    )
}
