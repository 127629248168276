import { getRequest } from "../ApiService/Service";

export async function getObjectTemplate(objectName) {
    try{
        const response = await getRequest(`/company/objectFieldTemplate/${objectName}`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getFieldDetails(objectName, fieldName) {
    try{
        const response = await getRequest(`/company/objectFieldTemplate/${objectName}`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function updateFieldDetails(objectName, fieldName) {
    try{
        const response = await getRequest(`/company/objectFieldTemplate/${objectName}`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function generateListView() {
    try{
        const response = await getRequest(`/company/generateListView`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}
