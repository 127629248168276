import StepsToCreate from "./components/StepsToCreate";
import { useState } from "react";
import Templates from "./components/Templates";
import TemplateSettings from "./components/TemplateSettings";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../../../Api/ApiService/Service";

export default function NewSite() {

    const [step, setStep] = useState(0);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const navigate = useHistory();
    const selectTemplate = (templateId) => {
        setSelectedTemplateId(templateId);
        console.log(step);
        setStep(step + 1);
    }

    const backStep = () => {
        setStep(step - 1);
    }

    const crateSite = async (newSiteData) => {
        try {
            const response = await postRequest('/company/edit/store',newSiteData);
            navigate.push('/setup/stores');
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <StepsToCreate step={step} />
            {step == 0 && <Templates selectTemplate={selectTemplate}/>}
            {step == 1 && selectedTemplateId && <TemplateSettings selectedTemplateId={selectedTemplateId} backStep={backStep} createSite={crateSite}/>}
        </>
    )
}
