import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { USCard, USCardContent, USCardHeading } from '../../../USCommonComponents/USCardComponent';
import { getPartCategory, getPartSubCategory } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import USIOSSwitch from '../../../USCommonComponents/USIOSSwitch';
export default function EditProductParts() {
    const { id } = useParams();
    const [partCategories, setPartCategories] = useState();
    const fetchCategory = async () => {
        try {
            const response = await getPartCategory();
            setPartCategories(response);
            console.log('response', response);
        } catch(e) {

        }
    }

    useEffect(() => {
        fetchCategory();
    },[id]);

    const [selectedPartCategory, setSelectedPartCategory] = useState('all');
    const handleChangeCategory = (e) => {
        console.log(e.target.value)
        setSelectedPartCategory(e.target.value);
    }

    const [productSubCategory, setProductSubCategory] = useState();
    const fetchProductCategory = async() => {
        try {
            const response = await getPartSubCategory(selectedPartCategory);
            setProductSubCategory(response);
            console.log('response', response);
        } catch(e) {

        }
    }

    useEffect(() => {
        fetchProductCategory();
    },[selectedPartCategory]);

    const handleSelectPart = (partIndex, itemKey) => {
        console.log(productSubCategory[partIndex]);
        productSubCategory[partIndex]['partsubcategoryitems'][itemKey]['selected'] = !productSubCategory[partIndex]['partsubcategoryitems'][itemKey]['selected'];
        console.log(productSubCategory[partIndex]);
    }

    const handleMakeDefault = (partIndex, itemKey) => {
        productSubCategory[partIndex]['partsubcategoryitems'][itemKey]['isDefault'] = !productSubCategory[partIndex]['partsubcategoryitems'][itemKey]['isDefault'];
        console.log(productSubCategory[partIndex]);
    }

    const navigate = useHistory();
    const [complectName, setComplectName] = useState();
    const handleSave = async () => {
        //const response = await saveCompanyProductParts(id, productSubCategory, complectName);
        //navigate.push(`/sales/view/productscompany/${id}`);
    }

    const handleCancel = () => {
        navigate.push(`/view/productscompany/${id}`);
    }
    return (
        <div>
            <USCard>
                <USCardContent>
                    <TextField value={complectName} label="Complect Name" onChange={(e) => setComplectName(e.target.value)}/>
                    <Button size="small" variant="outlined" className="ml-5" onClick={handleSave}>Save</Button>
                    <Button size="small" variant="outlined" className="ml-5" onClick={handleCancel}>Cancel</Button>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardHeading
                    title="Product Parts manager"
                    customClasses="pb-0"
                />
                <USCardContent>
                    {partCategories &&
                        <FormControl fullWidth>
                            <InputLabel id="PartCategory">Product Category Part</InputLabel>
                            <Select
                                    id="PartCategory"
                                    value={selectedPartCategory}
                                    label="Part Category"
                                    onChange={handleChangeCategory}
                                >
                                <MenuItem value='all'>All Categories</MenuItem>
                                {partCategories?.map((item, key) => (
                                    <MenuItem key={item.id} value={item.id}>{item.Name}</MenuItem>
                                ))}
                        </Select>
                        </FormControl>
                    }

                    {productSubCategory && <div>
                        {productSubCategory?.map((subCategoryItem, key) => (
                            <Box key={subCategoryItem.id}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {subCategoryItem.Name}
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Select</TableCell>
                                            <TableCell align="center">is Default</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Select</TableCell>
                                            <TableCell align="center">is Default</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {subCategoryItem?.partsubcategoryitems?.map((partSubCategoryItem, itemKey) => (
                                        <TableRow key={partSubCategoryItem.id}>
                                            <TableCell><Typography className="ml-10">{partSubCategoryItem.Name}</Typography></TableCell>
                                            <TableCell align="center"><USIOSSwitch name={partSubCategoryItem.id} onChange={(e) => handleSelectPart(key, itemKey) } checked={partSubCategoryItem['selected']} /></TableCell>
                                            <TableCell align="center"><USIOSSwitch name={partSubCategoryItem.id} onChange={(e) => handleMakeDefault(key, itemKey) } checked={partSubCategoryItem['isDefault']} /></TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ))}
                    </div>}
                </USCardContent>
            </USCard>
        </div>
    )
}
