/**
 * Quill Editor
 */
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean'],
    [{ 'align': [] }],
    ['code-block']
  ],
};

const formats = [
  'header',
  'font',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'align',
  'code-block'
];

function QuillEditor({handleOnchangeValue, value, fieldDetails, readOnly}) {

    const [val, setVal] = useState(value ?? "");
    useEffect(() => {
        if(val) {
            const timeoutId = setTimeout(() => {
                    handleOnchangeValue({target:{value: val, name: fieldDetails.field_api_name}});
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [val, 500]);

    return (
        <>
            {readOnly
                ? <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" readOnly value={val}></textarea>
                : <ReactQuill modules={modules} formats={formats} value={val ?? ""} defaultValue="" onChange={setVal} placeholder="Enter Your Message.." />
            }
        </>
    );
}

export default QuillEditor;
