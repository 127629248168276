import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getContactOrLeadByPhone, getSpecification } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { useDispatch } from 'react-redux';
import { openDialog, setIsSalesConsole } from '../../../../reduxSettings/reduxSlices/systemSlice';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CalculateLoan from './CalculateLoan';

export default function ContractDetails({selectedProducts, currentContact, removeProduct, editSelectedProduct, refreshToken, onselectedContact, handleUpdateProduct, handleNewContract, opportunity, resetCalculation, handleCheckErrors}) {

    const [productPrice, setProductPrice] = useState(0);
    const [calculatedProducts, setCalculatedProducts] = useState();
    const [searchInputValue, setSearchInputValue] = useState('');
    const [selectedContact, setSelectedContact] = useState(currentContact);
    const [selectedContacts, setSelectedContacts] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [openCalculateLoad, setOpenCalculateLoad] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            searchContact(searchInputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchInputValue, 500]);

    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        onselectedContact(contact);
        setSelectedContacts(null);
    }

    const searchContact = async(value) => {
        const response = await getContactOrLeadByPhone(value);
        setSelectedContacts(response);
    }

    const dispatch = useDispatch();
    const handleNewContact = () => {
        dispatch(setIsSalesConsole());
        dispatch(openDialog({objectName : 'contact', dialogTitle : 'New/Edit Contact', parentFieldName : null, parentRecordId: null}));
    }

    const handleEditContact = () => {
        dispatch(openDialog({objectName : 'contact', id : selectedContact?.id, dialogTitle : 'Информация о контакте', parentFieldName : null, parentRecordId: null}));
    }

    const handleEditOpportunity = () => {
        dispatch(openDialog({objectName : 'opportunity', id : opportunity?.id, dialogTitle : 'Информация о продаже', parentFieldName : null, parentRecordId: null}));
    }

    useEffect(() => {
        if(selectedProducts && selectedProducts?.length) {
            let totalAmount = 0;
            selectedProducts?.map((prdItem) => {
                totalAmount += calculateTotalAmount(prdItem);
            })
            setProductPrice(totalAmount);
            setCalculatedProducts(selectedProducts);
        } else {
            setProductPrice(0);
            setCalculatedProducts([]);
        }
    },[selectedProducts, refreshToken])

    const calculateTotalAmount = (detail) => {
        let partsPrice = 0;
        let complectPrice = 0;

        let characteristicsArray = detail?.characteristics[detail?.ComplectType];

        if(detail?.productParts) {
            complectPrice = calulateProductPrice(detail?.productParts, characteristicsArray);
        }

        complectPrice += detail?.SalesPrice + calculateAttribute(detail?.attributes);

        detail['CalculatedPrice'] = complectPrice;

        return complectPrice;
    }

    const calulateProductPrice = (productParts, characteristicsArray) => {
        let result = 0;
        Object.keys(productParts)?.map((itemCategory, index) => {
            productParts[itemCategory]?.map((item, index) => {
                if(characteristicsArray &&
                    (item?.id in characteristicsArray)
                ) {
                    item['isSelected'] = true;
                }
                if(item?.isSelected && item?.Price) {
                    let count = item?.Count ? item?.Count : 1;
                    result += item?.Price ? item?.Price * count : 0;
                }
            });
        });
        return result;
    }

    const calculateAttribute = (detailAttributes) => {
        let result = 0;
        if(detailAttributes) {
            detailAttributes?.map((attributesItem, index) => {
                if(attributesItem?.Price) {
                    let count = attributesItem?.Count ? attributesItem?.Count : 1;
                    result += attributesItem?.Price * count;
                }
            })
        }
        return result;
    }

    const handleSelectedProduct = (index, productItem) => {
        setSelectedIndex(index);
        editSelectedProduct(index, productItem)
    }

    const refreshData = () => {
        setProductPrice(0);
        setCalculatedProducts();
        setSearchInputValue('');
        setSelectedContact();
        setSelectedContacts();
        setSelectedIndex();
        setOpenCalculateLoad(false);
        resetCalculation();
    }

    const downloadContract = async() => {
        try {
            if(!handleCheckErrors()) {
                const response = await getSpecification({'opportunityId': opportunity?.id});
                const param1 = response?.param1;
                const param2 = response?.param2;
                const param3 = response?.param3;
                if(param1 && param2 && param3) {
                    const link = document.createElement('a');
                    link.href = `/files/donwload/${param1}/${param2}/${param3}`;
                    link.target = '_blank';
                    link.download = param3;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } catch (e) {
            setErrorMessages('Error message: '.e);
            console.log('Error message: '.e)
        }
    }

    return (
        <Card>
            <CardContent>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={refreshData} style={{ width: '100% !important' }}>Сбросить</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={handleNewContact} style={{ width: '100% !important' }}>Новый Контакт</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={handleNewContract} style={{ width: '100% !important' }}>Договор</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={downloadContract} style={{ width: '100% !important' }}>Скачать спецификацию</button>
                <div className="content d-flex flex-column justify-content-center align-items-center">
                    <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => setSearchInputValue(e.target.value)}/>
                    {selectedContacts && <ul className="list-group">
                        {selectedContacts?.map((contactItem, contactIndex) => (
                            <li className="list-group-item" key={`contactItem_${contactIndex}`} onClick={() => handleSelectContact(contactItem)}>{contactItem?.Name}</li>
                        ))}
                    </ul>}
                </div>
                {selectedContact && <div className="rct-link-btn pb-5" onClick={handleEditContact}><b>Контакт:</b> {selectedContact?.Name}</div>}
                {opportunity && <div className="rct-link-btn pb-5" onClick={handleEditOpportunity}><b>Продажа:</b> {opportunity?.Name}</div>}
                <div>
                    Итоговая стоимость: {productPrice}
                    <div className="rct-link-btn pb-5" onClick={() => setOpenCalculateLoad(true)}>Рассчитать в кредит </div>
                </div>

                {calculatedProducts && <div className="panel-body table-responsive">
                    <table className="table table-bordered  table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th>Price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {calculatedProducts?.map((productItem, index) => {
                                return <tr key={`products_${index}`} className={`${selectedIndex == index ? 'bg-info': ''}`}>
                                    <td onClick={() => handleSelectedProduct(index, productItem)} >
                                        {productItem.Name}
                                    </td>
                                    <td>
                                        {productItem?.CalculatedPrice}
                                    </td>
                                    <td>
                                        <DeleteForeverOutlinedIcon onClick={() => removeProduct(index)}/>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleUpdateProduct} style={{ width: '100% !important' }}>Сохранить Изменения</button>
            </CardContent>
            {openCalculateLoad && <CalculateLoan isOpen={openCalculateLoad} contractPrice={productPrice} handleClose={() => setOpenCalculateLoad(false)} />}
        </Card>
    )
}
