import { Avatar, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useState } from 'react'

export default function USCreateTab() {
    const [steps, setSteps] = useState();
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                    </Avatar>
                }
                action={
                    <div>

                    </div>
                }
                title="New Custom Object Tab"
                subheader="tab"
            />
            <CardContent>
                content
            </CardContent>
        </Card>
    )
}
