import { useEffect, useState } from 'react';
import { getContractDocument } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
export default function USCheckContract({id, contactPasport, issuedDate, handleBack, handleFinish}) {
    const [selectedDocs, setSelectedDocs] = useState(null);

	useEffect(() => {
		initData(id);
	}, [id]);

    const initData = async (recId) => {
        const response = await getContractDocument({'contractName': 'contrakt3.docx', 'contactPasport': contactPasport, 'issuedDate': issuedDate, 'opportunityId': recId});
        setSelectedDocs(response);
    }

	return (

        <Card>

            <CardHeader
                title="Информация о договоре"
                subheader="Внимательно проверьте договор"
            />
            <CardContent>
                {selectedDocs && <iframe src={selectedDocs} style={{width:'100%', height:'500px'}} ></iframe>}
            </CardContent>
            <CardActions style={{ justifyContent: 'center'}}>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleFinish} style={{ width: '100% !important' }}>Сохранить</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleBack} style={{ width: '100% !important' }}>Вернуться к просчету</button>
            </CardActions>
        </Card>
    )
}
