/**
* Main App
*/
import React from 'react';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { store } from './reduxSettings/store';
import USThemeProvider from './container/themes/USThemeProvider';
import './lib/reactifyCss';
import { NotificationContainer } from 'react-notifications';
import USNewRecordDialog from './components/USCommonComponents/USNewRecordDialog';
import USNewRecDialog from './components/USCompanyComponents/Pages/USClientsManagement/USCMDialog/USNewRecDialog';

const MainApp = () => {
    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <USThemeProvider>
                    <USNewRecordDialog />
                    <USNewRecDialog />
                    <NotificationContainer/>
                </USThemeProvider>
            </MuiPickersUtilsProvider>
        </Provider>
    )
}

export default MainApp;
