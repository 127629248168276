import React from 'react'
import { Typography } from "@mui/material"

export default function EventInfo({ event }) {
    return (
        <>
          <Typography>{event.description}</Typography>
        </>
    )
}
