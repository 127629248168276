import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import IntlMessages from 'Util/IntlMessages';
import { getRequest } from '../../../../Api/ApiService/Service';


function USPickList({fieldDetails, objectName, value, handleChange, variant, readOnly, isRequired}) {

    const [inputValue, setInputValue] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);

    const checkIfNull = (val) => {
        return val ? val : '';
    }

    useEffect(() => {
        const init = async () => {
            const fieldApiName = fieldDetails.field_api_name;
            const responseCompanyLayout = await getRequest('utils/getDropdownItems/' + objectName + '/' + fieldApiName);
            setInputValue(checkIfNull(value));
            setSelectOptions(responseCompanyLayout?.data?.data || [{name:fieldDetails?.field_api_name, value: null}]);
        }
        init();
    },[fieldDetails]);

    return (
        <>
            {selectOptions &&
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label"><IntlMessages id={fieldDetails?.field_label} /></InputLabel>
                    <Select
                        required={isRequired}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name={fieldDetails?.field_api_name}
                        value={checkIfNull(inputValue)}
                        disabled={readOnly}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                            handleChange({ target: {value: event.target.value, name: fieldDetails?.field_api_name}});
                        }}
                    >
                        {selectOptions.map((option, index) => (
                            <MenuItem key={index} value={option.Name ? option.Name : ''}>{option.Value ? option.Value : ''}</MenuItem>
                        ))}
                    </Select>
            </FormControl>}

                {/* <Autocomplete
                    id="free-solo-demo"
                    value={checkIfNull(inputValue)}
                    name={fieldDetails?.field_api_name}
                    onChange={(event, newValue) => {
                        console.log('event.target' , event.currentTarget);
                        setInputValue(newValue);
                        handleChange({ target: {value: newValue, name: fieldDetails?.field_api_name}});
                    }}
                    disabled={readOnly}
                    options={selectOptions.map((option) => option.Value)}
                    getOptionLabel={selectOptions.map((option) => option.Name)}
                    renderInput={
                        (params) => <TextField
                        name={fieldDetails?.field_api_name}
                        {...params}
                        label={<IntlMessages id={fieldDetails?.field_label}
                        inputProps={{
                            readOnly: (fieldDetails?.field_access == 'edit' || !readOnly ? false : true),
                            ...params.InputProps,
                        }}
                        />} variant={variant}/>
                    }
                /> */}

        </>
    )
}

export default USPickList;
