import React, { useEffect, useState } from 'react'
import Divider from '@mui/material/Divider';
import { Card, CardContent, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';
import USAlertDialog from '../../USAlertDialog/USAlertDialog';

export default function ContractConstuctor({detail, handleUpdateProduct, handleAddAction, refreshToken, handleUpdateType, handleRefreshData}) {
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [attributes, setAttributes] = useState(null);
    const [characteristics, setCharacteristics] = useState(null);
    //const [additionalItems, setAdditionalItems] = useState(null);
    const [selectedComplectName, setSelectedComplectName] = useState();
    const [selectedComplect, setSelectedComplect] = useState(null);
    const [productParts, setProductParts] = useState(null);
    const [productPrice, setProductPrice] = useState(0);

    useEffect(() => {
        if(detail) {
            console.log('detail', detail);
            let partsPrice = 0;
            let characteristicsArray = detail?.characteristics[detail?.ComplectType];

            setSelectedComplectName(detail?.ComplectType);
            setSelectedComplect(characteristicsArray);

            if(detail?.productParts) {
                partsPrice = calulateProductPrice(detail?.productParts, characteristicsArray);
            }

            partsPrice += calculateAttribute(detail?.attributes);

            setProductPrice(partsPrice);
            setAttributes(detail?.attributes);
            setCharacteristics(detail?.characteristics);
            setProductParts(detail?.productParts);
        }
    }, [detail, refreshToken]);

    const calulateProductPrice = (productParts, characteristicsArray) => {
        let result = 0;
        Object.keys(productParts)?.map((itemCategory, index) => {
            productParts[itemCategory]?.map((item, index) => {
                if(characteristicsArray &&
                    (item?.id in characteristicsArray)
                ) {
                    item['isSelected'] = true;
                } else {
                    item['isSelected'] = false;
                }
                if(item?.isSelected && item?.Price) {
                    let count = item?.Count ? item?.Count : 1;
                    result += item?.Price ? item?.Price * count : 0;
                }

            });
        });
        result+= detail?.SalesPrice
        return result;
    }

    const calculateAttribute = (detailAttributes) => {
        let result = 0;
        if(detailAttributes) {
            detailAttributes?.map((attributesItem, index) => {
                if(attributesItem?.Price) {
                    let count = attributesItem?.Count ? attributesItem?.Count : 1;
                    result += attributesItem?.Price * count;
                }
            })
        }
        return result;
    }

    const handleCloseDialog = () => {
        setChangedComplect(null);
        setOpenAlertDialog(false);
    }

    const [changedComplect, setChangedComplect] = useState();
    const handleChangeComplect = (selectedValue) => {
        setChangedComplect(selectedValue);
        setOpenAlertDialog(true);
    }

    const selectComplect = (selectedValue, index) => {
        setSelectedComplectName(selectedValue);
        setSelectedComplect(characteristics[selectedValue])
        let complectPrice = 0;
        Object.keys(characteristics)?.map((chItem) => {
            if(chItem != selectedValue) {
                characteristics[chItem]['isSelected'] = false;
            } else {
                characteristics[selectedValue]['isSelected'] = true;
            }
        })

        let clearAdditionalItems = productParts;
        let characteristicsArray = characteristics[selectedValue];

        complectPrice = calulateProductPrice(clearAdditionalItems, characteristicsArray);
        complectPrice += calculateAttribute(detail?.attributes);

        setProductParts(clearAdditionalItems);
        setProductPrice(complectPrice);
        handleUpdateType(selectedValue);
        setChangedComplect(null);
        setOpenAlertDialog(false);
        handleRefreshData(new Date().valueOf());
    }

    const selectAdditionalItem = (aiId, sectionIndex) => {
        console.log('aiId', aiId);
        console.log('sectionIndex', sectionIndex);

        productParts[sectionIndex][aiId]['isSelected'] = !productParts[sectionIndex][aiId].isSelected;
        console.log('sectionIndex', productParts[sectionIndex][aiId]);
        console.log('sectionIndex', productParts[sectionIndex][aiId].isSelected);
        console.log('sectionIndex', productParts[sectionIndex][aiId]);
        let changedProductPrice = productPrice;
        if(productParts[sectionIndex][aiId].isSelected) {
            let count = productParts[sectionIndex][aiId]?.Count;
            changedProductPrice += productParts[sectionIndex][aiId].Price * count;
        } else {
            let count = productParts[sectionIndex][aiId]?.Count;
            changedProductPrice -= productParts[sectionIndex][aiId].Price * count;
        }
        setProductPrice(changedProductPrice);
        handleRefreshData(new Date().valueOf());
    }

    const changeCount = (count, aiId, sectionIndex) => {
        if(productParts[sectionIndex][aiId].isSelected) {
            let changedProductPrice = productPrice;
            let previousCount = productParts[sectionIndex][aiId]?.Count;
            changedProductPrice = changedProductPrice - productParts[sectionIndex][aiId].Price * previousCount;
            productParts[sectionIndex][aiId]['Count'] = count;
            changedProductPrice = changedProductPrice + productParts[sectionIndex][aiId].Price * count;
            setProductPrice(changedProductPrice);
            handleRefreshData(new Date().valueOf());
        }
    }
    const saveProduct = () => {
        handleUpdateProduct(detail);
    }

    return (
        <>
            <Card>
                {detail && <CardContent>
                    {detail.id && <div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <Typography variant='h5'>
                                    {detail?.Name}
                                </Typography>
                            </div>
                            <div className='col-sm-12 col-md-2 col-lg-2'>
                                <Typography>
                                    <b>{<IntlMessages id="common.price" />}: </b>{productPrice}
                                </Typography>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                {handleUpdateProduct && <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={saveProduct} style={{ width: '100% !important' }}>Добавить в просчет</button>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                {characteristics && <RadioGroup row>
                                    <div className='row'>
                                        {Object.keys(characteristics)?.map((value, key) => (
                                            <div className='col-sm-12 col-md-4 col-lg-4' key={`radio_${value}`}>
                                                <FormControlLabel control={<Radio checked={value == selectedComplectName} onChange={() => handleChangeComplect(value)} />} label={value} />
                                            </div>
                                        ))}
                                    </div>
                                </RadioGroup>}
                            </div>
                        </div>
                        <Divider textAlign="center" style={{ marginTop: '5px', marginBottom:'5px'}}>Комплектация</Divider>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                                {detail && <table className="table-bordered" style={{ width: '100%'}}>
                                    <tbody>
                                            <tr>
                                                <td >
                                                    {<IntlMessages id="common.height" />}
                                                </td>
                                                <td style={{ minWidth: '20px' }}>
                                                    {detail?.Height}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td >
                                                    {<IntlMessages id="common.lenght" />}
                                                </td>
                                                <td style={{ minWidth: '20px' }}>
                                                    {detail?.Length}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td >
                                                    {<IntlMessages id="common.width" />}
                                                </td>
                                                <td style={{ minWidth: '20px' }}>
                                                    {detail?.Width}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {<IntlMessages id="common.material" />}
                                                </td>
                                                <td style={{ minWidth: '20px' }}>
                                                    {detail?.Material}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {<IntlMessages id="common.Thickness" />}
                                                </td>
                                                <td style={{ minWidth: '20px' }}>
                                                    {detail?.Thickness}
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>}
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 d-block'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <table className="table-bordered" style={{ width: '100%'}}>
                                            <tbody>
                                                {attributes && attributes?.map((item, key) => (
                                                    <tr key={`attributes_${key}`}>
                                                        <td key={key}>
                                                            {item.Name}
                                                        </td>
                                                        <td style={{ minWidth: '20px' }}>
                                                            {item.Count}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {productParts && <Divider textAlign="center" style={{marginTop: '20px' }}><Typography variant='h6'>Дополнительная комплектация</Typography></Divider>}
                    {productParts && <div className="form-check">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                {Object.keys(productParts).map((sectionName, sectionIndex) => (
                                    <div className="row" key={sectionName+':'+sectionIndex}>
                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{ paddingTop: '0px', backgroundColor: '#72d1e7' }}>
                                            {sectionName}
                                        </div>
                                        {productParts[sectionName]?.map((value, key) => (
                                            <div className="col-sm-12 col-md-6 col-lg-6 pt-10 pb-5" key={`checkbox_${value.id}`} data-id={value.id} style={{ paddingTop: '0px' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{width:'90%'}}>
                                                            <input className="form-check-input" style={{width:'25px', height:'25px'}} type="checkbox" value="" id={`checkbox_${value.id}`} checked={value?.isSelected ? true : false} onChange={() => selectAdditionalItem(key, sectionName)} />
                                                            <label className="form-check-label mt-5 ml-20" htmlFor={`checkbox_${value.id}`}>
                                                                {value.Name}
                                                            </label>
                                                        </td>
                                                        <td style={{width:'10%'}}>
                                                            {value?.isSelected && <input className="mt-10" style={{maxWidth:'25px', maxHeight:'25px'}} type="text" value={value?.Count} id={`count_${value.id}`} onChange={(e) => changeCount(e.target.value, key, sectionName)} />}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                    </CardContent>
                }
            </Card>
            <USAlertDialog isOpen={openAlertDialog} closeDialog={handleCloseDialog} dialogTitle="Вы уверены, что хотите изменить комплектацию?" dialogDesctiption="При смене комплектации, все дополнительный настройки будут сброшены до станандартных!" handleAction={selectComplect} valueToApprove={changedComplect}/>
        </>
    )
}
