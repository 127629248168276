/**
 * App Routes
 */
 import React, { useState, useEffect, useLayoutEffect } from 'react';
 import { useSelector, useDispatch } from 'react-redux';
 import Sidebar from 'react-sidebar';
 import { Scrollbars } from 'react-custom-scrollbars-2';
 import classnames from 'classnames';

 // Components
 import Header from 'Components/USCommonComponents/USHeader';
 import SidebarContent from '../../CompanySidebar';
 import ThemeOptions from 'Components/USCommonComponents/USUtils/ThemeOptions/ThemeOptions';

 // preload Components
 import PreloadHeader from 'Components/USCommonComponents/USPreloadLayout/PreloadHeader';
 import PreloadSidebar from 'Components/USCommonComponents/USPreloadLayout/PreloadSidebar';
 // app config
 import AppConfig from 'Constants/AppConfig';

 import { setStartTour, collapsedSidebarAction } from '../../../../reduxSettings/reduxSlices/basicSlice';
import { useHistory } from 'react-router-dom';

 function USContainerCompanyLayout({ children }) {

    const dispatch = useDispatch();
    const location = useHistory();
    const navCollapsed = useSelector((state) => state.basic.navCollapsed);
    const rtlLayout = useSelector((state) => state.basic.rtlLayout);
    const miniSidebar = useSelector((state) => state.basic.miniSidebar);

    const [loadingHeader, setLoadingHeader] = useState(true);
    const [loadingSidebar, setLoadingSidebar] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

     useLayoutEffect(() => {
        function updateSize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            if (windowWidth <= 1199) {
                dispatch(collapsedSidebarAction(false));
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

     useEffect(() => {
        window.scrollTo(0, 0);
         if (AppConfig.enableUserTour && windowWidth > 600) {
             setTimeout(() => {
                dispatch(setStartTour(true));
             }, 2000);
         }
         setTimeout(() => {
            setLoadingHeader(false);
            setLoadingSidebar(false);
         }, 114);
     },[]);

    function renderPage() {
         const pathname = '';
         if (pathname === '/chat' || pathname.startsWith('/mail') || pathname === '/todo') {
             return (
                 <div className="rct-page-content p-0">
                     {children}
                 </div>
             );
         }
         return (
             <Scrollbars
                 className="rct-scroll"
                 autoHide
                 autoHideDuration={100}
                 style={getScrollBarStyle()}
             >
                 <div className="rct-page-content">
                     {children}
                 </div>
             </Scrollbars>
         );
     }

     // render header
    function renderHeader() {
         if (loadingHeader) {
             return <PreloadHeader />;
         }
         return <Header />
    }

     //render Sidebar
    function renderSidebar() {
         if (loadingSidebar) {
             return <PreloadSidebar />;
         }
         return <SidebarContent />
    }

     //Scrollbar height
    function getScrollBarStyle() {
         return {
             height: 'calc(100vh - 50px)'
         }
    }
    
    return (
        <div className="app">
            <div className="app-main-container crm-layout">
                <Sidebar
                    sidebar={renderSidebar()}
                    open={windowWidth <= 1199 ? navCollapsed : false}
                    docked={windowWidth > 1199 ? !navCollapsed : false}
                    pullRight={rtlLayout}
                    onSetOpen={() => dispatch(collapsedSidebarAction(false))}
                    styles={{ content: { overflowY: '' } }}
                    contentClassName={classnames({ 'app-conrainer-wrapper': miniSidebar })}
                >
                    <div className="app-container">
                        <div className="rct-app-content">
                            <div className="app-header">
                                {renderHeader()}
                            </div>
                            <div className="rct-page">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </Sidebar>
                <ThemeOptions />
            </div>
        </div>
    );
 }

 export default USContainerCompanyLayout;
