import { Button, CardHeader, Stack, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import { useDispatch } from "react-redux";
import USObjectLayoutEdit from "./USObjectLayoutEdit";

export default function USObjectLayoutDetails() {
    const dispatch = useDispatch();
    const { layoutName, objectName } = useParams();
    const [selectedLayout, setSelecetdLayout] = useState(null);
    const [isHeaderLayout, setIsHeaderLayout] = useState(null);

    useEffect(() => {
        fetchLayoutDetails();
    },[layoutName, objectName])

    const fetchLayoutDetails = async() => {
        try {
            const response = await getRequest('company/layout/' + objectName +'/' + layoutName);
            console.log(response.data.data.layout);
            const responseData = response.data.data.layout;
            if(responseData.hasOwnProperty("sections")) {
                setIsHeaderLayout(false);
            } else {
                setIsHeaderLayout(true);
            }
            setSelecetdLayout(response.data.data.layout);
        } catch (error) {
            console.log('fetch layout details error' ,error)
        }
    }

    const handleUpdateTableHeaderFields = (index, valueName, value) => {
        const updatedLayoutValues = JSON.parse(JSON.stringify(selectedLayout));
        updatedLayoutValues[index][valueName] = value;
        setSelecetdLayout(updatedLayoutValues);
    }

    const saveLayout = async () => {
        try {
            const response = await postRequest('company/layout/' + objectName +'/' + layoutName , selectedLayout);
            dispatch(addNotification({type: "info", message: "Layout Updated"}));
        } catch(error) {
            console.log('saveLayout', error);
        }
    }

    const sortHeaders = (index, currentIndex, value) => {
        const relatedListsValues = JSON.parse(JSON.stringify(selectedLayout));
        relatedListsValues.splice(index, 0, value);
        if(index <= currentIndex) {
            relatedListsValues.splice(currentIndex + 1, 1);
        } else {
            relatedListsValues.splice(currentIndex, 1);
        }
        setSelecetdLayout(relatedListsValues);
    }

    return (
        <USObjectManagerLayout objectName={objectName}>
            {!isHeaderLayout && <USObjectLayoutEdit />}
            {isHeaderLayout && <USCard>
                    <CardHeader
                        action={
                            <div style={{ justifyContent:"space-between" }}>
                                <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                            </div>
                        }
                        title="Layout"
                        subheader="Field Layout"
                    />
                    <USCardContent>
                        <div className="bg-primary text-white">
                            Table Columns Layout
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="table-responsive">
                                    <div className="unseen">
                                        <Table size="small">
                                            <thead>
                                                <tr className="bg-primary text-white">
                                                    <th>Field Label</th>
                                                    <th>Field Name</th>
                                                    <th>Field Type</th>
                                                    <th>Reference Object</th>
                                                    <th>Is Active</th>
                                                    <th>Order</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {selectedLayout && selectedLayout.map((headerLayoutItem, headerItemKey) => (
                                                <TableRow key={headerItemKey}>
                                                    <TableCell>
                                                        <Typography>{headerLayoutItem.fieldLabel}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{headerLayoutItem.fieldName}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{headerLayoutItem.fieldType}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{headerLayoutItem.referenceObject}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <USIOSSwitch name="isActive" onChange={(e) => handleUpdateTableHeaderFields(headerItemKey, "isAvailable", !headerLayoutItem.isAvailable) } checked={headerLayoutItem.isAvailable} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-number"
                                                            label="Order"
                                                            name=""
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="standard"
                                                            value={headerItemKey}
                                                            onChange={(e) => sortHeaders(e.target.value, headerItemKey, headerLayoutItem)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </USCardContent>
                </USCard>}
        </USObjectManagerLayout>
    )
}
