import React, { useEffect, useRef, useState } from 'react'
import USDetailsContent from '../../../USCommonComponents/USDetailsPage/USDetailsContent';
import { useDispatch } from 'react-redux';
import { getRequest, postRequest } from '../../../../Api/ApiService/Service';
import { addNotification } from '../../../../reduxSettings/reduxSlices/NotificationSlice';
import { Box, Card, CardActions, CardContent, CardHeader, TextField } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';
import USDate from '../../../USCommonComponents/USService/Inputs/USDate';
import USTableContractDetails from '../../../USCommonComponents/USSalesConsole/CalculateContract/USTableContractDetails';
import { getContractDocument } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { useIntl } from 'react-intl';

export default function USContactStep({id, handleUpdate, handleBack, handleNext, handleUpdatePassport}) {
    const intl = useIntl();
    const childRef = useRef();
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if(id) {
            initData();
        }
    }, [id]);

    const [oppContact, setOppContact] = useState(null);
    const [contactFields, setContactFields] = useState(null);

    const [addressFields, setAddressFields] = useState();
    const [oppContactAddress, setOppContactAddress] = useState(null);
    const [deliveryAddressFields, setDeliveryAddressFields] = useState();

    const [oppContract, setOppContract] = useState(null);
    const [contractFields, setContractFields] = useState(null);

    const [isSaved, setIsSaved] = useState(true);

    const [opportunityProducts, setOpportunityProducts] = useState();
    const initData = async () => {
        try {
            const currentRecord = await getRequest('/company/sales/getContractDetails/' + id);
            console.log('currentRecord', currentRecord);
            const selectedData = currentRecord.data.data;
            if(selectedData?.contact) {
                if(selectedData?.contact?.recordData) {
                    setContactFields(selectedData?.contact?.recordData);
                    setOppContact(selectedData?.contact?.layout?.sections);
                }
            }
            if(selectedData?.address) {
                setAddressFields(selectedData?.address?.recordData);
                setOppContactAddress(selectedData?.address?.layout?.sections);

            }
            if(selectedData?.deliveryAddress) {
                setDeliveryAddressFields(selectedData?.deliveryAddress?.recordData);
            }
            if(selectedData?.contract) {
                setContractFields(selectedData?.contract?.recordData);
                setOppContract(selectedData?.contract?.layout?.sections);
            }
            if(selectedData?.opportunityProducts) {
                setOpportunityProducts(selectedData?.opportunityProducts);
            }
        } catch (error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const updateContact = async(recToSave) => {
        setIsSaved(false);
        setContactFields(recToSave);
    }

    const updateDefaultAddress = (rec) => {
        setIsSaved(false);
        rec.Type = 'default';
        setAddressFields(rec);
    }

    const updateDeliveryAddress = (rec) => {
        setIsSaved(false);
        rec.Type = 'delivery';
        setDeliveryAddressFields(rec);
    }

    const updateContract = (rec) => {
        setIsSaved(false);
        setContractFields(rec);
    }

    const [pasportNumber, setPasportNumber] = useState();
    const handleChangePassport = (val) => {
        
        setPasportNumber(val);
        handleUpdatePassport({
            'contactPasport' : val,
            'issuedDate':issuedPasport,
            'issuedBy': issuedBy
        });
    }
    const [issuedPasport, setIssuedPasport] = useState(null);
    const handleChangeIssued = (val) => {
        setIssuedPasport(val.target.value);
        handleUpdatePassport({
            'contactPasport' : pasportNumber,
            'issuedDate':val.target.value,
            'issuedBy': issuedBy
        });
    }

    const [issuedBy, setIssuedBy] = useState(null);
    const handleChangeIssuedBy = (val) => {
        setIssuedBy(val);
        handleUpdatePassport({
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport,
            'issuedBy': val
        });
    }

    const handleSaveDetails = () => {
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': deliveryAddressFields,
            'address': addressFields,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport
        });
        setIsSaved(true);
    }

    const [errorMessages, setErrorMessages] = useState();

    const validateFields = (ref, ref1, ref2, ref3) => {
        let cehckErrorMessages = [];
        if(!pasportNumber) {
            cehckErrorMessages.push(intl.formatMessage({ id:'users.identityPasportNumber'}));
        }

        if(!issuedPasport) {
            cehckErrorMessages.push(intl.formatMessage({ id:'users.dateOfIssue'}));
        }

        if(!issuedBy) {
            cehckErrorMessages.push(intl.formatMessage({ id:'users.passportMinistryOf'}));
        }


        cehckErrorMessages = cehckErrorMessages?.concat(ref?.current?.validateFields());
        cehckErrorMessages = cehckErrorMessages?.concat(ref1?.current?.validateFields());
        cehckErrorMessages = cehckErrorMessages?.concat(ref2?.current?.validateFields());
        cehckErrorMessages = cehckErrorMessages?.concat(ref3?.current?.validateFields());
        if (cehckErrorMessages === undefined || cehckErrorMessages.length == 0) {
            if(!isSaved) {
                cehckErrorMessages.push('Сохраните изменения');
                setErrorMessages(cehckErrorMessages);
                return;
            }
            setErrorMessages(null);
            downloadContract();
        } else {
            console.log(cehckErrorMessages);
            setErrorMessages(cehckErrorMessages);
            return;
            //dispatch(addNotification({"type" : "error", "title" : 'Заполните обязательные поля ', "message" : JSON.stringify(cehckErrorMessages)}));
        }
    }

    const downloadContract = async() => {
        try {
            const response = await getContractDocument({'contractName': 'contrakt3.docx', 'contactPasport': pasportNumber, 'issuedDate': issuedPasport, 'opportunityId': id, 'issuedBy': issuedBy});
            const param1 = response?.param1;
            const param2 = response?.param2;
            const param3 = response?.param3;
            console.log(param1 && param2 && param3);
            if(param1 && param2 && param3) {
                const link = document.createElement('a');
                link.href = `/files/donwload/${param1}/${param2}/${param3}`;
                link.target = '_blank';
                link.download = param3;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (e) {
            setErrorMessages('Error message: '.e);
            console.log('Error message: '.e)
        }
    }

    return (
        <Card>
            <CardHeader
                action={
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleBack} style={{ width: '100% !important' }}>Назад</button>
                        <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={handleSaveDetails} style={{ width: '100% !important' }}>Сохранить</button>
                        <button type="button" className="btn btn-outline-primary btn-sm mb-5 ml-5" onClick={() => validateFields(childRef, childRef1, childRef2, childRef3)} style={{ width: '100% !important' }}>Скачать договор</button>
                    </div>
                }
                title="Информация о договоре"
                subheader="Заполните все необходимые поля"
            />
            <CardContent>
                {errorMessages && <span style={{maxWidth:'80%', color:"#ffffff", backgroundColor:'#ff0000'}}>Заполните обязательные поля: <br/> <p style={{maxWidth:'80%', backgroundColor:'#ff0000'}}>{JSON.stringify(errorMessages)}</p></span>}
                {contactFields && <USDetailsContent ref={childRef} sections={oppContact} objectName={'contact'} objectFields={contactFields} handleUpdateRecord={updateContact} readRecord={false} sectionTitle='Информация о контакте'/>}
                <div className="row p-10">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Box style={{width: '100%', height:'25px', backgroundColor: '#e2eaf2'}} >
                            Паспортные данные
                        </Box>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                <TextField
                                    id="pasport"
                                    name="pasport"
                                    required
                                    label={<IntlMessages id={'passport'} />}
                                    onChange={(e) => handleChangePassport(e.target.value)}
                                    value={pasportNumber}
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    helperText={errorMessages?.pasportNumber}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                <USDate fieldDetails={{ 'field_api_name': 'Issued_Date', 'field_access': 'edit', field_label : 'IssuedDate'}} handleChange={handleChangeIssued} value={issuedPasport} variant="standard" readOnly={false} helperText={errorMessages?.issuedPasport} isRequied={true}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ display:'block' }}>
                                <TextField
                                    required
                                    id="issuedBy"
                                    name="issuedBy"
                                    label="Кем выдан"
                                    onChange={(e) => handleChangeIssuedBy(e.target.value)}
                                    value={issuedBy}
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    helperText={errorMessages?.issuedBy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {oppContactAddress && <USDetailsContent ref={childRef1} sections={oppContactAddress} objectName={'address'} objectFields={addressFields} handleUpdateRecord={updateDefaultAddress} readRecord={false} sectionTitle='Адрес регистрации'/>}
                {oppContactAddress && <USDetailsContent ref={childRef2} sections={oppContactAddress} objectName={'address'} objectFields={deliveryAddressFields} handleUpdateRecord={updateDeliveryAddress} readRecord={false} sectionTitle='Адрес доставки'/>}
                {oppContract && <USDetailsContent ref={childRef3} sections={oppContract} objectName={'contract'} objectFields={contractFields} handleUpdateRecord={updateContract} readRecord={false} sectionTitle='Договор'/>}
                {opportunityProducts &&
                    <USTableContractDetails opportunityProducts={opportunityProducts} />
                }
            </CardContent>
            <CardActions style={{ justifyContent: 'center'}}>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleSaveDetails} style={{ width: '100% !important' }}>Сохранить</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={handleBack} style={{ width: '100% !important' }}>Назад</button>
                <button type="button" className="btn btn-outline-primary btn-sm mb-5" onClick={validateFields} style={{ width: '100% !important' }}>Скачать договор</button>
            </CardActions>
        </Card>
    )
}
