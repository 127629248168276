import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { useParams } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from "@mui/material";
import USDndRow from "./USLayoutUtils/USDndRow";
import USDndNotSelected from "./USLayoutUtils/USDndNotSelected";
import { CardFooter } from "reactstrap";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";


export default function USObjectLayoutEdit() {
    const { layoutName, objectName } = useParams();
    const [selectedLayout, setSelectedLayout] = useState();
    const [sections, setSections] = useState();
    const [notSelected, setNotSelected] = useState();
    const [relatedLists, setRelatedLists] = useState();
    const dispatch = useDispatch();
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const copySections = [...sections];

        if(destination.droppableId == 'notSelected' || source.droppableId == 'notSelected') {
            let notSelectedCopy = [];
            if(notSelected) {
                notSelectedCopy = [...notSelected];
            }

            if(destination.droppableId == 'notSelected') {
                const fieldsSectionArea = source.droppableId.split('_');
                let editSections = copySections[fieldsSectionArea[1]];
                let fieldsList = editSections[fieldsSectionArea[0]];
                const draggedField = fieldsList[source.index];
                fieldsList.splice(source.index, 1);
                notSelectedCopy.push(draggedField);
                setNotSelected(notSelectedCopy);
            } else {
                const fieldsSectionArea = destination.droppableId.split('_');
                let editSections = copySections[fieldsSectionArea[1]];
                let fieldsList = editSections[fieldsSectionArea[0]];
                const draggedField = notSelectedCopy[source.index];
                notSelectedCopy.splice(source.index, 1);
                setNotSelected(notSelectedCopy);
                const copyFieldsList = [
                    ...fieldsList.slice(0, destination.index),
                    draggedField,
                    ...fieldsList.slice(destination.index)
                ];
                copySections[fieldsSectionArea[1]][fieldsSectionArea[0]] = copyFieldsList;
                setSections(copySections);
            }
        } else {
            if(source.droppableId == 'sections' && destination.droppableId == 'sections') {
                const draggedSection = copySections[source.index];
                copySections.splice(source.index, 1);
                const resultList = [
                    ...copySections.slice(0, destination.index),
                    draggedSection,
                    ...copySections.slice(destination.index)
                ];
                setSections(resultList);
            } else {
                if(source.droppableId == destination.droppableId) {
                    const fieldsSectionArea = source.droppableId.split('_');
                    let editSections = copySections[fieldsSectionArea[1]];
                    let fieldsList = editSections[fieldsSectionArea[0]];
                    const draggedField = fieldsList[source.index];
                    fieldsList.splice(source.index, 1);
                    const copyFieldsList = [
                        ...fieldsList.slice(0, destination.index),
                        draggedField,
                        ...fieldsList.slice(destination.index)
                    ];
                    fieldsList = [...copyFieldsList];
                    copySections[fieldsSectionArea[1]][fieldsSectionArea[0]] = copyFieldsList;
                    setSections(copySections);
                } else {
                    const sectionAndFieldsFrom = source.droppableId.split('_');
                    const sectionAndFieldsTo = destination.droppableId.split('_');
                    let editSectionsFrom = copySections[sectionAndFieldsFrom[1]];
                    let editSectionsTo = copySections[sectionAndFieldsTo[1]];
                    let setFieldsFrom = editSectionsFrom[sectionAndFieldsFrom[0]];
                    let setFiedlsTo = editSectionsTo[sectionAndFieldsTo[0]];
                    const draggedField = setFieldsFrom[source.index];
                    setFieldsFrom.splice(source.index, 1);
                    const copySetFiedlsTo = [
                        ...setFiedlsTo.slice(0, destination.index),
                        draggedField,
                        ...setFiedlsTo.slice(destination.index)
                    ];
                    copySections[sectionAndFieldsFrom[1]][sectionAndFieldsFrom[0]] = setFieldsFrom;
                    copySections[sectionAndFieldsTo[1]][sectionAndFieldsTo[0]] = copySetFiedlsTo;
                    setSections(copySections);
                }
            }
        }
    };

    const fetchLayoutDetails = async() => {
        try {
            const response = await getRequest('company/layout/' + objectName +'/' + layoutName);
            setSelectedLayout(response.data.data.layout);
            setSections(response.data.data.layout?.sections);
            setNotSelected(response.data.data.layout?.notSelected);
            setRelatedLists(response.data.data.layout?.relatedList);
        } catch (error) {
            console.log('fetch layout details error' ,error)
        }
    }

    useEffect(() => {
        fetchLayoutDetails();
    },[layoutName, objectName]);

    const [newSectioName, setNewSectionName] = useState('');
    const [sectionStyle, setSectionStyle] = useState('columns');
    const newSection = () => {
        const section = {"sectionName": newSectioName,"style": sectionStyle,"leftSideFields": [],"rightSideFields": []};
        const copySections = [...sections];
        copySections.push(section);
        setSections(copySections);
    }

    const saveLayout = async() => {
        try {
            let postLayout = JSON.parse(JSON.stringify(selectedLayout));
            postLayout['sections'] = sections;
            postLayout['notSelected'] = notSelected;
            postLayout['relatedList'] = relatedLists;
            const response = await postRequest('company/layout/' + objectName +'/' + layoutName , postLayout);
            console.log('saveLayout response', response);
            dispatch(addNotification({type: "info", message: "Layout Updated"}));
        } catch(error) {
            console.log('saveLayout', error);
        }
    }

    const handleUpdateTableHeaderFields = (index, valueName, value) => {
        const relatedListsValues = JSON.parse(JSON.stringify(relatedLists));
        relatedListsValues[index][valueName] = value;
        setRelatedLists(relatedListsValues);
    }

    return (
        <div>
            {sections && <div
                style={{ display: "flex", justifyContent: "center", height: "100%" }}
            >
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result)}
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                            <Card>
                                <CardContent >
                                    <USDndNotSelected notSelectedItems={notSelected}/>
                                </CardContent>
                                <CardFooter>
                                    <TextField size="small" value={newSectioName} onChange={(e) => setNewSectionName(e.target.value)} />
                                    Колонки<USIOSSwitch name="Style column" onChange={(e) => setSectionStyle('columns') } checked={sectionStyle == 'columns' ? true : false} />
                                    На весь экран<USIOSSwitch name="Style Row" onChange={(e) => setSectionStyle('fullWidth') } checked={sectionStyle == 'fullWidth' ? true : false} />
                                    <Button variant="outlined" size="small" onClick={newSection}>Add Section</Button>
                                    <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                                </CardFooter>
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Card>
                                <CardContent >
                                    <USDndRow sections={sections} />
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </DragDropContext>

            </div>}
            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                <Card>
                    <CardContent >
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Parent Field Name</th>
                                    <th scope="col">Parent Object</th>
                                    <th scope="col">Is Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatedLists && relatedLists?.map((rlItem, index) => (
                                    <tr key={`rlItem_${index}`}>
                                        <td>
                                            {rlItem?.Title}
                                        </td>
                                        <td>
                                            {rlItem?.ParentFieldName}
                                        </td>
                                        <td>
                                            {rlItem?.ObjectName}
                                        </td>
                                        <td>
                                            <USIOSSwitch name="isActive" onChange={(e) => handleUpdateTableHeaderFields(index, "show", !rlItem?.show) } checked={rlItem?.show} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
