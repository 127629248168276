import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from 'react';

export default function USEditListView({handleSave, listViewData, objectName}) {

    const [listViewRecord, setListViewRecord] = useState({
        id:"",
        Name : "",
        ApiName: "",
        ObjectName : objectName[0].toUpperCase() + objectName.slice(1),
        AvailableForOwner: false,
        AvailableForAll: false,
        AvailableForGroup: false
    })

    const handleChange = (e) => {
        updateValues(e.target.name, e.target.value);
    }

    const[selectedVisibility, setSelectedVisibility] = useState("AvailableForOwner");
    const handleSelectVisibility = (e) => {
        setSelectedVisibility(e.target.value);
        setListViewRecord(existingValues => ({
            ...existingValues,
            AvailableForOwner: false,
            AvailableForAll: false,
            AvailableForGroup: false,
            [e.target.value] : true
        }));
        updateListViewFunc(listViewRecord);
    }

    const updateValues = (fieldName, value) => {
        setListViewRecord(existingValues => ({
            ...existingValues,
            [fieldName]: value,
        }));
        updateListViewFunc(listViewRecord);
    }

    const updateListViewFunc = (recordDetails) => {
        handleSave(recordDetails, 'Listview');
    }

    return (
        <>
            {listViewRecord && <div className='row'>
                <div className='col-sm-12 col-xs-12 col-md-12'>
                    <TextField
                        id="outlined-number"
                        label="Name"
                        name="Name"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                        value={listViewRecord?.Name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='col-sm-12 col-xs-12 col-md-12 mt-10'>
                    <TextField
                        id="outlined-number"
                        name="ApiName"
                        label="Api Name"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                        value={listViewRecord?.ApiName}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='col-sm-12 col-xs-12 col-md-12 mt-10'>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Who sees this list view?</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="Visibility"
                            value={selectedVisibility}
                            onChange={handleSelectVisibility}
                        >
                            <FormControlLabel value="AvailableForOwner" control={<Radio />} label="Only I can see this list view" />
                            <FormControlLabel value="AvailableForAll" control={<Radio />} label="All users can see this list view" />
                            <FormControlLabel value="AvailableForGroup" control={<Radio />} label="Share list view with groups of users" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>}
        </>
    )
}
