import React from 'react';
import { Link, useParams } from "react-router-dom";
export default function USManageProfile({}) {
    const {id} = useParams();
  return (
    <div>
        <Link to={`/setup/profile/managesidebar/${id}`}>Manage Sidebar</Link>
    </div>
  )
}
