import React from 'react'
//import USTable from '../USTable';
import { useParams } from 'react-router-dom';
import USTableNew from '../USTableNew';

export default function USRecordList({appPath, ...props}) {
    const {objectName} = useParams();
    return (
        <>
            {/* {objectName && <USTable tableTitle="Company Products" objectName={objectName} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={false} hidePagination={false} handleNew={true}/>} */}
            {objectName && <USTableNew objectName={objectName} currentAppUrl={appPath} /> }
        </>
    )
}
