import React, { useState } from 'react';
import IntlMessages from 'Util/IntlMessages';
import { BottomNavigation, BottomNavigationAction, Button, Chip, Paper } from '@mui/material';
import { USCard } from '../../../USCommonComponents/USCardComponent';
import USNewOrder from './USNewOrder';
import USFullScreenDialog from '../../../USCommonComponents/USFullScreenDialog';
import { checkExperationDate } from '../../../../helpers/helpers';
import { saveNewCall, saveNewLead } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import USTaskSidebar from '../../../USCommonComponents/USTasks/USTaskSidebar';
import USDetailsPage from '../../../USCommonComponents/USDetailsPage';
import { useDispatch } from 'react-redux';
import { openNewRecordDialog } from '../../../../reduxSettings/reduxSlices/systemSlice';
import USTableNew from '../../../USCommonComponents/USTableNew';
import CalculateContract from '../../../USCommonComponents/USSalesConsole/CalculateContract/CalculateContract';

function USClientsManagement() {
    const [tabIndex, setTabIndex] = useState(0);
    const [objectList, setObjectList] = useState('contactrequest');

    //check experation date for callback request in table row
    const cellUpdates = (columnName, cellValue, objectName, recordId) => {
        if(columnName == 'created_at') {
            if(cellValue != null) {
                const recordDate = new Date(cellValue);
                const currentdate = new Date();
                const colorStatus = checkExperationDate(recordDate,currentdate);
                return <div><Chip label={recordDate.toLocaleString("ru-RU")} color={colorStatus} /><Button variant='outlined' size="small" onClick={() => handleConvertToLead(recordId)} className='ml-2'>Convert to lead</Button> </div>
            }
            return '';
        }
    }

    //end new request and contact
    const [newClient, setNewClient] = useState(false);
    const handleNewClientDialogClose = () => {
        setNewClient(false);
    }

    const [newRequestId, setNewRequestId] = useState();
    const handleConvertToLead = (recordId) => {
        setNewRequestId({'contactId': recordId.ContactId, 'phone': recordId.Phone});
        setNewClient(true);
    }

    //Filter panel
    const [searchParams, setSearchParams] = useState({});
    const updateSearchParams = (e) => {
        const fieldName = e.target.name
        setSearchParams(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }));
    }
    //end filter panel

    const [dataToSave, setDataToSave] = useState({
        'form' : null,
        'contactId' : null,
        'callbackId' : null,
        'productDetails' : null,
        'callDescription' : null,
        'parentTaskId' : null
    });

    const updateDataToSave = (value) => {
        setDataToSave(value);
    }

    const saveToLead = async (lastData) => { //, requestedProductObject
        try {
            Object.keys(lastData).map((value, key) => {
                dataToSave[value] = lastData[value];
            })
            const response = await saveNewLead(dataToSave);
        } catch(error) {

        }
    }

    const saveCall = async(lastData) => {
        try {
            Object.keys(lastData).map((value, key) => {
                dataToSave[value] = lastData[value];
            });

            const response = await saveNewCall(dataToSave);
        } catch(error) {

        }
    }

    const [taskDetailsId, setTaskDetailsId] = useState();
    const handleTaskDetails = (taskId) => {
        setTaskDetailsId(taskId);
        dataToSave['parentTaskId'] = taskId;
    }

    const getButtons = () => {
        return [
            {"Name" : "ToLead", "Label" : "To_Lead"},
            {"Name" : "ToOpportunity", "Label" : "To_Opportunity"},
            {"Name" : "Call", "Label" : "Call"}
        ];
    }

    const dispatch = useDispatch();
    const handleAddtionalActions = (actionName, record) => {
        if(actionName == 'ToLead') {
            dispatch(openNewRecordDialog({objectName : 'Lead', dialogTitle : 'Lead.New ', contactId : record.ContactId, taskId: record.id}));
        } else {
            if(actionName == 'ToOpportunity') {
                dispatch(openNewRecordDialog({objectName : 'opportunity', dialogTitle : 'Opportunity.New ', contactId : record.ContactId, taskId: record.id}));
            } if(actionName == 'Call') {
                setNewRequestId({'contactId': record.ContactId, 'phone': record.Phone});
                setNewClient(true);
            }
        }
    }

    return (
        <div >
            {(tabIndex == 0) &&
                <div className='row pb-50'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <CalculateContract />
                    </div>
                </div>
            }
            {(tabIndex == 1) &&
                <div className='row pb-50'>
                    {/* <div className='col-sm-12 col-md-12 col-lg-12'>
                        <USSalesRequestCallbackSearch handleOnChangeValue={updateSearchParams} fieldValues={searchParams} setNewClient={setNewClient} setNewOrder={setNewClient}/>
                    </div> */}
                    {/* <USCallRequestList objectName="contactrequest" handleOpen={() => console.log()} handleCall={handleConvertToLead}/> */}
                    <div className='col-sm-12 col-md-4 col-lg-4'>
                        <USTaskSidebar objectName="task" handleOpen={handleTaskDetails} handleCall={handleConvertToLead}/>
                    </div>
                    <div className='col-sm-12 col-md-8 col-lg-8'>
                        {taskDetailsId && <USDetailsPage objectName="task" id={taskDetailsId} showRelatedList={false} showDelete={false} showNew={false} additionalButtons={getButtons()} handleAddtionalActions={handleAddtionalActions}/>}
                    </div>
                </div>
            }
            {(tabIndex == 2) &&
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <USCard>
                            <USTableNew objectName={objectList} currentAppUrl="" />
                            {/*<USTable tableTitle={`${objectList}.id`} objectName={objectList} requestUrl={'company/getTableList'} expandableRows={false} showDelete={false} columnModificationFunction={cellUpdates} searchCondition={searchParams} packageUrl="sales" handleNew={true} />*/}
                        </USCard>
                    </div>
                </div>
            }

            {(tabIndex == 3) &&
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <USCard>
                            <USTableNew objectName={objectList} currentAppUrl="" />
                            {/*<USTable tableTitle={`${objectList}.id`} objectName={objectList} requestUrl={'company/getTableList'} expandableRows={false} showDelete={false} columnModificationFunction={cellUpdates} searchCondition={searchParams} packageUrl="sales" handleNew={true}/>*/}
                        </USCard>
                    </div>
                </div>
            }

            {(tabIndex == 4) &&
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <USCard>
                            <USTableNew objectName={objectList} currentAppUrl="" />
                            {/*<USTable tableTitle={`${objectList}.id`} objectName={objectList} requestUrl={'company/getTableList'} expandableRows={false} showDelete={false} columnModificationFunction={cellUpdates} searchCondition={searchParams} packageUrl="sales" handleNew={true}/>*/}
                        </USCard>
                    </div>
                </div>
            }

            {newClient &&
                <USFullScreenDialog title="New Client" isOpen={newClient} handleSave={saveToLead} handleClose={handleNewClientDialogClose}>
                    {newClient && <USNewOrder handleSaveNewCall={saveCall} handleSaveNewLead={saveToLead} callbackRecordId={newRequestId} handleAddObjectToSave={updateDataToSave}/>}
                </USFullScreenDialog>
            }

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        setNewClient(false);
                        setTabIndex(newValue);

                        if(newValue == 0) {
                            setObjectList('quickcalculation');
                        }else if(newValue == 1) {
                            setObjectList('contactrequest');
                        }else if(newValue == 2) {
                            setObjectList('Lead');
                        } else if(newValue == 3) {
                            setObjectList('opportunity');
                        } else if(newValue == 4) {
                            setObjectList('contract');
                        }
                    }}
                >
                    <BottomNavigationAction label={<IntlMessages id="sidebar.quickcalculate"/>}  />
                    <BottomNavigationAction label={<IntlMessages id="sidebar.Tasks"/>}  />
                    <BottomNavigationAction label={<IntlMessages id="sidebar.Lead"/>}  />
                    <BottomNavigationAction label={<IntlMessages id="sidebar.opportunity"/>}  />
                    <BottomNavigationAction label={<IntlMessages id="sidebar.contracts"/>} />
                    <BottomNavigationAction label={<IntlMessages id="sidebar.production"/>} />
                </BottomNavigation>
            </Paper>

        </div>
    );
  }

  export default USClientsManagement;

