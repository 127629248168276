import React, { Fragment, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../lang';
import primaryTheme from './primaryTheme';
import darkTheme from './darkTheme';
import secondaryTheme from './secondaryTheme';
import warningTheme from './warningTheme';
import dangerTheme from './dangerTheme';
import infoTheme from './infoTheme';
import successTheme from './successTheme';
import { getUserDetails } from '../../reduxSettings/reduxSlices/userAuthSlice';
import { BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import USServiceConsoleLayout from '../../components/USServiceConsole/Layout/USServiceConsoleLayout';
import USUserLayout from '../../components/USUserComponents/Layout/USUserLayout';
import USCompanyLayout from '../../components/USCompanyComponents/Layout/USCompanyLayout';
import USSalesConsoleLayout from '../../components/USSalesConsole/Layout/USSalesConsoleLayout';
import USPublicSite from '../../components/USPublicSite';
import USMainLayout from '../../components/USMain/Layout/USMainLayout';
import { setLanguage } from '../../reduxSettings/reduxSlices/basicSlice';
import { Redirect } from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
    const isLogged = useSelector((state) => state?.auth?.isLogged);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLogged ? (
                    children
                ) : (
                    <Redirect to={{
                        pathname: "/login",
                        state: { from: location }
                    }} />
            )}
        />
    );
}

function USThemeProvider({children}) {
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.basic.locale);
    const darkMode = useSelector((state) => state.basic.darkMode);
    const rtlLayout = useSelector((state) => state.basic.rtlLayout);
    const activeTheme = useSelector((state) => state.basic.activeTheme);
    const languages = useSelector((state) => state.basic.languages);

    const isLogged = useSelector((state) => state?.auth?.isLogged);
    const user = useSelector((state) => state?.auth?.user);
    console.log('user?.Locale',user?.user?.Locale);
    const currentAppLocale = AppLocale[user?.user?.Locale ? user?.user?.Locale : 'en'];
    // theme changes
    let theme = '';
    switch (activeTheme.id) {
        case 1:
        theme = primaryTheme
        break;
        case 2:
        theme = secondaryTheme
        break;
        case 3:
        theme = warningTheme
        break;
        case 4:
        theme = infoTheme
        break;
        case 5:
        theme = dangerTheme
        break;
        case 6:
        theme = successTheme
        break;
        default:
        break;
    }

    if (darkMode) {
        theme = darkTheme
    }

    if (rtlLayout) {
        theme.direction = 'rtl'
    } else {
        theme.direction = 'ltr'
    }

    useEffect(() => {
        if(isLogged) {
            const currentUserDetails = dispatch(getUserDetails());
            if(currentUserDetails?.user?.Locale) {
                languages.map((languageItem, key) => {
                    if(languageItem.locale == currentUserDetails?.user?.Locale) {
                        console.log(languageItem.locale);
                        dispatch(setLanguage(languageItem));
                    }
                });
            }
        }
    },[]);

    return (
        <ThemeProvider theme={theme}>
            {currentAppLocale && <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <Fragment>
                    <Router>
                        <Switch>
                            <Route path="/login" component={USPublicSite} />
                            <PrivateRoute path="/setup" >
                                <USCompanyLayout />
                            </PrivateRoute>
                            {/* {isLogged && <PrivateRoute path="/home">
                                <USMainLayout/>
                            </PrivateRoute>} */}
                            {user?.application && user?.application[2]?.isVisible && <PrivateRoute  path="/service" >
                                <USServiceConsoleLayout />
                            </PrivateRoute>}
                            {user?.application && user?.application[4]?.isVisible && <PrivateRoute  path="/user" >
                                <USUserLayout />
                            </PrivateRoute>}
                            <PrivateRoute path="/" >
                                <USSalesConsoleLayout />
                            </PrivateRoute>
                            {user?.application && user?.application[1]?.isVisible && <PrivateRoute  path="/portal" >
                                <USSalesConsoleLayout />
                            </PrivateRoute>}
                        </Switch>
                    </Router>
                    {children}
                </Fragment>
            </IntlProvider>}
        </ThemeProvider>
    );
}

export default USThemeProvider;
