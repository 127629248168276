import { SetStateAction, MouseEvent, Dispatch } from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Typography } from "@mui/material"

export default function EventInfoModal({ open, handleClose, onDeleteEvent, currentEvent }) {
    const onClose = () => {
        handleClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Event Info</DialogTitle>
            <DialogContent>
                <DialogContentText>
                <Typography sx={{ fontSize: 14, marginTop: 3 }} color="text.secondary" gutterBottom>
                    {currentEvent?.description}
                </Typography>
                </DialogContentText>
                <Box component="form"></Box>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="info" onClick={onDeleteEvent}>
                    Delete Event
                </Button>
            </DialogActions>
        </Dialog>
    )
}
