/**
 * Reactify Sidebar
 */
import React, { useLayoutEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

// components
import CompanyBlock from './CompanyBlock';
import CompanySidebarContent from './SidebarContent';

import {collapsedSidebarAction} from '../../../reduxSettings/reduxSlices/basicSlice';

function CompanySidebar() {
    const dispatch = useDispatch();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const {enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav, locale} = useSelector((state) => state.basic);

    useLayoutEffect(() => {
        console.log('CompanySidebar');
        function updateSize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            if (windowWidth <= 1199) {
                dispatch(collapsedSidebarAction(false));
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <Fragment>
            <div
                className={classNames('rct-sidebar', { 'background-none': !enableSidebarBackgroundImage })}
                style={{ backgroundImage: enableSidebarBackgroundImage ? `url(${selectedSidebarImage})` : 'none' }}
            >
                <div className={classNames("rct-sidebar-content", { "sidebar-overlay-dark": isDarkSidenav, 'sidebar-overlay-light': !isDarkSidenav })}>
                    <div className="site-logo">
                        <Link to="/" className="logo-mini">
                            <img src={require('Assets/sidebar/img/appLogo.png').default} className="mr-15" alt="site logo" width="35" height="35" />
                        </Link>
                        <Link to="/" className="logo-normal">
                            <img src={require('Assets/sidebar/img/appLogoText.png').default} className="img-fluid" alt="site-logo" width="67" height="17" />
                        </Link>
                    </div>
                    <div className="rct-sidebar-wrap">
                        <Scrollbars
                            className="rct-scroll"
                            autoHide
                            autoHideDuration={100}
                            style={{ height: 'calc(100vh - 60px)' }}
                        >
                            <CompanyBlock />
                            <CompanySidebarContent />
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </Fragment>
    );
 }

//  // map state to props
//  const mapStateToProps = ({ settings }) => {
//      const { enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav, locale } = settings;
//      return { enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav, locale };
//  };

 export default CompanySidebar;
