import React, { useEffect, useState } from 'react'
import SearchProductSidebar from './SearchProductSidebar'
import ContractDetails from './ContractDetails'
import ContractConstuctor from './ContractConstuctor'
import { getCompanyProductById, getOpportunityProductById, getOpportunityProducts, saveOppProducts, updateSalesContact } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { useDispatch, useSelector } from 'react-redux';
import USCheckIfOppExist from '../USSalesDialog/USCheckIfOppExist';
import { NotificationManager } from 'react-notifications';
import USAlertDialog from '../../USAlertDialog/USAlertDialog';
import { saveRecord } from '../../../../Api/OrgRequests/OrgRequestHandler';
import USContactStep from '../../../USSalesConsole/Pages/USNewContractDialog/USContactStep';
import { addNotification } from '../../../../reduxSettings/reduxSlices/NotificationSlice';
import USCheckContract from '../../../USSalesConsole/Pages/USNewContractDialog/USCheckContract';

export default function CalculateContract() {
    const dispatch = useDispatch();
    const [clientSelectedProduct, setClientSelectedProduct] = useState();
    const [refresAddedProducts, setRefresAddedProducts] = useState();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [opportunityId, setOpportunityId] = useState();

    const contactId = useSelector((state) => state.system.updatedRecordId);
    const [selectedContact, setSlectedContact] = useState(null);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogDesctiption, setDialogDesctiption] = useState();

    const [contractIsChanged, setContractIsChanged] = useState(false);

    const handleSelectContact = (contact) => {
        if(contact?.id) {
            checkIfOpportunityExist(contact);
        }
    }

    const [checkOppDialog, setCheckOppDialog] = useState();
    const checkIfOpportunityExist = async(selectedUpdateContact) => {
        setSlectedContact(selectedUpdateContact);
        setCheckOppDialog(true);
    }

    const handleSelectExistingOpportunity = (oppId) => {
        if(oppId) {
            setOpportunityId(oppId);
            setCheckOppDialog(false);
        } else {
            if(selectedContact?.id) {
                createNewOpp(selectedContact?.id);
            } else {
                setDialogTitle("Добавление не возможно");
                setDialogDesctiption("Для добавления товара в просчет, необходимо выбрать существующий контакт или создать новый");
                setAlertDialog(true);
            }
        }
    }

    const createNewOpp = async(selectedContactId) => {
        const response = await saveRecord('Opportunity', '', {Name: 'Продажа', ContactId: selectedContactId, Stage: 'new'});
        setOpportunityId(response?.data?.data);
        setCheckOppDialog(false);
    }

    useEffect(() => {
        if(contactId) {
            checkIfOpportunityExist(contactId);
        }
    },[contactId])

    const updateOpportunity = async() => {

        if(!opportunityId?.id || !selectedContact?.id) {
            setDialogTitle("Ошибка при сохранении");
            setDialogDesctiption("Контакт или продажа не выбраны");
            setAlertDialog(true);
            return;
        } else {
            if(!selectedProducts ||  (selectedProducts && selectedProducts?.length == 0)) {
                setDialogTitle("Ошибка при сохранении");
                setDialogDesctiption("Продукты не выбраны");
                setAlertDialog(true);
                return;
            }
        }

        const response = await saveOppProducts({'name': '', opportunityId: opportunityId?.id, selectedContact: selectedContact?.id, recordToUpdate: selectedProducts});
        setContractIsChanged(false);
        NotificationManager.success('Message has been sent successfully!');
    }

    const [alertDialog, setAlertDialog] = useState(false);
    const dataToSaveFunc = async(productDetails) => {
        if(selectedContact?.id || opportunityId?.id) {
            setSelectedProducts([...selectedProducts, {...productDetails}]);
            setContractIsChanged(true);
        } else {
            setDialogTitle("Создание договора не доступно");
            setDialogDesctiption("Что бы создать договор необходимо выбрать существующий конакт или создать новый и выбрать существующую продажу или создать новую");
            setAlertDialog(true);
        }
    }

    const updateProductDetails = async (selectedValue) => {
        setClientSelectedProduct(null);
        const response = await getCompanyProductById(selectedValue);
        setClientSelectedProduct(response);
    }

    const updateOppProductDetails = async (selectedValue) => {
        setClientSelectedProduct(null);
        const response = await getOpportunityProductById(selectedValue);
        setClientSelectedProduct(response);
    }

    const handleOpenProduct = (recordId, objName) => {
        if(objName == 'productscompany') {
            updateProductDetails(recordId);
        } else {
            if(objName == 'opportunityproduct') {
                updateOppProductDetails(recordId);
            }
        }
    }

    const handleEditSelectedProduct = (index, product) => {
        setClientSelectedProduct(null);
        setClientSelectedProduct(product);
    }

    const handleRemoveProduct = (index) => {
        if(index > -1) {
            setSelectedProducts(products=> products.filter((s,i)=>(i != index)))
        }
    }

    useEffect(() => {
        if(opportunityId?.id) {
            getProductsByOppId(opportunityId);
        }
    },[opportunityId])

    const getProductsByOppId = async(opportunityId) => {
        const response = await getOpportunityProducts(opportunityId?.id);
        setSelectedProducts(response);
    }

    const updateProductComplectType = (value) => {
        clientSelectedProduct['ComplectType'] = value;
        setContractIsChanged(true);
        setRefresAddedProducts(new Date().getTime());
    }

    const [newContract, setNewContract] = useState(false);
    const [checkContract, setCheckContract] = useState(false);
    const [calculateOpportunity, setCalculateOpportunity] = useState(true);
    const [contractDetails, setContractDetails] = useState();
    const updatedPassportDetails = (pasportDetails) => {
        setContractDetails(pasportDetails);
    }

    const handleSaveContract = async (currentData) => {
        try {
            const response = await updateSalesContact(currentData);
            if(response?.id) {
                dispatch(addNotification({"type" : "success", "message" : 'updated'}));
            } else {
                dispatch(addNotification({"type" : "error", "message" : "Error record creation"}));
            }
        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const handleConstructConrtact = () => {
        if(!checkErrors()) {
            setCalculateOpportunity(false);
            setNewContract(true);
        }
    }

    const checkErrors = () => {
        console.log('checkErrors');
        if(contractIsChanged) {
            setDialogTitle("Изменения в договоре не сохранены");
            setDialogDesctiption("Что бы продолжить работу, сохраните изменения в договоре");
            setAlertDialog(true);
            return true;
        }

        if(!selectedContact?.id) {
            setDialogTitle("Создание договора не доступно");
            setDialogDesctiption("Что бы создать договор необходимо выбрать существующий конакт или создать новый");
            setAlertDialog(true);
            return true;
        } else {
            if(!opportunityId?.id) {
                setDialogTitle("Создание договора не доступно");
                setDialogDesctiption("Что бы создать договор необходимо выбрать сделку связанную с текущим контактом");
                setAlertDialog(true);
                return true;
            } else {
                if(!selectedProducts ||  (selectedProducts && selectedProducts?.length == 0)) {
                    setDialogTitle("Ошибка при сохранении");
                    setDialogDesctiption("Продукты не выбраны");
                    setAlertDialog(true);
                    return true;
                }
            }
        }
        return false;
    }

    const resetData = () => {
        setSelectedProducts([]);
        setOpportunityId(null);
        setSlectedContact(null);
        setClientSelectedProduct(null);
    }

    return (
        <>
            {calculateOpportunity && <div className='row'>
                <div className='col-sm-3 col-md-3 col-lg-3 pr-5 pl-5'>
                    <SearchProductSidebar objectName={'productscompany'} sortField='created_at' handleOpen={handleOpenProduct}/>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6 pr-5 pl-5'>
                    <ContractConstuctor
                        detail={clientSelectedProduct}
                        handleUpdateSelectedProduct={setClientSelectedProduct}
                        handleUpdateProduct={dataToSaveFunc}
                        handleRefreshData={setRefresAddedProducts}
                        handleUpdateType={updateProductComplectType}
                    />
                </div>
                <div className='col-sm-3 col-md-3 col-lg-3 pr-5 pl-5'>
                    <ContractDetails
                        currentContact={selectedContact}
                        opportunity={opportunityId}
                        removeProduct={handleRemoveProduct}
                        handleOpen={handleOpenProduct}
                        selectedProducts={selectedProducts}
                        refreshToken={refresAddedProducts}
                        showDelete={true}
                        onselectedContact={handleSelectContact}
                        handleUpdateProduct={updateOpportunity}
                        editSelectedProduct={handleEditSelectedProduct}
                        handleNewContract={handleConstructConrtact}
                        resetCalculation={resetData}
                        handleCheckErrors={checkErrors}
                    />
                </div>

                {(selectedContact && checkOppDialog) && <USCheckIfOppExist isOpen={checkOppDialog} handleAction={() => setCheckOppDialog(false)} contactId={selectedContact?.id} selectOpportunity={handleSelectExistingOpportunity} />}
                {alertDialog && <USAlertDialog isOpen={alertDialog} closeDialog={() => setAlertDialog(false)} handleAction={() => setAlertDialog(false)} dialogTitle={dialogTitle} dialogDesctiption={dialogDesctiption}/>}
            </div>}
            {newContract && <USContactStep id={opportunityId?.id}  handleUpdate={handleSaveContract} handleBack={() => {setCalculateOpportunity(true);setNewContract(false)}} handleNext={() => {setCheckContract(true);setNewContract(false)}} handleUpdatePassport={updatedPassportDetails}/>}
            {checkContract && <USCheckContract id={opportunityId?.id} contactPasport={contractDetails['contactPasport']} issuedDate={contractDetails['issuedDate']} handleBack={() => {setCheckContract(false);setNewContract(true)}}/>}
        </>
    )
}
