import { Button, ButtonGroup, Link, Stack } from '@mui/material';
import React from 'react'
import { useHistory } from 'react-router-dom';

export default function USCallDetails({packageUrl, objectName, fieldDetails, fieldData, relatedObjects, rowIndex,handleCall}) {
    const navigate = useHistory();

    function getTableRow() {
        const fieldType = fieldDetails.fieldType;
        const fieldName = fieldDetails.fieldName;
        const referenceObject = fieldDetails.referenceObject;

        const value = fieldData[fieldName];
        if(fieldType) {
            switch (fieldType) {
                case 'bigint': return bigIntField(fieldName, referenceObject, value, false);
                case 'float': return floatField(value);
                case 'date': return dateField(value);
                case 'datetime': return datetimeField(value);
                case 'string': return stringField(value);
                case 'integer': return integerField(value);
                case 'boolean': return booleanField(value);
                default:
                    return value;
            }
        }
        return value;
    }

    const getRelatedObjectName = (objectName, objectId) => {
        if(relatedObjects?.hasOwnProperty(objectName)) {
            if(relatedObjects[objectName]?.hasOwnProperty(objectId)) {
                return relatedObjects[objectName][objectId];
            } else {
                return  '';
            }
        }
        return '';
    }

    const bigIntField = (fieldName, referenceObject, value, showDelete=false) => {
        if(referenceObject) {
            return <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            navigate.push(`${packageUrl}/${referenceObject}/${value}`);
                    }}>
                        {getRelatedObjectName(referenceObject,value)}
                    </Link>
        } else {
            return <ButtonGroup size="small" aria-label="Small button group">
                        <Button key="one" onClick={() => navigate.push(`${packageUrl}/${objectName}/${value}`)}>Open</Button>
                        <Button key="two" onClick={() => handleCall(value)}>Call</Button>
                    </ButtonGroup>
        }
        return '';
    }

    const dateField = (value) => {
        if(value != null) {
            let colData = new Date(value);
            let day = colData.getDate();
            let month = colData.getMonth();
            let year = colData.getFullYear();
            return month+1 + "/" + day + "/" + year;
        }
        return '';
    }

    const datetimeField = (value) => {
        if(value != null) {
            //const cellValue = columnModification(tableMeta.columnData.name, value, objectName,tableMeta.rowData[0]);
            // if(value) {
            //     return value
            // }
            const recordDate = new Date(value);
            return recordDate.toLocaleString("ru-RU");
        }
        return '';
    }

    const booleanField = (value) => {
        return <USIOSSwitch name="isPopular" onChange={(e) => updateTableRow(value,'isPopular',!value) } checked={value} />
    }

    const stringField = (value) => {
        return value;
    }

    const floatField = (value) => {
        return value;
    }

    const integerField = (value) => {
        return value;
    }
    return (
        <div>{fieldData && getTableRow()}</div>
    )
}
