import AppConfig from 'Constants/AppConfig';

import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { AppBar, Button, Fab, FormGroup, Input, LinearProgress, Toolbar } from '@mui/material';
import { loginUser } from '../../../reduxSettings/reduxSlices/userAuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../reduxSettings/reduxSlices/NotificationSlice';
import { USCard, USCardContent } from '../USCardComponent';


 function USLogin({callBackUrl, onChangeToRegister}) {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.loading)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useHistory();
    const location = useLocation();

    const onUserLogin = () => {
        if (email !== '' && password !== '') {
            dispatch(loginUser({
                email: email,
                password: password
            })).then((result) => {
                if(result?.payload?.success) {
                    if(location?.state?.from != undefined) {
                        const navigateUrl = (callBackUrl && callBackUrl != undefined ? callBackUrl + '/' : '' )  + location?.state?.from?.pathname;
                        navigate.push(navigateUrl)

                    } else {
                        if(callBackUrl != undefined) {
                            navigate.push(callBackUrl);
                        }
                        navigate.push('/user');
                    }
                } else {
                    console.log('error notification');
                    dispatch(addNotification({"type" : "success","title":"Product Added", "message":  result}));
                }
            });
        }
    }
    /**
     * On User Sign Up
     */
    const onUserSignUp = () => {
       navigate.push('/register');
    }

    return (

        <USCard>
            <USCardContent>
                <div className="row row-eq-height">
                    <div className="col-12">
                        <div className="session-body text-center">
                            <div className="session-head mb-40 mt-15">
                                <h2 className="font-weight-bold">У вас есть аккаунт?</h2>
                                <p className="mb-0">Войдите в личный кабинет</p>
                            </div>

                            <FormGroup className="has-wrapper">
                                <Input type="mail" value={email} name="user-mail" id="user-mail" className="has-input input-lg" placeholder="Enter Email Address" onChange={(event) => setEmail(event.target.value)} />
                                <span className="has-icon"><i className="ti-email"></i></span>
                            </FormGroup>
                            <FormGroup className="has-wrapper">
                                <Input value={password} type="Password" name="user-pwd" id="loginPassword" className="has-input input-lg" placeholder="Password" onChange={(event) => setPassword(event.target.value )} />
                                <span className="has-icon"><i className="ti-lock"></i></span>
                            </FormGroup>
                            <FormGroup className="mb-15 mt-15">
                                <Button
                                    color="primary"
                                    className="btn-block text-white w-100"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onUserLogin()}>
                                    Sign In
                                </Button>
                            </FormGroup>

                            {/*<FormGroup className="mb-15 mt-15">
                                <Button
                                    color="error"
                                    className="btn-block text-white w-100"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onChangeToRegister()}>
                                    Register
                                </Button>
                            </FormGroup>

                            <p className="mb-20">or sign in with</p>
                            <Fab variant="round" size="small"
                                className="btn-facebook mr-15 mb-20 text-white"
                                onClick={() => signinUserWithFacebook(history)}
                            >
                                <i className="zmdi zmdi-facebook"></i>
                            </Fab>
                            <Fab variant="round" size="small"
                                className="btn-google mr-15 mb-20 text-white"
                                onClick={() => signinUserWithGoogle(history)}
                            >
                                <i className="zmdi zmdi-google"></i>
                            </Fab>
                            <Fab variant="round" size="small"
                                className="btn-twitter mr-15 mb-20 text-white"
                                onClick={() => signinUserWithTwitter(history)}
                            >
                                <i className="zmdi zmdi-twitter"></i>
                            </Fab>
                            <Fab variant="round" size="small"
                                className="btn-instagram mr-15 mb-20 text-white"
                                onClick={() => signinUserWithGithub(history)}
                            >
                                <i className="zmdi zmdi-github-alt"></i>
                            </Fab>
                            <p className="text-muted">By signing up you agree to {AppConfig.brandName}</p>
                            <p><a target="_blank" href="#/terms-condition" className="text-muted">Terms of Service</a></p>*/}
                        </div>
                    </div>
                </div>
            </USCardContent>
        </USCard>

    );

 }

 // map state to props
//  const mapStateToProps = ({ authUser }) => {
//     const { user, loading } = authUser;
//     return { user, loading }
//  }

 export default USLogin;
//  connect(mapStateToProps, {
//     signinUserInFirebase,
//     signinUserWithFacebook,
//     signinUserWithGoogle,
//     signinUserWithGithub,
//     signinUserWithTwitter
//  })(Signin);
