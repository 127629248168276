import React, { useState } from 'react'
import IntlMessages from '../../../../util/IntlMessages';

export default function USTableDropdown({icon, values, selectedValue}) {

    const [isOpen, setIsOpen] = useState(false);
    const selectOption = (e) => {
        selectedValue(e);
        setIsOpen(false);
    }

    return (
        <div className="dropdown d-inline-block">
            <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(!isOpen)}>
                {icon}
            </button>
            <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}  aria-labelledby="dropdownMenuButton1">
                {values && values.map((optionItem, optionIndex) => (
                    <li key={optionIndex}><a className="dropdown-item" href="#" onClick={(e) => selectOption(optionItem.value)}><IntlMessages id={optionItem.label} /></a></li>
                ))}
            </ul>
        </div>
    )
}
