/**
 * Company Routes
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
// async components
import {
    AsyncUSCompanyInformation
} from '../USCompanyAsyncPageLoader/USCompnayPageLoader';


import USCompanyDashboard from '../Pages/USCompanyDashboard';
import USSiteBuilder from '../../USSiteBuilder';


import USCompanyProductLoad from './USCompanyProductLoad';
import USCompanySettings from './USCompanySettings';
import USEditEmailConnectSetting from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditEmailConnectSetting';
import USEditEmailFilterSettings from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditEmailFilterSettings';
import USEditFileMapping from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditFileMapping';
import USEditFileMappingItem from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditFileMappingItem';
import USRoles from './USRoles';
import USProfiles from './USProfiles';
import USCompanyEditProfile from './USProfiles/USCompanyEditProfile';
import USCompanyEditRole from './USRoles/USCompanyEditRole';
import StoreDetails from './USCompanyStrores/view/StoreDetails';
import NewSite from './USCompanyStrores/new/NewSite';
import USEditIntegration from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditIntegration';
import USStoreCatalogs from './USCompanyStrores/USStoreCatalogs';
import USStoreMenu from './USCompanyStrores/USStoreMenu';
import USStoreCategories from './USCompanyStrores/USStoreCategories';

import USStoreMenuDetails from './USCompanyStrores/USStoreMenu/USStoreMenuDetails';
import USObjectManager from './USObjectManager';
import USObjectDetails from './USObjectManager/USObjectDetails';
import USObjectFields from './USObjectManager/USObjectFields';
import USObjectLayout from './USObjectManager/USObjectLayout';
import USObjectActions from './USObjectManager/USObjectActions';
import USObjectLimits from './USObjectManager/USObjectLimits';
import USObjectRecordType from './USObjectManager/USObjectRecordType';
import USObjectRestrictionRules from './USObjectManager/USObjectRestrictionRules';
import USObjectTrigger from './USObjectManager/USObjectTrigger';
import USObjectValidationRules from './USObjectManager/USObjectValidationRules';
import USObjectFieldDetails from './USObjectManager/USObjectFieldDetails';
import USObjectLayoutDetails from './USObjectManager/USObjectLayoutDetails';

import USViewRecord from '../../USCommonComponents/USRecordDetails/USViewRecord';
import USEditRecord from '../../USCommonComponents/USRecordDetails/USEditRecord';

import USBulkAddRelatedRecords from '../../USCommonComponents/USDetailsPage/USBulkAddRelatedRecords';
import USRecordList from '../../USCommonComponents/USRecordDetails/USRecordList';
import USManageProfile from './USProfiles/USManageProfile';
import USManageSidebar from './USProfiles/USManageSidebar';
import USCreateTab from './USCustomTabs/USCreateTab';


const Pages = (props) => {
    console.log('company pages' , props);
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Networks</title>
                <meta name="description" content="BPortal Setup" />
            </Helmet>
            <Switch>
                <Route path="/setup/account" component={USCompanyDashboard} />
                <Route path={`/setup/company-information`} component={AsyncUSCompanyInformation} />
                <Route path={`/setup/settings`} component={USCompanySettings} />
                <Route path={`/setup/site-builder`} component={USSiteBuilder} />
                <Route path="/setup/ObjectManager/:objectName/ValidationRules" component={USObjectValidationRules} />
                <Route path="/setup/ObjectManager/:objectName/Triggers" component={USObjectTrigger} />
                <Route path="/setup/ObjectManager/:objectName/RestrictionRules" component={USObjectRestrictionRules} />
                <Route path="/setup/ObjectManager/:objectName/RecordTypes" component={USObjectRecordType} />
                <Route path="/setup/ObjectManager/:objectName/Limits" component={USObjectLimits} />
                <Route path="/setup/ObjectManager/:objectName/Layouts/:layoutName" component={USObjectLayoutDetails} />
                <Route path="/setup/ObjectManager/:objectName/Layouts" component={USObjectLayout} />
                <Route path="/setup/ObjectManager/:objectName/Buttons/:buttonName" component={USObjectActions} />
                <Route path="/setup/ObjectManager/:objectName/Buttons" component={USObjectActions} />
                <Route path="/setup/ObjectManager/:objectName/Fields/:fieldName" component={USObjectFieldDetails} />
                <Route path="/setup/ObjectManager/:objectName/Fields" component={USObjectFields} />
                <Route path="/setup/ObjectManager/:objectName" component={USObjectDetails} />
                <Route path="/setup/ObjectManager" component={USObjectManager} />

                <Route path={`/setup/emailconnectsetting/:id`} component={USEditEmailConnectSetting} />
                <Route path={`/setup/emailfiltersetting/:id`} component={USEditEmailFilterSettings} />
                <Route path={`/setup/filemapping/:id`} component={USEditFileMapping} />
                <Route path={`/setup/filemappingitem/:id`} component={USEditFileMappingItem} />
                <Route path={`/setup/integration/:id?`} component={USEditIntegration} />

                <Route path={`/setup/profile/manage/:id`} component={USManageProfile} />
                <Route path={`/setup/profile/managesidebar/:id`} component={USManageSidebar} />
                <Route path={`/setup/view/profile/:id`} component={USCompanyEditProfile} />
                <Route path={`/setup/roles/:id`} component={USCompanyEditRole} />
                <Route path={`/setup/test`} component={USProfiles} />

                <Route path={`/setup/uploadedfile/:id`} component={USCompanyProductLoad} />

                <Route path={`/setup/store/new`} component={NewSite} />
                <Route path={`/setup/menu/:id`} component={USStoreMenuDetails} />
                <Route path={`/setup/store/:id/catalogs`} component={USStoreCatalogs} />
                <Route path={`/setup/store/:id/menus`} component={USStoreMenu} />
                <Route path={`/setup/store/:id/categories`} component={USStoreCategories} />
                <Route path={`/setup/store/:id`} component={StoreDetails} />

                <Route path={`/setup/new/tab`} component={USCreateTab} />

                <Route path="/setup/view/:parentObject/:relatedObject/:id" component={USBulkAddRelatedRecords}/>
                <Route path="/setup/view/:objectName?/:id" component={USViewRecord}/>
                <Route path="/setup/edit/:objectName?/:id?" component={USEditRecord}/>
                <Route path="/setup/:objectName" render={(props) => <USRecordList {...props} appPath={'setup'} />}/>
                <Route path="/setup" component={USCompanyDashboard}/>
            </Switch>
        </div>
    );
}
export default Pages;
