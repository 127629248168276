import React, { useEffect, useState } from 'react'
import USEditListView from './USListView/USEditListView'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Modal } from '@mui/material';
import USListViewFilters from './USListView/USListViewFilters';
import { saveListFilters, saveRecord } from '../../../Api/OrgRequests/OrgRequestHandler';
import { red } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../reduxSettings/reduxSlices/NotificationSlice';

export default function USListViewDialog({isOpen, handleClose, objectName, selectedListView, selectedAction}) {
    const dispatch = useDispatch();
    const [objectToSave, setObjectToSave] = useState();
    const [recordDetails, setRecordDetails] = useState();

    const handleSave = async() => {
        if(selectedAction == 'listfilter') {
            const response = await saveListFilters(recordDetails);
        } else {
            const response = await saveRecord(objectToSave, recordDetails?.id, recordDetails);
        }

        handleClose();
        dispatch(addNotification({type: "info", message: "Record Updated"}));
    }

    useEffect(() => {
        if(selectedListView)
            setRecordDetails(selectedListView);
    },[selectedListView])

    const getCurrentContent = (action) => {
        switch (action) {
            case 'new': {
                
                return <USEditListView handleCancel={handleClose} handleSave={handleUpdateDetails} listViewData={recordDetails} objectName={objectName}/>
            }
            case 'clone':
              console.log('clone');
            break;
            case 'rename':
              console.log('rename');
            break;
            case 'sharing':
              console.log('sharing');
            break;
            case 'listfilter': {
                return <USListViewFilters listViewId={recordDetails?.id} handleUpdateDetails={handleUpdateDetails} objectName={objectName}/>
            }
            case 'listToDisplay':
              console.log('listToDisplay');
            break;
            case 'delete':
              console.log('delete');
            break;
            default:
                return <>action not found</>
        }
    }

    const handleUpdateDetails = (recDetails, objectName) => {
        setObjectToSave(objectName);
        setRecordDetails(recDetails);
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10"
            style={{ height: "90%", width:'60%', top: '10%', left: '20%'}}
        >
            <Card>
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        T
                    </Avatar>
                    }
                    title="List View Filter"
                    subheader="Record"
                />
                {selectedAction && <CardContent>
                    {getCurrentContent(selectedAction)}
                </CardContent>}
                <CardActions>
                    <Button size="small" variant='outlined' onClick={handleSave}>Save</Button>
                    <Button size="small" variant='outlined' onClick={handleClose}>Cancel</Button>
                </CardActions>
            </Card>
        </Modal>
    )
}
